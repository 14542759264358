import { Box, makeStyles } from "@material-ui/core";
import { useParams, useLocation } from "react-router";
import NavTabs from "./NavTabs";
import ContentCard from "../../components/ContentCard";
import MarketingPartnersSideNav from "./MarketingPartnersSideNav";
import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import {
  getAnnualMeeting,
  getScheduleDetails,
  updateAnnualMeeting,
} from "../../api/adplanApiClient";
import {
  AnnualMeeting,
  mapAnnualMeeting,
  MarketingCampaign,
  MarketingPartner,
  Schedule,
} from "../../dto/Adplan";
import { useSnackbar } from "notistack";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createContext } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import ReactRouterPrompt from "react-router-prompt";
import ConfirmDialog from "../../components/ConfirmDialog";
const useStyles = makeStyles((theme) => ({
  gridItemStyles: {
    paddingRight: theme.spacing(2),
  },

  fixedLaylout: {
    position: "fixed",
    width: "450px",
  },
}));

const defaultValues: AnnualMeeting = {
  customerData: {
    salesLines: [],
    conditionAreas: [],
    creditors: [],
    objectGroups: [],
    nielsenRegions: [],
    federalStates: [],
    rangeRegions: [],
    zipCodes: [],
    salesDistricts: [],
    nationalCode: "",
  },
  articleData: {
    brands: [],
    productKinds: [],
    articles: [],
    containerReturnPolicies: [],
    articleRange: true,
    productHierarchyLevel3Ids: [],
    packageSizes: [],
  },
  advertisingCommitmentData: {
    leaflet: false,
    newspaper: false,
    poster: false,
    internet: false,
    campaign: false,
    coverPage: false,
    hero: false,
    tailorMade: false,
    onlineCampaign: false,
    offerDiscount: false,
    customQuality: false,
    customQualityDescription: null,
    initiations: null,
    duration: "",
    advertisingCommitmentComment: "",
    customQuality2: false,
    customQualityDescription2: null,
    customQuality3: false,
    customQualityDescription3: null,
    customQuality4: false,
    customQualityDescription4: null,
    customQuality5: false,
    customQualityDescription5: null,
    customQuality6: false,
    customQualityDescription6: null,
  },
  annualMeetingFinancingCommentPosition: [],
};
interface AdPlanningContextProps {
  fetchScheduleDetails: () => void;
  schedule: Schedule | null;
  selectedMarketingPartner: MarketingPartner | undefined;
  selectedCampaign: MarketingCampaign | undefined;
}

export const AdPlanningContext = createContext<AdPlanningContextProps>({
  fetchScheduleDetails: () => {},
  selectedCampaign: undefined,
  selectedMarketingPartner: undefined,
  schedule: null,
});

interface LayoutProps {
  selectedPartnerId?: number | undefined;
}

const AdPlanLayout = ({ selectedPartnerId }: LayoutProps) => {
  const classes = useStyles();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [schedule, setSchedule] = useState<Schedule | null>(null);
  const [selectedCampaign, setSelectedCampaign] = useState<
    MarketingCampaign | undefined
  >(undefined);
  const [oldAnnualMeeting, setOldAnnualMeeting] = useState<any>();
  const [
    oldAnnualMeetingCommentPositions,
    setOldAnnualMeetingCommentPositions,
  ] = useState<any>();

  const [selectedMarketingPartner, setSelectedMarketingPartner] = useState<
    MarketingPartner | undefined
  >(undefined);
  const [open, setOpen] = useState(false);

  const params = useParams<{
    scheduleId: string;
    marketingPartnersId: string;
    campaignId: string;
  }>();
  const scheduleId = params.scheduleId;
  const marketingPartnersId = params.marketingPartnersId;
  const campaignId = params.campaignId;
  const methods = useForm<AnnualMeeting>({
    defaultValues: { ...defaultValues },
  });
  const { reset, handleSubmit, getValues, formState } = methods;
  const fetchScheduleDetails = useCallback(async () => {
    if (scheduleId)
      try {
        let response = await getScheduleDetails(+scheduleId);
        setSchedule(response);
      } catch (e) {
        const error = e as Error;
        enqueueSnackbar(error.message, { variant: "error" });
      }
  }, [scheduleId, enqueueSnackbar]);

  useEffect(() => {
    if (campaignId) {
      const marketingPartner = schedule?.marketingPartners.find((m) => {
        let campaign = m.marketingCampaigns.find((c) => c.id === +campaignId);
        setSelectedCampaign(campaign);
        return campaign ? true : false;
      });
      setSelectedMarketingPartner(marketingPartner);
    }
  }, [schedule, marketingPartnersId, campaignId, reset]);

  function transformZipCodes(zipCodes: any) {
    return (
      zipCodes.map((z: string, index: number) => ({
        id: index,
        name: z as unknown as string,
      })) || []
    );
  }

  const fetchAnnualMeeting = useCallback(async () => {
    if (campaignId) {
      setOpen(true);
      try {
        let response = await getAnnualMeeting(+campaignId);
        let values = { ...defaultValues };
        if (response) {
          if (response.articleData) {
            values.articleData = {
              ...values.articleData,
              ...response.articleData,
            };
          }
          if (response.advertisingCommitmentData) {
            values.advertisingCommitmentData = {
              ...values.advertisingCommitmentData,
              ...response.advertisingCommitmentData,
            };
          }
          if (response.customerData) {
            values.customerData = {
              ...values.customerData,
              ...response.customerData,
            };
          }
          if (response.annualMeetingFinancingCommentPosition) {
            values.annualMeetingFinancingCommentPosition = [
              ...values.annualMeetingFinancingCommentPosition,
              ...response.annualMeetingFinancingCommentPosition,
            ];

            setOldAnnualMeetingCommentPositions(
              response.annualMeetingFinancingCommentPosition
            );
          }
          setOldAnnualMeeting(values);
        }
        values.customerData.zipCodes = transformZipCodes(
          values.customerData.zipCodes
        );
        reset({ ...values });
        setOpen(false);
      } catch (e) {
        const error = e as Error;
        enqueueSnackbar(error.message, { variant: "error" });
        setOpen(false);
      }
    }
  }, [campaignId, reset, enqueueSnackbar]);

  useEffect(() => {
    // setOpen(true);
    fetchScheduleDetails();
    fetchAnnualMeeting();
  }, [fetchScheduleDetails, fetchAnnualMeeting]);

  const onSubmit = async (data: any) => {
    if (campaignId)
      try {
        // zip codes are transformed here - better option might be to create a custom autocomplete in ZipcodesDropdown.tsx
        // const retransformedZipCodes = getValues().customerData.zipCodes
        //   ? getValues().customerData.zipCodes.map((z: any) => z.name)
        //   : [];
        let mapped = mapAnnualMeeting({ ...getValues(), ...data });
        if (mapped.annualMeetingFinancingCommentPosition.length > 0)
          mapped = removeSalesLinesFromPositions(mapped);
        // mapped.customerData.zipCodes = retransformedZipCodes;
        const response = await updateAnnualMeeting(+campaignId, "POST", mapped);

        response.customerData.zipCodes = transformZipCodes(
          response.customerData.zipCodes
        );
        reset(response);
        enqueueSnackbar(t("successfully-applied"), { variant: "success" });
      } catch (e) {
        const error = e as Error;
        enqueueSnackbar(error.message, { variant: "error" });
      }
  };

  function removeSalesLinesFromPositions(mapped: any) {
    let positions = mapped.annualMeetingFinancingCommentPosition;
    let newSaleLines = mapped.customerData.salesLineIds;
    positions.forEach((position: any, index: any) => {
      position.salesLines?.forEach((salesLine: any) => {
        if (!newSaleLines.includes(salesLine)) {
          mapped.annualMeetingFinancingCommentPosition[index].salesLines =
            mapped.annualMeetingFinancingCommentPosition[
              index
            ].salesLines.filter((line: any) => {
              return line !== salesLine;
            });
        }
      });
    });
    return mapped;
  }

  let path = location.pathname.split("/");
  path.pop();
  const base = path.join("/");
  const resetForm = useCallback(
    (
    ) => {
      if (oldAnnualMeetingCommentPositions)
      oldAnnualMeeting.annualMeetingFinancingCommentPosition =
        oldAnnualMeetingCommentPositions;
      reset({ ...oldAnnualMeeting });
    },
    [reset, oldAnnualMeeting, oldAnnualMeetingCommentPositions]
  );

  const blocker = (args: {
    currentLocation: Location;
    nextLocation: Location;
    historyAction: any;
  }) => {
    if (formState.isDirty){
      if (
          args.nextLocation.pathname.includes(base) &&
          !args.nextLocation.pathname.includes("werbeplanung")
      ) {
        return false;
      }
      return true;
    }
  }
  return (
    <>
      <div>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <AdPlanningContext.Provider
        value={{
          schedule,
          selectedCampaign,
          selectedMarketingPartner,
          fetchScheduleDetails,
        }}
      >
        <Box className={classes.fixedLaylout}>
          <ContentCard>
            <MarketingPartnersSideNav
              scheduleId={scheduleId ? +scheduleId : undefined}
              selectedPartnerId={selectedPartnerId}
            />
          </ContentCard>
        </Box>
        <Box width="100%" display="flex">
          <Box width="450px" minWidth="450px" />
          <Box width="100%" marginLeft={2} overflow="hidden">
            {selectedMarketingPartner && (
              <ContentCard>
                <>
                  <ReactRouterPrompt when={blocker} beforeConfirm={resetForm as any}
                    >
                    {({ isActive, onConfirm, onCancel }) => (
                        <ConfirmDialog
                            title={t("unsaved-changes")}
                            message={t("discard-addplanning-changes")}
                            open={isActive}
                            handleReject={onCancel}
                            confirmBtnText={t("leave")}
                            handleConfirm={onConfirm}
                        />
                    )}
                  </ReactRouterPrompt>
                  <NavTabs
                    scheduleId={scheduleId ? +scheduleId : undefined}
                    marketingPartnersId={
                      marketingPartnersId ? +marketingPartnersId : undefined
                    }
                    campaignId={campaignId ? +campaignId : undefined}
                  />
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Outlet />
                    </form>
                  </FormProvider>
                </>
              </ContentCard>
            )}
          </Box>
        </Box>
      </AdPlanningContext.Provider>
    </>
  );
};

export default AdPlanLayout;
