import CustomerCollapsibleTableRow from "./CustomerCollapsibleTableRow";
import { Customers } from "../../../api/detailedPlanningApiClient";

interface CustomerCollapsibleTableProps {
  customers: Customers[];
  year: number;
  weekOfYear: number;
  clientele: string;
  importVersion: number;
}

const CustomerCollapsibleTable = ({
  customers,
  year,
  weekOfYear,
  clientele,
  importVersion,
}: CustomerCollapsibleTableProps) => {
  return (
    <>
      <div>
        {customers.map((item) => {
          return (
            <CustomerCollapsibleTableRow
              key={item.id}
              customer={item}
              year={year}
              weekOfYear={weekOfYear}
              clientele={clientele}
              importVersion={importVersion}
            />
          );
        })}
      </div>
    </>
  );
};

export default CustomerCollapsibleTable;
