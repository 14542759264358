import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useState, useEffect } from "react";

import { getMarketingPartners } from "../../../api/basedataApiClient";
import { DropDownItem } from "../../../api/basedataApiClient";

interface PartnersDropdownProps {
  label: string;
  addMarketingPartner: (marketingPartnerId: number | null) => Promise<void>;
}

const MarketingPartnerDropdown = ({
  label,
  addMarketingPartner,
}: PartnersDropdownProps) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<DropDownItem[]>([]);

  const loading = open && options.length === 0;
  const [showCircularProgress, setShowCircularProgress] = useState(false);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      setShowCircularProgress(true);
      const response = await getMarketingPartners();
      const responseResult = response.results;

      setShowCircularProgress(false);

      if (active) {
        setOptions(responseResult);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <>
      <Autocomplete
        id={label}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={showCircularProgress}
        onChange={(event, value) =>
          addMarketingPartner(value ? value.id : null)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {showCircularProgress ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default MarketingPartnerDropdown;
