import { useTranslation } from "react-i18next";
import { getSalesline } from "../../../api/basedataApiClient";
import Dropdown from "./Dropdown";

interface SalesDropdownProps {
  registerName: string;
  control: any;
  rules?: any;
}

const SalesDropdown = ({
  registerName,
  control,
  rules,
}: SalesDropdownProps) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option) => {
        return option.name;
      }}
      fetchData={getSalesline}
      control={control}
      label={t("sales-line")}
      name={registerName}
      shouldServerSideSearch={false}
      rules={rules}
    />
  );
};

export default SalesDropdown;
