import { Card, CardContent, makeStyles } from "@material-ui/core";

interface CardProps {
  className?: string;
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  contentCard: {
    "&:last-child": {
      paddingBottom: theme.spacing(0),
    },
  },
}));

const ContentCard = ({ className, children }: CardProps) => {
  const classes = useStyles();

  return (
    <Card variant="outlined">
      <CardContent className={`${className} ${classes.contentCard}`}>
        {children}
      </CardContent>
    </Card>
  );
};

export default ContentCard;
