import { Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
      }}
    >
      <Typography variant="h5">{t("page-not-found")}</Typography>
    </Box>
  );
};

export default NotFound;
