// Add here scopes for access token to be used at MS Identity Platform endpoints.
const azureClientId =
  process.env.REACT_APP_AZURE_CLIENT_ID ||
  document.documentElement.dataset.azureClientId ||
  "";

export const authRequest = {
  scopes: [
    // see https://github.com/microsoft/azure-spring-boot/issues/476#issuecomment-919291844
    `${azureClientId}/.default`,
  ],
};
