import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { useTranslation } from "react-i18next";
import { getZipCodes } from "../../../api/basedataApiClient";

import Dropdown from "./Dropdown";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface ZipCodesDropdownProps {
  // register: any;
  registerName: string;
  // setValue: any;
  // clearErrors: any;
  // errors: any;
  control: any;
  rules?: any;
}

const ZipCodesDropdown = ({
  // register,
  registerName,
  // setValue,
  // clearErrors,
  // errors,
  control,
  rules,
}: ZipCodesDropdownProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Dropdown
        isOptionEqualToValue={(option: any, value: any) =>
          option.name === value.name
        }
        getOptionLabel={(option) => {
          return option.name;
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        searchField="zipCodePrefix"
        fetchData={getZipCodes}
        control={control}
        label={t("postal-code")}
        name={registerName}
        shouldServerSideSearch={true}
        rules={rules}
      />
    </>
  );
};

export default ZipCodesDropdown;
