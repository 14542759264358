import { Box, IconButton, TextField } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import {
  Article,
  MarketingCampaign,
} from "../../../api/detailedPlanningApiClient";
import { useDrag } from "react-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

export interface UnlistedArticleProps {
  unlistedArticleName: string;
}

const UnlistedArticle = ({
  addArticleToFlyerPage,
  lastAddedArticlePosition,
}: {
  addArticleToFlyerPage: (params: {
    marketingCampaign?: MarketingCampaign | null;
    article?: Article;
    insertionPosition?: { positionId: number; positionIndex: number };
    unlistedArticleName?: string;
    addAsSubposition?: boolean;
  }) => void;

  lastAddedArticlePosition: number | undefined;
}) => {
  const { t } = useTranslation();
  const [unlistedArticleName, setUnlistedArticleName] = useState<string>("");
  const addUnlistedArticle = useCallback(
    (addAsSubposition: boolean) => {
      addArticleToFlyerPage({
        unlistedArticleName: unlistedArticleName,
        addAsSubposition: addAsSubposition,
      });
      setUnlistedArticleName("");
    },
    [unlistedArticleName, addArticleToFlyerPage]
  );

  // eslint-disable-next-line
  const [collected, drag, dragPreview] = useDrag<UnlistedArticleProps>({
    type: "unlistedArticle",
    item: { unlistedArticleName: unlistedArticleName },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  return (
    <Box
      style={{
        marginRight: "auto",
        marginLeft: "1rem",
      }}
    >
      <div ref={drag}>
        <Box display="flex" alignItems="center">
          <TextField
            fullWidth
            size="small"
            label={t("unlisted-article")}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={unlistedArticleName || ""}
            onChange={(event) => {
              setUnlistedArticleName(event.target.value);
            }}
          />
          {unlistedArticleName !== "" && (
            <DragIndicatorIcon sx={{ margin: "0 0 0 10px" }} />
          )}
          {unlistedArticleName !== "" && (
            <IconButton
              size="small"
              color="primary"
              aria-label="add-article"
              onClick={() => {
                addUnlistedArticle(false);
              }}
              style={{ marginLeft: 5, marginRight: 5, padding: "0px" }}
            >
              <AddIcon />
            </IconButton>
          )}
          {lastAddedArticlePosition !== undefined &&
            unlistedArticleName !== "" && (
              <IconButton
                size="small"
                color="primary"
                aria-label="add-article"
                component="span"
                onClick={() => {
                  addUnlistedArticle(true);
                }}
                style={{ padding: "0px", marginRight: "4px" }}
              >
                <PlaylistAddIcon />
              </IconButton>
            )}
        </Box>
      </div>
    </Box>
  );
};

export default UnlistedArticle;
