import { Control } from "react-hook-form";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GenericSelect from "../dropdowns/GenericSelect";
import { DropDownItem } from "../../../api/basedataApiClient";
import GenericTextField from "./GenericTextField";
import GenericSwitch from "../../ad-planning/dropdowns/GenericSwitch";
import {
  ArticleAssignment,
  getPaperSizes,
} from "../../../api/detailedPlanningApiClient";
import { useCallback, useEffect, useState } from "react";

interface AssignmentDetailsProps {
  assignment: ArticleAssignment | null;
  name: string;
  control: Control<any>;
  isPoster: number | boolean | undefined;
}

const AssignmentDetails = ({
  assignment,
  control,
  name,
  isPoster,
}: AssignmentDetailsProps) => {
  const { t } = useTranslation();
  const [paperSizes, setPaperSizes] = useState<DropDownItem[]>();

  const fetchPaperSizes = useCallback(async () => {
    try {
      const paperSizes = await getPaperSizes();
      setPaperSizes(paperSizes);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    fetchPaperSizes();
  }, [fetchPaperSizes]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          padding: "1rem 0",
          marginBottom: "1rem",
          justifyContent: "end",
        }}
      >
        <Box width="70%" marginRight={2}>
          <GenericTextField
            label={t("handling-note")}
            control={control}
            name={name + ".processingNote"}
            disabled={!assignment}
          />
        </Box>
        <Box width="30%" marginRight={2}>
          <GenericTextField
            label={t("extra-note")}
            control={control}
            name={name + ".graphicNote"}
            disabled={!assignment}
          />
        </Box>
        <Box marginRight={2}>
          <GenericSwitch
            control={control}
            name={name + ".poster"}
            label={t("poster")}
            disabled={!assignment}
          />
        </Box>
        <Box width="12em">
          {paperSizes && (
            <GenericSelect
              label={t("poster-dimensions")}
              control={control}
              options={paperSizes}
              name={name + ".paperSize"}
              disabled={!assignment || !isPoster}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default AssignmentDetails;
