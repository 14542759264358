import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export interface LocationProp {
  year: string;
  weekOfYear: string;
  importVersion: string;
  clientele: string;
}

const DetailedPlanningDetailsPage = () => {
  const { t } = useTranslation();
  let location = useLocation();
  // eslint-disable-next-line
  const { year, weekOfYear, importVersion, clientele } =
    location.state as LocationProp;
  useEffect(() => {
    document.title = `${t("detailed-planning")} - Team Beverage`;
  });

  return <></>;
};

export default DetailedPlanningDetailsPage;
