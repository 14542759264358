import {
  Box,
  Paper,
  Table,
  Divider,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  makeStyles,
  TableContainer,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";

import { MarketingPartner } from "../../dto/Adplan";

import MarketingPartnerTableRows from "./MarketingPartnerTableRows";

interface MarketingPartnerTableProps {
  scheduleId: number | undefined;
  marketingPartnersList: MarketingPartner[];
  selectedPartnerId?: number | undefined;
  changeMarketingPartnerHandler: (partner: MarketingPartner) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexFlow: "column",
    height: "calc(100vh - 315px)",
  },

  dividerStyles: {
    marginTop: theme.spacing(2),
  },

  headerCellStyles: {
    textTransform: "capitalize",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  tableContainerStyles: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },
}));

const MarketingPartnerTable = ({
  scheduleId,
  marketingPartnersList,
  selectedPartnerId,
  changeMarketingPartnerHandler,
}: MarketingPartnerTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.tableContainerStyles}>
      <Divider className={classes.dividerStyles} />
      <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader size="small" aria-label={t("collapsible-table")}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCellStyles}>
                {t("marketing-partner")}
              </TableCell>

              <TableCell className={classes.headerCellStyles} align="right">
                {`${t("annual-meeting")} (${marketingPartnersList.length})`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {marketingPartnersList.map((partner, index) => (
              <MarketingPartnerTableRows
                key={partner.id}
                scheduleId={scheduleId ? +scheduleId : undefined}
                partner={partner}
                initiallyOpen={selectedPartnerId === partner.id}
                changeMarketingPartnerHandler={changeMarketingPartnerHandler}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MarketingPartnerTable;
