import GenericSelect from "./GenericSelect";
import { useTranslation } from "react-i18next";
import { DropDownItem } from "../../../api/basedataApiClient";
import { useCallback, useEffect, useState } from "react";
import { getPrintTemplates } from "../../../api/detailedPlanningApiClient";

interface FlyerDropdownProps {
  name: string;
  control: any;
}

const FlyerDropdown = ({ name, control }: FlyerDropdownProps) => {
  const { t } = useTranslation();
  const [printTemplates, setPrintTemplates] = useState<DropDownItem[]>();

  const fetchPrintTemplates = useCallback(async () => {
    try {
      const printTemplates = await getPrintTemplates();
      let printTemplatesResults = printTemplates.results;

      let printTemplatesFormatted = printTemplatesResults.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });

      setPrintTemplates(printTemplatesFormatted);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    fetchPrintTemplates();
  }, [fetchPrintTemplates]);

  return (
    <>
      {printTemplates && (
        <GenericSelect
          name={name}
          label={t("Prospektmuster")}
          control={control}
          options={printTemplates}
          showEmptyOption={false}
        />
      )}
    </>
  );
};

export default FlyerDropdown;
