import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  makeStyles,
  Popper,
  TableRow,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  dividerStyles: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  footerStyles: {
    marginBottom: theme.spacing(2),
  },
  popper: {
    border: `1px solid #e1e4e8`,
    boxShadow: `0 8px 24px rgba(149, 157, 165, 0.2)`,
    borderRadius: 2,
    width: "auto",
    zIndex: theme.zIndex.modal,
    backgroundColor: "#fff",
  },
  popperButton: {
    width: "100%",
    textTransform: "initial",
    fontWeight: 400,
  },
}));

interface AdPlanningFooterProps {
  submitHandler: () => void;
  setExportAdplanningAct: () => void;
  setExportAdplanningAll: () => void;
}

const AdPlanningFooter = ({
  setExportAdplanningAll,
  setExportAdplanningAct,
  submitHandler,
}: AdPlanningFooterProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const id = open ? "linking-positions" : undefined;

  const classes = useStyles();
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  // @ts-ignore
  return (
    <>
      <Divider className={classes.dividerStyles} />
      <Box display="flex" justifyContent="end" className={classes.footerStyles}>
        <Button
          variant="contained"
          color="primary"
          style={{
            marginRight: "1rem",
            paddingRight: "5px",
          }}
          onClick={handleClick}
        >
          {t("export-adplanning")}
          <KeyboardArrowUpIcon
            style={{ marginBottom: "2px", height: "20px" }}
          />
        </Button>
        <Popper
          className={classes.popper}
          id={"export-button-" + id}
          open={open}
          anchorEl={anchorEl}
          placement="top-start"
        >
          <ClickAwayListener onClickAway={handleClose}>
            <div>
              <TableRow>
                <Button
                  className={classes.popperButton}
                  onClick={() => {
                    setExportAdplanningAct();
                  }}
                  variant="text"
                >
                  {t("export-adplanning-actvmp")} Excel
                </Button>
              </TableRow>
              <TableRow>
                <Button
                  className={classes.popperButton}
                  onClick={() => {
                    setExportAdplanningAll();
                  }}
                  variant="text"
                >
                  {t("export-adplanning-allvmp")} Excel
                </Button>
              </TableRow>
            </div>
          </ClickAwayListener>
        </Popper>
        <Button
          variant="contained"
          color="primary"
          style={{
            marginRight: "1rem",
            paddingRight: "5px",
          }}
          onClick={() => submitHandler()}
        >
          {t("save-adplanning")}
        </Button>
      </Box>
    </>
  );
};

export default AdPlanningFooter;
