import { NumericFormat } from "react-number-format";
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";

interface CurrencyInputProps {
  name: string;
  label?: string;
  control: any;
  disabled?: boolean;
  onBlur?: () => void;
  readOnly?: boolean;
  textAlighEnd?: boolean;
  onKeyDown?: (event: any) => void;
}

const NumberInput = ({
  name,
  label,
  control,
  onBlur,
  disabled,
  readOnly,
  textAlighEnd,
  onKeyDown,
}: CurrencyInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref } }) => {
        return (
          <NumericFormat
            readOnly={readOnly}
            customInput={TextField}
            size="small"
            label={label}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            disabled={disabled}
            value={value || null}
            onChange={onChange}
            inputProps={{
              readOnly,
              style: textAlighEnd ? { textAlign: "end" } : undefined,
            }}
            inputRef={ref}
            // prefix="€ "
            decimalScale={0}
            // decimalSeparator={","}
            fixedDecimalScale={true}
          />
        );
      }}
    />
  );
};

export default NumberInput;
