import {
  Box,
  ClickAwayListener,
  Input,
  makeStyles,
  Popper,
} from "@material-ui/core";

import Autocomplete from "@mui/material/Autocomplete";
import DoneIcon from "@mui/icons-material/Done";

import LinkIcon from "@mui/icons-material/Link";

import { Controller, useFormContext } from "react-hook-form";

import { useState } from "react";

import { useTranslation } from "react-i18next";
import { FormValues } from "../../layout/DetailedPlanningDetailsLayout";

export interface LinkedPositionsDropdownProps {
  name: any;
  pageIndex: number;
  index: number;
}

const useStyles = makeStyles((theme) => ({
  linkIcon: {
    margin: "auto 5px",
    padding: "5px",
    width: "2rem!important",
    height: "2rem!important",
    display: "block!important",
    cursor: "pointer",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
      borderRadius: "50%",
    },
  },
  popper: {
    border: `1px solid #e1e4e8`,
    boxShadow: `0 8px 24px rgba(149, 157, 165, 0.2)`,
    borderRadius: 6,
    width: "auto",
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: `#24292e`,
    backgroundColor: "#fff",
  },
}));

const LinkedPositionsDropdown = ({
  name,
  index,
  pageIndex,
}: LinkedPositionsDropdownProps) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { watch, control } = useFormContext<FormValues>();
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "linking-positions" : undefined;

  const emptyFields = () => {
    const watchedPositions = watch(`pages.${pageIndex}.positions`) || [];
    const watchedPositionsIndex = watchedPositions.map((p, i) => {
      p.index = i + 1;
      return p;
    });
    const emptyWatchedPositions = watchedPositionsIndex.filter(
      (c) => !c.articleAssignments?.length
    );

    const emtpyFieldIndex = emptyWatchedPositions.map((e) => e.index);

    const fieldPositionsFromId = watchedPositions
      .map((c, i) => {
        if (emtpyFieldIndex.includes(i + 1)) {
          return i;
        }
        return undefined;
      })
      .filter((x) => x !== undefined);

    return fieldPositionsFromId as number[];
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <>
          <LinkIcon
            onClick={handleClick}
            fontSize="small"
            className={classes.linkIcon}
          />
          <Box style={{ fontSize: "10px" }}>
            {value &&
              value.length !== 0 &&
              "(" +
                value.map((v: number, i: number, row: any) => {
                  return v + 1;
                }) +
                ")"}
          </Box>
          <Popper
            className={classes.popper}
            id={"linked-position-" + id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
          >
            <ClickAwayListener onClickAway={handleClose}>
              <div>
                <Box
                  sx={{
                    padding: "8px 10px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {t("linking-positions")}
                </Box>
                <Autocomplete
                  open
                  multiple
                  value={value && value.map((v: number) => v)}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.name === value + 1
                  }
                  onChange={(e, data) => {
                    onChange(data.map((v) => (v.name ? v.name - 1 : v)));
                  }}
                  disableCloseOnSelect
                  renderTags={() => null}
                  noOptionsText={t("0-results-found")}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Box>{option.name}</Box>
                      <Box
                        component={DoneIcon}
                        sx={{
                          position: "absolute",
                          right: "0",
                          width: "15px!important",
                          height: "15px!important",
                          mr: "5px",
                          ml: "-2px",
                        }}
                        style={{
                          visibility: selected ? "visible" : "hidden",
                        }}
                      />
                    </li>
                  )}
                  options={emptyFields().map((o, index) => {
                    return {
                      id: index,
                      name: o + 1,
                    };
                  })}
                  getOptionLabel={(option) => option.name.toString()}
                  renderInput={(params) => (
                    <Input
                      ref={params.InputProps.ref}
                      inputProps={params.inputProps}
                      autoFocus
                    />
                  )}
                />
              </div>
            </ClickAwayListener>
          </Popper>
        </>
      )}
    />
  );
};

export default LinkedPositionsDropdown;
