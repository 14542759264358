import {
  Box,
  Button,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from "@material-ui/core";

import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AdvertisingSchedule, SCHEDULE_STATUS } from "../../../../dto/Adplan";

const useStyles = makeStyles((theme) => ({
  headerCellStyles: {
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
  },

  FiberManualRecordIconStyles: {},

  greenChip: {
    color: "white",
    backgroundColor: "green",
    marginLeft: theme.spacing(1),
  },

  yellowChip: {
    color: "white",
    backgroundColor: "gold",
    marginLeft: theme.spacing(1),
  },

  grayChip: {
    color: "white",
    backgroundColor: "gray",
    marginLeft: theme.spacing(1),
  },

  redChip: {
    color: "white",
    backgroundColor: "red",
    marginLeft: theme.spacing(1),
  },
}));

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface AdvertisingScheduleTableProps {
  schedules: AdvertisingSchedule[];
}

const AdvertisingScheduleTable = ({
  schedules,
}: AdvertisingScheduleTableProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();

  const selectStatus = (status: string) => {
    switch (status) {
      case SCHEDULE_STATUS.FINISHED:
        return "green";
      case SCHEDULE_STATUS.IN_PROGRESS:
        return "gold";
      case SCHEDULE_STATUS.IN_COPY:
        return "gray";
      case SCHEDULE_STATUS.ERROR:
        return "red";
      default:
        return "";
    }
  };

  return (
    <TableContainer component={Paper} variant="outlined" square>
      <Table aria-label={t("advertisingschedule")}>
        <TableHead>
          <TableRow>
            <TableCell>{t("designation")}</TableCell>
            <TableCell style={{ textAlign: "right" }}>
              <Grid container justifyContent="flex-end">
                <Box className={classes.headerCellStyles}>
                  {t("annual-meeting")}
                  <ArrowDownwardIcon fontSize="small" />
                </Box>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedules &&
            schedules.map((scheduleItem, index) => (
              <StyledTableRow key={index}>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <FiberManualRecordIcon
                      style={{
                        fontSize: "14px",
                        color: selectStatus(scheduleItem.status),
                      }}
                    />
                    <Button
                      size="small"
                      style={{
                        textTransform: "capitalize",
                        fontWeight: "normal",
                      }}
                      onClick={() =>
                        navigate(
                          "/" +
                            t("advertisingschedule").toLowerCase() +
                            `/${scheduleItem.id}`
                        )
                      }
                    >
                      {scheduleItem.name}
                    </Button>
                  </Box>
                </TableCell>
                <TableCell align="right">
                  {scheduleItem.numberInCopy > 0 && (
                    <Chip
                      size="small"
                      label={`${scheduleItem.numberInCopy} ${t("in-copy")}`}
                      className={classes.grayChip}
                    />
                  )}
                  {scheduleItem.numberInProgress > 0 && (
                    <Chip
                      size="small"
                      label={`${scheduleItem.numberInProgress} ${t(
                        "in-progress-status-option-vmp"
                      )}`}
                      className={classes.yellowChip}
                    />
                  )}
                  {scheduleItem.numberFinished > 0 &&
                    scheduleItem.status !== SCHEDULE_STATUS.FINISHED && (
                      <Chip
                        size="small"
                        label={`${scheduleItem.numberFinished} ${t(
                          "finished-status-option-vmp"
                        )}`}
                        className={classes.greenChip}
                      />
                    )}

                  {scheduleItem.numberError > 0 && (
                    <Chip
                      size="small"
                      label={`${scheduleItem.numberError} ${t("no-agreement")}`}
                      className={classes.redChip}
                    />
                  )}
                </TableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdvertisingScheduleTable;
