import { Select, MenuItem, makeStyles } from "@material-ui/core";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { AdvertisingSchedule } from "../../../../dto/Adplan";
import { getSchedules } from "../../../../api/adplanApiClient";

import BaseFormField from "../../../../components/BaseFormField";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  selectStyles: {
    textAlign: "left",
  },

  menuItemStyles: {
    textTransform: "capitalize",
  },
});

const ReferenceYearsDropdown = ({ register, errors }: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [schedules, setSchedules] = useState<AdvertisingSchedule[]>();

  const fetchSchedules = useCallback(async () => {
    try {
      const schedules = await getSchedules();
      setSchedules(schedules);
    } catch (e) {
      enqueueSnackbar(t("error"), {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    fetchSchedules();
  }, [fetchSchedules]);

  return (
    <BaseFormField
      name={t("select-advertising-schedule")}
      errors={errors}
      label={t("select-advertising-schedule")}
    >
      <Select
        defaultValue=""
        label={t("select-advertising-schedule")}
        {...register("referenceYear")}
        className={classes.selectStyles}
      >
        <MenuItem value="" disabled className={classes.menuItemStyles}>
          {t("select-advertising-schedule")}
        </MenuItem>
        {schedules &&
          schedules.map((scheduleItem, index) => (
            <MenuItem
              key={index}
              value={scheduleItem.year}
              className={classes.menuItemStyles}
            >
              {t("advertisingschedule")} {scheduleItem.year}
            </MenuItem>
          ))}
      </Select>
    </BaseFormField>
  );
};

export default ReferenceYearsDropdown;
