import { useTranslation } from "react-i18next";

import { useEffect } from "react";

import Financing from "../../../domain/ad-planning/views/campaign/financing/Financing";

const FinancingPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `${t("advertising-planning")} - Team Beverage`;
  }, [t]);

  return <Financing />;
};

export default FinancingPage;
