import {
  Box,
  Button,
  Typography,
  CardContent,
  TextField,
  makeStyles,
} from "@material-ui/core";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { CreateSchedule } from "../../../../dto/Adplan";

import { createSchedule } from "../../../../api/adplanApiClient";

import ScheduleYearsDropdown from "./ScheduleYearsDropdown";
import ReferenceYearsDropdown from "./ReferenceYearsDropdown";

import ErrorIcon from "@material-ui/icons/Error";

interface NewAdvertisingScheduleFormProps {
  handleClose: () => void;
}

const AdvertisingScheduleForm = ({
  handleClose,
}: NewAdvertisingScheduleFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateSchedule>();

  const onSubmit = async (data: CreateSchedule) => {
    try {
      await createSchedule("Post", {
        name: data.name,
        year: data.year,
        referenceYear: data.referenceYear,
      });
      handleClose();
      enqueueSnackbar(t("successfully-applied"), { variant: "success" });
    } catch (e) {
      enqueueSnackbar(t("error"), { variant: "error" });
    }
  };

  const getHelperText = (type: string) => {
    let text = "";
    switch (type) {
      case "required":
        text = `` + t("name-is-a-required-field");
        break;
      case "maxLength":
        text = t("max-length-exceeded");
        break;
      default:
        break;
    }
    return text;
  };

  return (
    <>
      <CardContent className={classes.customizedCardContent}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column">
            <TextField
              autoFocus
              label={t("name")}
              variant="outlined"
              defaultValue={t("new-advertising-plan")}
              {...register("name", {
                required: true,
                maxLength: 128,
              })}
              error={errors.name ? true : false}
              helperText={
                errors.name ? (
                  <Typography
                    component={"span"}
                    variant={"body2"}
                    className={classes.wrapIcon}
                  >
                    <ErrorIcon fontSize="small" />
                    <Typography
                      component={"span"}
                      variant={"body2"}
                      className={classes.iconTextStyles}
                    >
                      {getHelperText(errors.name.type)}
                    </Typography>
                  </Typography>
                ) : undefined
              }
            />

            <Box className={classes.boxHeight}></Box>
            <ScheduleYearsDropdown register={register} errors={errors} />
            <Box className={classes.boxHeight}></Box>
            <ReferenceYearsDropdown register={register} errors={errors} />
            <Box className={classes.btnGroupStyles}>
              <Button color="primary" onClick={handleClose}>
                {t("abort")}
              </Button>
              <Button variant="contained" color="primary" type="submit">
                {t("create-an-ad-plan")}
              </Button>
            </Box>
          </Box>
        </form>
      </CardContent>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  customizedCardContent: {
    width: "450px",
    padding: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  boxHeight: {
    height: "20px",
  },

  btnGroupStyles: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },

  wrapIcon: {
    marginLeft: theme.spacing(-2),
    verticalAlign: "middle",
    display: "inline-flex",
  },

  iconTextStyles: {
    marginLeft: theme.spacing(0.5),
    marginTop: "2px",
    fontSize: "12px",
  },
}));

export default AdvertisingScheduleForm;
