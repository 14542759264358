import { useEffect, useState } from "react";
import { allArticles } from "../api/detailedPlanningApiClient";

const useAllArticlesSearch = (importVersion: string, clientele: string, query: string, pageNumber: number) => {
  const [loading, setLoading] = useState(true);
  const [articlesByBrandFormatted, setArticlesByBrandFormatted] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setArticlesByBrandFormatted([]);
  }, [query]);

  useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      (async () => {
        let result = await allArticles({
          version: parseInt(importVersion),
          clientele: clientele,
          page: pageNumber,
          limit: 100,
          search: query,
        });

        let articlesByBrandFormatted = Object.entries(result.articlesByBrand);

        setHasMore(articlesByBrandFormatted.length > 0);
        setLoading(false);

        setArticlesByBrandFormatted((prev) => {
          let total = prev.concat(articlesByBrandFormatted);
          return total;
        });
      })();
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [query, pageNumber, clientele, importVersion]);

  return {loading, articlesByBrandFormatted, hasMore};
};

export default useAllArticlesSearch;
