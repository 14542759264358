import { Button, ButtonProps } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { DropzoneDialogBase } from "material-ui-dropzone";
import { GridCloseIcon } from "@material-ui/data-grid";
import { articlerangeFileUpload } from "../../api/basedataApiClient";
import { useSnackbar } from "notistack";
// eslint-disable-next-line
import { useBlocker } from "react-router-dom";

const UploadButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileObjects, setFileObjects] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  const uploadFile = async () => {
    if (fileObjects[0]) {
      try {
        setLoading(true);
        await articlerangeFileUpload(fileObjects[0]);
        enqueueSnackbar(t("upload-success"), { variant: "success" });
        setLoading(false);
        setOpenUploadModal(false);
        setFileObjects([]);
      } catch (e) {
        setLoading(false);
        const error = e as Error;
        enqueueSnackbar(error.message, { variant: "error" });
      }
    }
  };

  const dialogTitle = () => (
    <>
      <span>{t("upload-headline")}</span>
      <IconButton
        style={{ right: "12px", top: "8px", position: "absolute" }}
        onClick={() => setOpenUploadModal(false)}
      >
        <GridCloseIcon />
      </IconButton>
    </>
  );

  // Block navigating elsewhere when data has been entered into the input
  // let blocker = useBlocker(
  //   ({ currentLocation, nextLocation }) =>
  //     loading && currentLocation.pathname !== nextLocation.pathname
  // );

  return (
    <>
      {/*{blocker.state === "blocked" ? (*/}
      {/*<div>*/}
      {/*  <p>{t("upload-in-progress") + t("upload-in-progress-leave")}</p>*/}
      {/*  <button onClick={() => blocker.proceed?.()}>{t("proceed")}</button>*/}
      {/*  <button onClick={() => blocker.reset?.()}>{t("abort")}</button>*/}
      {/*</div>*/}
      {/*) : null}*/}
      <Button
        {...props}
        variant="outlined"
        color="secondary"
        onClick={() => setOpenUploadModal(true)}
      >
        {t("upload")}
      </Button>
      <DropzoneDialogBase
        dialogProps={{
          open: openUploadModal,
          classes: { root: loading ? "loading-dialog" : "" },
          onClose: () => {
            if (loading) {
              enqueueSnackbar(t("upload-in-progress"), { variant: "error" });
            }
          },
        }}
        dialogTitle={dialogTitle()}
        fileObjects={fileObjects}
        maxFileSize={5000000}
        open={openUploadModal}
        onAdd={(newFileObjs: any) => {
          if (loading) {
            enqueueSnackbar(t("upload-in-progress"), { variant: "error" });
            return;
          } else {
            setFileObjects([]);
            setFileObjects([].concat(fileObjects, newFileObjs));
          }
        }}
        onDelete={(deleteFileObj) => {
          if (loading) {
            enqueueSnackbar(t("upload-in-progress"), { variant: "error" });
            return;
          } else {
            setFileObjects([]);
          }
        }}
        onClose={() => {
          if (loading) {
            enqueueSnackbar(t("upload-in-progress"), { variant: "error" });
          } else {
            setOpenUploadModal(false);
            setFileObjects([]);
          }
        }}
        onSave={() => {
          if (loading) {
            enqueueSnackbar(t("upload-in-progress"), { variant: "error" });
            return;
          } else {
            uploadFile();
          }
        }}
        showPreviews={false}
        alertSnackbarProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        }}
        acceptedFiles={[".xlsx"]}
        useChipsForPreview
        filesLimit={1}
        dropzoneText={t("upload-dnd-text")}
        cancelButtonText={t("abort")}
        submitButtonText={t("upload")}
        showPreviewsInDropzone={true}
        showFileNamesInPreview={false}
        getFileAddedMessage={(fileName) =>
          t("upload-file-added", {
            fileName: fileName,
          })
        }
        getFileLimitExceedMessage={(filesLimit) =>
          t("upload-file-limit-exceed", {
            fileName: filesLimit,
          })
        }
        getFileRemovedMessage={(fileName) =>
          t("upload-file-removed", {
            fileName: fileName,
          })
        }
        getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
          let message = t("upload-file-rejected", {
            fileName: rejectedFile.name,
          });
          if (!acceptedFiles.includes(rejectedFile.type)) {
            message += t("upload-file-not-supportet");
          }
          if (rejectedFile.size > maxFileSize) {
            message += t("upload-file-too-big", {
              fileSize: formatBytes(maxFileSize),
            });
          }
          return message;
        }}
      />
    </>
  );
};
export default UploadButton;
