import {
  Box,
  Grid,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SearchInput from "./SearchInput";
import ClientelesTable from "../detailed-planning/components/ClientelesTable";
import { useForm, useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import YearsDropdown from "../detailed-planning/dropdowns/YearsDropdown";
import { getYears } from "../../api/detailedPlanningApiClient";
import WeeksOfYearDropdown from "../detailed-planning/dropdowns/WeeksOfYearDropdown";
import { getWeeksOfYear } from "../../api/detailedPlanningApiClient";
import SaleslineDropdown from "../detailed-planning/dropdowns/SaleslineDropdown";
import SalesDistrictDropdown from "../detailed-planning/dropdowns/SalesDistrictDropdown";
import { DropDownItem } from "../../api/basedataApiClient";
import { getClienteles } from "../../api/detailedPlanningApiClient";
import { Clienteles } from "../../api/detailedPlanningApiClient";
import StatusDropdownMultiple from "../detailed-planning/dropdowns/StatusDropdownMultiple";
interface FormValues {
  yearsSelected: string;
  weeksOfYearSelected: DropDownItem[];
  saleslineSelected: DropDownItem[];
  salesDistrictSelected: DropDownItem[];
  statusSelected: DropDownItem[];
}

const DetailedPlanningSideNav = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const weeksOfYearSelected = localStorage.getItem(
    "tbg-select-weeksOfYearSelected"
  );
  const saleslineSelected = localStorage.getItem(
    "tbg-select-saleslineSelected"
  );
  const salesDistrictSelected = localStorage.getItem(
    "tbg-select-salesDistrictSelected"
  );
  const statusSelected = localStorage.getItem("tbg-select-statusSelected");

  const searchValueSelected = localStorage.getItem("tbg-select-searchValueSelected");

  function isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const defaultValues = {
    yearsSelected: localStorage.getItem("tbg-select-yearsSelected") || "",
    weeksOfYearSelected:
      weeksOfYearSelected &&
      isJson(weeksOfYearSelected) &&
      JSON.parse(weeksOfYearSelected) instanceof Array
        ? JSON.parse(weeksOfYearSelected)
        : undefined,
    saleslineSelected:
      saleslineSelected &&
      isJson(saleslineSelected) &&
      JSON.parse(saleslineSelected) instanceof Array
        ? JSON.parse(saleslineSelected)
        : undefined,
    salesDistrictSelected:
      salesDistrictSelected &&
      isJson(salesDistrictSelected) &&
      JSON.parse(salesDistrictSelected) instanceof Array
        ? JSON.parse(salesDistrictSelected)
        : undefined,
    statusSelected:
      statusSelected &&
      isJson(statusSelected) &&
      JSON.parse(statusSelected) instanceof Array
        ? JSON.parse(statusSelected)
        : undefined,
  };

  const { control, setValue } = useForm<FormValues>({
    defaultValues,
  });

  const [weeksOfYear, setWeeksOfYear] = useState<DropDownItem[]>();
  const [clienteles, setClienteles] = useState<Clienteles[]>();
  const [filteredList, setFilteredList] = useState<Clienteles[]>();
  const [searchValue, setSearchValue] = useState(searchValueSelected || "");
  // @ts-ignore
  const formValues: FormValues = useWatch({ control });

  const getClientelesCallback = useCallback(async () => {
    if (formValues?.yearsSelected) {
      const clienteles = await getClienteles({
        year: +formValues.yearsSelected,
        weekOfYear: formValues.weeksOfYearSelected
          ? formValues.weeksOfYearSelected?.map((x) => x.id)
          : undefined,
        salesLineId: formValues.saleslineSelected
          ? formValues.saleslineSelected.map((x) => x.id)
          : undefined,
        salesDistrictId: formValues.salesDistrictSelected
          ? formValues.salesDistrictSelected.map((x) => x.id)
          : undefined,
        status: formValues.statusSelected
          ? formValues.statusSelected.map((x) => x.name)
          : undefined,
      });
      setClienteles([...clienteles.clienteles]);
    }
  }, [formValues]);

  const getWeeksOfYearCallback = useCallback(async () => {
    if (formValues.yearsSelected) {
      const weeksOfYear = await getWeeksOfYear(formValues.yearsSelected);
      setWeeksOfYear(weeksOfYear);
    }
  }, [formValues.yearsSelected]);

  useEffect(() => {
    getClientelesCallback();
  }, [getClientelesCallback]);

  useEffect(() => {
    getWeeksOfYearCallback();
  }, [getWeeksOfYearCallback]);

  useEffect(() => {
    (async () => {
      if (!localStorage.getItem("tbg-select-yearsSelected")) {
        const years = await getYears();
        const lastYear = years[years.length - 1];
        const actualYear = new Date().getFullYear();
        // @ts-ignore
        years.includes(actualYear)
          ? setValue("yearsSelected", actualYear.toString())
          : setValue("yearsSelected", lastYear.toString());
      }
    })();
  }, [setValue]);

  useEffect(() => {
    let filtered = clienteles || [];
    const search = searchValue || searchValueSelected;
    filtered = search
      ? filtered.filter((row) => {
          return (
            row.clientele.toLowerCase().includes(search.toLowerCase()) ||
            row.customers.find((row) =>
              row.name.toLowerCase().includes(search.toLowerCase())
            )
          );
        })
      : filtered;
    setFilteredList(filtered);
    localStorage.setItem(
        "tbg-select-searchValueSelected",
        searchValue
    );
  }, [searchValue, clienteles, searchValueSelected]);

  return clienteles && weeksOfYear ? (
    <>
      <Box pb={4}>
        <Typography component="span" variant="h5">
          {t("detailed-planning")}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Box className={classes.dropdownStyles}>
            <YearsDropdown name="yearsSelected" control={control} />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.dropdownStyles}>
            <WeeksOfYearDropdown
              name="weeksOfYearSelected"
              control={control}
              options={weeksOfYear || []}
            />
          </Box>
        </Grid>

        <Grid item xs={2}>
          <Box className={classes.dropdownStyles}>
            <SaleslineDropdown name="saleslineSelected" control={control} />
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Box className={classes.dropdownStyles}>
            <SalesDistrictDropdown
              name="salesDistrictSelected"
              control={control}
            />
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Box className={classes.dropdownStyles}>
            <StatusDropdownMultiple
              localStorage={true}
              name="statusSelected"
              control={control}
            />
          </Box>
        </Grid>
      </Grid>

      <Box display="flex" flexDirection="column" pt={2}>
        <SearchInput requestSearch={setSearchValue} value={searchValue} />
      </Box>

      <ClientelesTable
        clienteles={filteredList || []}
        getClientelesCallback={getClientelesCallback}
      />
    </>
  ) : (
    <CircularProgress className={classes.CircularProgressStyles} />
  );
};

const useStyles = makeStyles((theme) => ({
  dropdownStyles: {
    width: "auto",
  },

  CircularProgressStyles: {
    display: "block",
    margin: "2rem auto 3rem",
  },
}));

export default DetailedPlanningSideNav;
