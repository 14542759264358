import { callApi } from "./callApi";
import {
  CreateSchedule,
  Schedule,
  AddMarketingPartner,
  CreateCampaign,
  CopyCampaign,
  UpdateSchedulePartnerStateResponse,
  UpdateCampaignResponse,
  AnnualMeeting,
  AdPlanning,
  AdvertisingSchedule,
} from "../dto/Adplan";

export function getScheduleYears(): Promise<string[]> {
  return callApi("/api/ui/schedule/years", "GET");
}

export function getSchedules(): Promise<AdvertisingSchedule[]> {
  return callApi("/api/ui/schedule", "GET");
}

export function createSchedule(
  method: string,
  payload: CreateSchedule
): Promise<Schedule> {
  return callApi("/api/ui/schedule", method, payload);
}

export function getScheduleDetails(scheduleId: number): Promise<Schedule> {
  return callApi("/api/ui/schedule/" + scheduleId, "GET");
}

export function addMarketingPartner(
  scheduleId: number,
  partnerId: number,
  method: string
): Promise<AddMarketingPartner> {
  return callApi(`/api/ui/schedule/${scheduleId}/partner/${partnerId}`, method);
}

export function updateSchedulePartnerState(
  schedulePartnerId: number,
  method: string,
  payload: { state: string }
): Promise<UpdateSchedulePartnerStateResponse> {
  return callApi(
    `/api/ui/schedulepartner/${schedulePartnerId}/state`,
    method,
    payload
  );
}

export function createCampaign(
  schedulePartnerId: number,
  method: string,
  payload: CreateCampaign
): Promise<any> {
  return callApi(
    `/api/ui/schedulepartner/${schedulePartnerId}/campaign`,
    method,
    payload
  );
}

export function updateCampaign(
  campaignId: number,
  method: string,
  payload?: CreateCampaign
): Promise<UpdateCampaignResponse> {
  return callApi(`/api/ui/campaign/${campaignId}`, method, payload);
}

export function copyCampaign(
  campaignId: number,
  method: string,
  payload?: CopyCampaign
): Promise<UpdateCampaignResponse> {
  return callApi(`/api/ui/campaign/${campaignId}/copy`, method, payload);
}

export function removeMarketingPartner(
  partnerId: number,
  year: number,
  method: string
): Promise<UpdateCampaignResponse> {
  return callApi(`/api/ui/schedulepartner/${partnerId}/year/${year}`, method);
}

export function updateAnnualMeeting(
  campaignId: number,
  method: string,
  payload: any
): Promise<any> {
  return callApi(
    `/api/ui/campaign/${campaignId}/annualmeeting`,
    method,
    payload
  );
}

export function setExportAdplanAct(campaignId: number): Promise<AnnualMeeting> {
  return callApi(
    `/api/ui/detailedplanning/${campaignId}/exportadplanact`,
    "POST"
  );
}

export function setExportAdplanAll(campaignId: number): Promise<AnnualMeeting> {
  return callApi(
    `/api/ui/detailedplanning/${campaignId}/exportadplanall`,
    "POST"
  );
}

export function getAnnualMeeting(campaignId: number): Promise<AnnualMeeting> {
  return callApi(`/api/ui/campaign/${campaignId}/annualmeeting`, "GET");
}

export function getAdPlanning(campaignId: number): Promise<AdPlanning> {
  return callApi(`/api/ui/campaign/${campaignId}/adplanning`, "GET");
}
