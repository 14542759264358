import { createTheme } from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";

const theme = createTheme({
  palette: {
    primary: indigo,
    secondary: {
      main: "#fafafa",
    },
  },
});

export default theme;
