import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Box, CircularProgress } from "@material-ui/core";
interface ConfirmDialogProps {
  title?: string;
  open: boolean;
  message: string;
  loading?: boolean;
  handleConfirm: () => void;
  handleReject?: () => void;
  handleAlternativeConfirm?: () => void;
  showRejectButton?: boolean;
  showAlternativeConfirmButton?: boolean;
  ariaLabel?: string;
  ariaDescription?: string;
  confirmBtnText: string;
  alternativeConfirmBtnText?: string;
}

const ConfirmDialog = ({
  title,
  open,
  message,
  loading,
  handleConfirm,
  handleReject,
  handleAlternativeConfirm,
  showRejectButton = true,
  showAlternativeConfirmButton = false,
  ariaLabel,
  ariaDescription,
  confirmBtnText,
  alternativeConfirmBtnText,
}: ConfirmDialogProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleReject}
      aria-labelledby={ariaLabel}
      aria-describedby={ariaDescription}
    >
      <Box
        style={{
          opacity: loading ? "0.5" : "1",
        }}
      >
        {title && <DialogTitle id={ariaLabel}>{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText id={ariaDescription}>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {showRejectButton && (
            <Button onClick={handleReject}>{t("abort")}</Button>
          )}
          <Button onClick={handleConfirm} autoFocus>
            {confirmBtnText}
          </Button>
          {showAlternativeConfirmButton && (
            <Button onClick={handleAlternativeConfirm}>
              {alternativeConfirmBtnText}
            </Button>
          )}
        </DialogActions>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            position: "absolute",
            left: "0",
            right: "0",
            margin: "auto",
            top: "0",
            bottom: "0",
          }}
        />
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
