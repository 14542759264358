import { useTranslation } from "react-i18next";
import { getSalesDistrict } from "../../../api/detailedPlanningApiClient";
import { useEffect, useCallback, useState } from "react";
import { DropDownItem } from "../../../api/basedataApiClient";
import GenericMultipleSelect from "./GenericMultipleSelect";

interface SalesDistrictDropdownProps {
  name: string;
  control: any;
}

const SalesDistrictDropdown = ({
  name,
  control,
}: SalesDistrictDropdownProps) => {
  const { t } = useTranslation();
  const [salesDistrict, setSalesDistrict] = useState<DropDownItem[]>([]);

  const fetchSalesDistrict = useCallback(async () => {
    try {
      const salesDistrict = await getSalesDistrict();
      let salesDistrictFormatted = salesDistrict.results;
      setSalesDistrict(salesDistrictFormatted);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    fetchSalesDistrict();
  }, [fetchSalesDistrict]);

  return (
    <GenericMultipleSelect
      name={name}
      label={t("sales-representative")}
      control={control}
      options={salesDistrict}
      localStorage={true}
    />
  );
};

export default SalesDistrictDropdown;
