import {AdPlanning, AdPlanningPositionFormDto, mapAdPlanning, PeriodDropdownItem,} from "../dto/Adplan";
import {callApi} from "./callApi";

export enum EXPORT_TYPE {
    EXPORTADPLANALL = "EXPORTADPLANALL",
    EXPORTADPLANACT = "EXPORTADPLANACT",
    EXPORTADPLAN = "EXPORTADPLAN",
    INSERTIONSEXPORT = "INSERTIONSEXPORT",
    CLIENTELEINTERNALL = "CLIENTELEINTERNALL",
    CLIENTELEINTERN = "CLIENTELEINTERN",
    CLIENTELENEWSMEDIA = "CLIENTELENEWSMEDIA",
    CLIENTELEEXTERNALL = "CLIENTELEEXTERNALL",
    CLIENTELEEXTERN = "CLIENTELEEXTERN",
    KASSENEXPORT = "KASSENEXPORT",
    KASSENEXPORTWEEK = "KASSENEXPORTWEEK",
    KASSENEXPORTALL = "KASSENEXPORTALL",
    DRUCKVERSAND = "DRUCKVERSAND",
    DRUCKVERSANDWEEK = "DRUCKVERSANDWEEK",
    DRUCKVERSANDALL = "DRUCKVERSANDALL",
    EXPORTPDF = "EXPORTPDF",
    EXPORTPDF_MAILTO = "EXPORTPDF-MAILTO",
}

export enum EXPORT_STATE {
    NEW = "NEW",
    IN_PROGRESS = "IN_PROGRESS",
    FINISHED = "FINISHED",
}

export interface DropDownResult<T = DropDownItem> {
    total: number;
    results: T[];
}

export interface DropDownStringArrayResult {
    total: number;
    results: string[];
}

export interface DropDownItem {
    id: number;
    name: string;
}

export interface GetDropDownItemsParams {
    search?: string;
    marketingPartnerId?: number;
    campaignId?: string;
    zipCodePrefix?: string;
    brandIds?: number[];
    articleIds?: number[];
    packageSizeIds?: string[];
}

export interface CustomerCountParams {
    marketingPartnerId: number;
    salesLineId?: string;
    conditionAreaId?: string;
    creditorId?: string;
    objectGroup?: string;
    federalStateId?: string;
    rangeRegionId?: string;
    nielsenRegionId?: string;
    zipCode?: string;
    salesDistrictId?: string;
}

interface CustomerCountResult {
    count: number;
}

export interface ArticleCountParams {
    marketingPartnerId?: number[]; // TODO - should be required
    brandId?: string[];
    productHierarchyId?: string[];
    productKindId?: string[];
    articleId?: string[];
    containerReturnPolicy?: string[];
    packageSize?: string[];
}

export interface CreateAdplanning {
    positions: AdPlanningPositionFormDto[];
}

export interface DownloadDTO {
    filename: string;
    mediaType: string;
    exportType: EXPORT_TYPE;
    exportState: EXPORT_STATE;
    fileExtension: string;
    version: number;
    year: number;
    weekofyear: number;
    clientele: string;
    campaignId: number;
}

export interface DownloadParams {
    fileName: string;
}

export function toArticleCountParams(
    marketingPartnerId: number,
    articleData: any
): ArticleCountParams {
    const brandId = articleData.brands
        ? articleData.brands.map((x: any) => x.id.toString())
        : [];
    const productHierarchyId = articleData.productHierarchyLevel3Ids
        ? articleData.productHierarchyLevel3Ids.map((x: any) => x.id.toString())
        : [];
    const productKindId = articleData.productKinds
        ? articleData.productKinds.map((x: any) => x.id.toString())
        : [];
    const articleId = articleData.articles
        ? articleData.articles.map((x: any) => x.id.toString())
        : [];
    const containerReturnPolicy = articleData.containerReturnPolicies
        ? articleData.containerReturnPolicies.map((x: any) => x.id.toString())
        : [];
    const packageSize = articleData.packageSizes
        ? articleData.packageSizes.map((x: any) => x.id.toString())
        : [];

    return {
        marketingPartnerId: [marketingPartnerId],
        brandId,
        productHierarchyId,
        productKindId,
        articleId,
        containerReturnPolicy,
        packageSize,
    };
}

export interface FindRangeSelectableParams extends ArticleCountParams {
    search?: string;
    limit?: number;
}

interface ArticleCountResult {
    count: number;
}

export function getMarketingPartners() {
    return callApi<DropDownResult>("/api/ui/partner", "GET");
}

export function getSalesline(params: GetDropDownItemsParams) {
    return getDropdownItems<DropDownResult>("/api/ui/salesline", {
        search: params.search,
    });
}

export function getConditionArea(params: GetDropDownItemsParams) {
    return getDropdownItems<DropDownResult>("/api/ui/conditionarea", {
        search: params.search,
    });
}

export function getObjectGroup(params: GetDropDownItemsParams) {
    return getDropdownItems<DropDownResult>(
        `/api/ui/objectgroup/${params.marketingPartnerId}`,
        {
            search: params.search,
        }
    );
}

export function getCreditor(params: GetDropDownItemsParams) {
    return getDropdownItems<DropDownResult>("/api/ui/creditor", {
        search: params.search,
    });
}

export function getNielsenregion(params: GetDropDownItemsParams) {
    return getDropdownItems<DropDownResult>("/api/ui/nielsenregion", {
        search: params.search,
    });
}

export function getFederalState(params: GetDropDownItemsParams) {
    return getDropdownItems<DropDownResult>("/api/ui/federalstate", {
        search: params.search,
    });
}

export function getBrand(
    params: GetDropDownItemsParams,
    params2: FindRangeSelectableParams | undefined
): Promise<DropDownResult> {
    return callApi("/api/ui/brand", "Post", params2);
}

export function getArticle(
    params: GetDropDownItemsParams,
    params2: FindRangeSelectableParams | undefined
): Promise<DropDownResult> {
    return callApi("/api/ui/article", "Post", params2);
}

export function getProductHierarchy(
    params: GetDropDownItemsParams,
    params2: FindRangeSelectableParams | undefined
): Promise<DropDownResult> {
    return callApi("/api/ui/producthierarchy", "Post", params2);
}

export function getProductKind(
    params: GetDropDownItemsParams,
    params2: FindRangeSelectableParams | undefined
): Promise<DropDownResult> {
    return callApi("/api/ui/productkind", "Post", params2);
}

export function getContainerReturnPolicy(
    params: GetDropDownItemsParams,
    params2: FindRangeSelectableParams | undefined
): Promise<DropDownResult> {
    return callApi("/api/ui/article/containerreturnpolicy", "Post", params2);
}

export function getPackageSize(
    params: GetDropDownItemsParams,
    params2: FindRangeSelectableParams | undefined
): Promise<DropDownResult> {
    return callApi("/api/ui/article/packagesize", "Post", params2);
}

export function getRangeRegion(params: GetDropDownItemsParams) {
    return getDropdownItems<DropDownResult>("/api/ui/rangeregion", {
        search: params.search,
    });
}

export function getSalesDistrict(params: GetDropDownItemsParams) {
    return getDropdownItems<DropDownResult>("/api/ui/salesdistrict", {
        search: params.search,
    });
}

export async function getZipCodes(
    params: GetDropDownItemsParams
): Promise<DropDownResult> {
    let response = await getDropdownItems<DropDownStringArrayResult>(
        "/api/ui/customer/zipcodes",
        {
            zipCodePrefix: params.zipCodePrefix,
        }
    );

    if (response && response.results && Array.isArray(response.results)) {
        let results = response.results.map((item, index) => {
            return {
                id: index,
                name: item,
            };
        });
        return {
            total: results.length,
            results: results,
        };
    }
    return {
        total: 0,
        results: [],
    };
}

export function getCustomerCount(
    params: CustomerCountParams
): Promise<CustomerCountResult> {
    let queryStr = `/api/ui/customer/count?marketingPartnerId=${params.marketingPartnerId}`;

    if (params.salesLineId) queryStr += `&salesLineId=${params.salesLineId}`;
    if (params.conditionAreaId)
        queryStr += `&conditionAreaId=${params.conditionAreaId}`;
    if (params.creditorId) queryStr += `&creditorId=${params.creditorId}`;
    if (params.objectGroup) queryStr += `&objectGroup=${params.objectGroup}`;
    if (params.federalStateId)
        queryStr += `&federalStateId=${params.federalStateId}`;
    if (params.rangeRegionId)
        queryStr += `&rangeRegionId=${params.rangeRegionId}`;
    if (params.nielsenRegionId)
        queryStr += `&nielsenRegionId=${params.nielsenRegionId}`;
    if (params.zipCode) queryStr += `&zipCode=${params.zipCode}`;
    if (params.salesDistrictId)
        queryStr += `&salesDistrictId=${params.salesDistrictId}`;

    return callApi(queryStr, "GET");
}

export function postArticleCount(
    params: ArticleCountParams
): Promise<ArticleCountResult> {
    return callApi("/api/ui/article/count", "Post", params);
}

export function getDropdownItems<DropDownResult>(
    url: string,
    params: GetDropDownItemsParams
): Promise<DropDownResult> {
    let prefix = "?";
    let queryStr = `${url}`;
    if (params.marketingPartnerId) {
        queryStr += `${prefix}marketingPartnerId=${params.marketingPartnerId}`;
        prefix = "&";
    }
    if (params.brandIds && params.brandIds.length > 0) {
        queryStr += `${prefix}brandId=${params.brandIds}`;
        prefix = "&";
    }
    if (params.packageSizeIds && params.packageSizeIds.length > 0) {
        queryStr += `${prefix}packageSize=${params.packageSizeIds}`;
        prefix = "&";
    }
    if (params.articleIds && params.articleIds.length > 0) {
        queryStr += `${prefix}articleId=${params.articleIds}`;
        prefix = "&";
    }
    if (params.search) {
        queryStr += `${prefix}search=${params.search}`;
        prefix = "&";
    }
    if (params.zipCodePrefix) {
        queryStr += `${prefix}zipCodePrefix=${params.zipCodePrefix}`;
    }

    return callApi(queryStr, "GET");
}

export function getAdplanningBrand(params: GetDropDownItemsParams) {
    return getDropdownItems<DropDownResult>(
        `/api/ui/campaign/${params.campaignId}/adplanning/brand`,
        params
    );
}

export function getAdplanningPackageSize(params: GetDropDownItemsParams) {
    return getDropdownItems<DropDownResult>(
        `/api/ui/campaign/${params.campaignId}/adplanning/packagesize`,
        params
    );
}

export function getAdplannigArticle(
    params: GetDropDownItemsParams
): Promise<DropDownResult> {
    return getDropdownItems<DropDownResult>(
        `/api/ui/campaign/${params.campaignId}/adplanning/article`,
        params
    );
}

export function getAdplannigPeriods(
    params: GetDropDownItemsParams
): Promise<DropDownResult<PeriodDropdownItem>> {
    return getDropdownItems<DropDownResult<PeriodDropdownItem>>(
        `/api/ui/campaign/${params.campaignId}/adplanning/period`,
        {
            search: params.search,
        }
    );
}

export function getAdplannigSalesLine(
    params: GetDropDownItemsParams
): Promise<DropDownResult> {
    return getDropdownItems<DropDownResult>(
        `/api/ui/campaign/${params.campaignId}/adplanning/salesline`,
        {
            search: params.search,
        }
    );
}

export function getAdplannigConditionArea(
    params: GetDropDownItemsParams
): Promise<DropDownResult> {
    return getDropdownItems<DropDownResult>(
        `/api/ui/campaign/${params.campaignId}/adplanning/conditionarea`,
        {
            search: params.search,
        }
    );
}

export function createAdplanning(
    campaignId: number,
    adPlanning: AdPlanning
): Promise<any> {
    const payload = mapAdPlanning(adPlanning);
    return callApi(`/api/ui/campaign/${campaignId}/adplanning`, "post", payload);
}

export function articlerangeFileUpload(file: {
    data: string;
    file: File;
}): Promise<void> {
    const data = new FormData();
    data.append("file", file.file);
    return callApi(`/api/ui/upload/articlerange/`, "POST", null, false, data);
}

export function getNotifications(): Promise<DownloadDTO[]> {
    return callApi("/api/ui/export/downloads", "GET");
}

export function getExportFile(params: DownloadParams, preventDownload = false) {
    return callApi(
        `/api/ui/export/download?fileName=${params.fileName}`,
        "GET",
        null,
        false,
        null,
        preventDownload
    );
}
