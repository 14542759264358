import * as React from "react";
import { makeStyles } from "@material-ui/core";
import {
  ArticleAssignment,
  DetailedPlanningDetailPage,
} from "../../../api/detailedPlanningApiClient";
import { useDrop } from "react-dnd";
import { UnlistedArticleProps } from "./UnlistedArticle";
import { InsertionArticle } from "./ArticleRow";

interface DroppableTabProps {
  index: number;
  item: DetailedPlanningDetailPage;
  tabChange: (value: number) => void;
  handleTabDropUnlisted: (item: UnlistedArticleProps, index: number) => void;
  handleTabDropArticle: (item: InsertionArticle, index: number) => void;
  handleTabDropAssigned: (item: ArticleAssignment[], index: number) => void;
}

const DroppableTab = ({
  index,
  item,
  tabChange,
  handleTabDropUnlisted,
  handleTabDropArticle,
  handleTabDropAssigned,
}: DroppableTabProps) => {
  const useTabStyles = makeStyles((theme) => ({
    tabStyles: {
      cursor: "pointer",
      fontweight: "500",
      fontSize: "0.875rem",
      lineHeight: "1.25",
      letterSpacing: "0.02857em",
      textTransform: "uppercase",
      maxWidth: "360px",
      minWidth: "90px",
      position: "relative",
      minHeight: "48px",
      flexShrink: 0,
      padding: "12px 16px",
      overflow: "hidden",
      whiteSpace: "normal",
      textAlign: "center",
    },
  }));

  const classes = useTabStyles();
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: ["article", "unlistedArticle", "assignedArticle"],
    drop: (item: any) => {
      if (item.unlistedArticleName) {
        handleTabDropUnlisted(item, index);
      } else if (item.article) {
        (async () => {
          handleTabDropArticle(item, index);
        })();
      } else if (item.articleAssignments) {
        handleTabDropAssigned(item.articleAssignments, index);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      className={classes.tabStyles}
      style={{
        backgroundColor: canDrop ? "rgba(244, 244, 244, 1)" : undefined,
        boxShadow: isOver ? "inset 0px 0px 3px #1976d2" : "none",
      }}
      ref={dropRef}
      // label={item.name || index + 1}
      {...a11yProps(index)}
      key={"tab-" + index}
      onClick={() => {
        tabChange(index);
      }}
    >
      {item.name || index + 1}
    </div>
  );
};

export default DroppableTab;
