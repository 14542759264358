import {
  Box,
  ListItem,
  ListItemText,
  TableCell,
  TableRow,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import { useState, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ArticleRow from "./ArticleRow";
import {
  Article,
  MarketingCampaign,
} from "../../../api/detailedPlanningApiClient";

interface AllArticlesRowProps {
  initiallyOpen?: boolean;
  articlesByBrandKey: string;
  articlesByBrandValue: any;
  clientele: string;
  importVersion: string;
  index: number;
  lastAddedArticlePosition: number | undefined;
  addArticleToFlyerPage: (params: {
    marketingCampaign?: MarketingCampaign | null;
    article?: Article;
    insertionPosition?: { positionId: number; positionIndex: number };
    unlistedArticleName?: string;
    addAsSubposition?: boolean;
  }) => void;
}

const AllArticlesRow = forwardRef(
  (
    {
      initiallyOpen,
      articlesByBrandKey,
      articlesByBrandValue,
      index,
      clientele,
      importVersion,
      lastAddedArticlePosition,
      addArticleToFlyerPage,
    }: AllArticlesRowProps,
    ref
  ) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(initiallyOpen ?? false);
    const classes = useRowStyles();

    const getBackgroundColor = () => {
      return index % 2 === 1 ? "#fbfbfb" : "white";
    };

    return (
      <>
        <TableRow
          key={articlesByBrandKey}
          style={{ borderBottom: 1, backgroundColor: getBackgroundColor() }}
        >
          <TableCell colSpan={3} style={{ padding: 0, width: "100%" }}>
            <ListItem
              button
              onClick={() => setOpen(!open)}
              style={{ margin: 0, padding: 0 }}
            >
              <ListItemText>
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.texStyles}
                >
                  <IconButton
                    aria-label={t("expand-row")}
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? (
                      <KeyboardArrowUpIcon
                        fontSize="large"
                        color={open ? "primary" : "inherit"}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        fontSize="large"
                        color={open ? "primary" : "inherit"}
                      />
                    )}
                  </IconButton>
                  <div ref={ref as React.RefObject<HTMLDivElement>}>
                    {articlesByBrandKey}
                  </div>
                </Box>
              </ListItemText>
            </ListItem>
          </TableCell>
        </TableRow>
        {open &&
          articlesByBrandValue.map((article: any) => {
            return (
              <ArticleRow
                lastAddedArticlePosition={lastAddedArticlePosition}
                key={"arcticlesbybrand-" + article.articleId}
                marketingCampaign={null}
                article={article}
                clientele={clientele}
                importVersion={importVersion}
                addArticleToFlyerPage={addArticleToFlyerPage}
                showAddBtn
              />
            );
          })}
      </>
    );
  }
);

const useRowStyles = makeStyles((theme) => ({
  texStyles: {
    fontSize: "14px",
  },
}));

export default AllArticlesRow;
