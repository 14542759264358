import GenericSelect from "./GenericSelect";
import { useTranslation } from "react-i18next";
import { getYears } from "../../../api/detailedPlanningApiClient";
import { useEffect, useCallback, useState } from "react";
import { DropDownItem } from "../../../api/basedataApiClient";
interface YearsDropdownProps {
  name: string;
  control: any;
}

const YearsDropdown = ({ name, control }: YearsDropdownProps) => {
  const { t } = useTranslation();
  const [years, setYears] = useState<DropDownItem[]>();

  const fetchYears = useCallback(async () => {
    try {
      const years = await getYears();
      let yearsFormatted = years.map((item) => {
        return {
          id: +item,
          name: item.toString(),
        };
      });
      setYears(yearsFormatted);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    fetchYears();
  }, [fetchYears]);

  return (
    <>
      {years && (
        <GenericSelect
          name={name}
          label={t("year")}
          control={control}
          options={years || []}
          showEmptyOption={false}
          localStorage={true}
        />
      )}
    </>
  );
};

export default YearsDropdown;
