import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";

interface GenericTextFieldProps {
  name: string;
  label?: string;
  control: any;
  onBlur?: () => void;
  disabled?: boolean;
  readOnly?: boolean;
  textAlighEnd?: boolean;
  onKeyDown?: (event: any) => void;
  multiline?: boolean;
  required?: boolean;
}

const GenericTextField = ({
  name,
  label,
  control,
  onBlur,
  disabled,
  readOnly,
  textAlighEnd,
  onKeyDown,
  multiline,
  required = false,
}: GenericTextFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
      }}
      render={({ field: { onChange, value, ref } }) => {
        return (
          <TextField
            fullWidth
            multiline={multiline}
            size="small"
            label={label}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
            id="select"
            value={value || ""}
            onChange={onChange}
            onKeyDown={onKeyDown}
            inputRef={ref}
            onBlur={onBlur}
            inputProps={{
              readOnly,
              style: textAlighEnd ? { textAlign: "end" } : undefined,
            }}
          />
        );
      }}
    />
  );
};

export default GenericTextField;
