import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import theme from "./theme/theme";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import "./i18n";
import { MsalProvider } from "@azure/msal-react";
import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import "./app.css";

// MSAL configuration
const azureTenantId =
  process.env.REACT_APP_AZURE_TENANT_ID ||
  document.documentElement.dataset.azureTenantId ||
  "";
const azureClientId =
  process.env.REACT_APP_AZURE_CLIENT_ID ||
  document.documentElement.dataset.azureClientId ||
  "";
const msalConfig: Configuration = {
  auth: {
    clientId: azureClientId,
    authority: `https://login.microsoftonline.com/${azureTenantId}`,
    redirectUri: `${window.location.origin}/login/oauth2/code/`,
    postLogoutRedirectUri: window.location.origin,
  },
};
export const msalInstance =
  await PublicClientApplication.createPublicClientApplication(msalConfig);
msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MsalProvider instance={msalInstance}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
          <App />
      </SnackbarProvider>
    </ThemeProvider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
