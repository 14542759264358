import { Box, Table, TableContainer, Divider } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  Article,
  ArticleAssignment,
  MarketingCampaign,
} from "../../../api/detailedPlanningApiClient";
import InsertionRow from "./InsertionRow";
import { RenderCampaign } from "../../layout/Insertions";
import { useEffect, useState } from "react";

interface InsertionsTableProps {
  assigned: ArticleAssignment[];
  renderCampaigns: RenderCampaign[] | null;
  lastAddedArticlePosition: number | undefined;
  addArticleToFlyerPage: (params: {
    marketingCampaign?: MarketingCampaign | null;
    article?: Article;
    insertionPosition?: { positionId: number; positionIndex: number };
    unlistedArticleName?: string;
    addAsSubposition?: boolean;
  }) => void;
}

const InsertionsTable = ({
  renderCampaigns,
  addArticleToFlyerPage,
  lastAddedArticlePosition,
}: InsertionsTableProps) => {
  const { t } = useTranslation();
  const [campaigns, setCampaigns] = useState(renderCampaigns);
  const [campaignsSortedBy, setCampaignsSortedBy] = useState(
    "numberOfSalesMarkets"
  );

  const sortArticles = (sortBy: string) => {
    if (campaigns) {
      const sortedArticles = campaigns?.map((c) => {
        campaignsSortedBy === sortBy
          ? c.renderArticles?.sort((a: any, b: any) => a[sortBy] - b[sortBy])
          : c.renderArticles?.sort((a: any, b: any) => b[sortBy] - a[sortBy]);
        return c;
      });
      setCampaignsSortedBy(campaignsSortedBy === sortBy ? "" : sortBy);
      setCampaigns(sortedArticles);
    }
  };

  useEffect(() => {
    setCampaigns(renderCampaigns);
  }, [renderCampaigns]);

  return (
    <>
      <Divider />

      <TableContainer>
        <Table stickyHeader size="small" aria-label={t("collapsible-table")}>
          <TableHead>
            <TableRow>
              <TableCell>{t("article")}</TableCell>
              <TableCell>
                <Box display={"flex"} alignItems={"center"}>
                  {t("paragraph")}
                  <ArrowDownwardIcon
                    onClick={() => sortArticles("numberOfSalesMarkets")}
                    style={{
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                    fontSize="small"
                  />
                </Box>
              </TableCell>
              <TableCell sx={{ padding: "0px" }}>
                <Box display={"flex"} alignItems={"center"}>
                  {t("mandatory-range")}
                  <ArrowDownwardIcon
                    onClick={() => sortArticles("obligationRange")}
                    style={{
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                    fontSize="small"
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaigns?.map(
              (item, index) =>
                item.renderArticles?.length !== 0 && (
                  <InsertionRow
                    initiallyOpen={item.initiallyOpen}
                    key={"insertion-table-row-" + index}
                    index={index}
                    renderCampaign={item}
                    clientele={item.insertionFromAdplanningResult.clientele}
                    importVersion={item.insertionFromAdplanningResult.importVersion.toString()}
                    addArticleToFlyerPage={addArticleToFlyerPage}
                    lastAddedArticlePosition={lastAddedArticlePosition}
                  />
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default InsertionsTable;
