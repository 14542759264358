import { Grid, Paper, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import {
  Clientele,
  getPaperSizes,
  updatePrintingHouseClientele,
} from "../../../api/detailedPlanningApiClient";
import GenericTextField from "./GenericTextField";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { updatePrintingHouseCustomer } from "../../../api/detailedPlanningApiClient";
import { useSnackbar } from "notistack";
import GenericSelect from "../dropdowns/GenericSelect";
import { DropDownItem } from "../../../dto/Adplan";
interface PrintingInformationProps {
  fieldsReadOnly?: boolean;
  clienteleResult: Clientele;
  clientele: string | undefined;
  customerId?: string;
  importVersion: string;
  fetchDetails: () => void;
}

const Item = styled(Paper)(({ theme }: any) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface FormValues {
  printingHouseName: string;
  printCirculation: string;
  customerCirculation: string;
  totalCirculation: string;
  numberOfPoster: string;
  paperSize: string | null;
  implementationNote: string;
}

const defaultValues = {
  printingHouseName: "",
  printCirculation: "",
  customerCirculation: "",
  totalCirculation: "",
  numberOfPoster: "",
  paperSize: null,
  implementationNote: "",
};

const PrintingInformation = ({
  fieldsReadOnly,
  clienteleResult,
  customerId,
  clientele,
  importVersion,
  fetchDetails,
}: PrintingInformationProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line
  const { control, watch, setValue, getValues, formState, reset } =
    useForm<FormValues>({
      defaultValues,
    });

  const [paperSizes, setPaperSizes] = useState<DropDownItem[]>();
  const getId = (str: string) => {
    var id = str.replace(/[^0-9]/g, "");
    return +id;
  };

  const fetchPaperSizes = useCallback(async (mounted: boolean) => {
    try {
      const paperSizes = await getPaperSizes();
      if (mounted) {
        setPaperSizes(paperSizes);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const setPrintingHouseDetails = async () => {
    const isFormEdited = formState.isDirty;
    if (isFormEdited === false) return;

    if (customerId) {
      try {
        await updatePrintingHouseCustomer(
          {
            version: parseInt(importVersion),
            year: clienteleResult.year,
            weekOfYear: clienteleResult.weekOfYear,
            customerId: +customerId,
            clientele: clienteleResult.clientele,
          },
          "POST",
          {
            printingHouseName: getValues().printingHouseName ?? "",
            printCirculation: getId(getValues().printCirculation),
            customerCirculation: getId(getValues().customerCirculation),
            numberOfPoster: getId(getValues().numberOfPoster),
            paperSize: getValues().paperSize,
            implementationNote: getValues().implementationNote,
          }
        );
        fetchDetails();
        enqueueSnackbar(t("successfully-applied"), { variant: "success" });
      } catch (e) {
        enqueueSnackbar(t("error"), { variant: "error" });
      }
    } else if (clientele) {
      try {
        await updatePrintingHouseClientele(
          {
            version: parseInt(importVersion),
            year: clienteleResult.year,
            weekOfYear: clienteleResult.weekOfYear,
            clientele: clienteleResult.clientele,
          },
          "POST",
          {
            printingHouseName: getValues().printingHouseName ?? "",
            printCirculation: getId(getValues().printCirculation),
            customerCirculation: getId(getValues().customerCirculation),
            numberOfPoster: getId(getValues().numberOfPoster),
            paperSize: getValues().paperSize,
            implementationNote: getValues().implementationNote,
          }
        );
        fetchDetails();
        enqueueSnackbar(t("successfully-applied"), { variant: "success" });
      } catch (e) {
        enqueueSnackbar(t("error"), { variant: "error" });
      }
    }

    let totalCirculation =
      getId(getValues().printCirculation) +
      getId(getValues().customerCirculation);

    setValue("totalCirculation", `${totalCirculation} Stück`);
    reset(getValues());
    // formState.isDirty = false;
  };

  // useEffect(() => {
  //   const subscription = watch(() => {
  //     formState.isDirty = true;
  //   });
  //
  //   return () => subscription.unsubscribe();
  // }, [watch, formState]);

  useEffect(() => {
    setValue("printingHouseName", clienteleResult.printingHouseName);
    setValue(
      "printCirculation",
      (clienteleResult.printCirculation?.toString() || "0") + " Stück"
    );
    setValue(
      "customerCirculation",
      (clienteleResult.customerCirculation?.toString() || "0") + " Stück"
    );
    setValue(
      "totalCirculation",
      (clienteleResult.totalCirculation?.toString() || "0") + " Stück"
    );
    setValue(
      "numberOfPoster",
      (clienteleResult.numberOfPoster === null
        ? "0"
        : clienteleResult.numberOfPoster.toString()) + " Stück"
    );
    setValue("paperSize", clienteleResult.paperSize);
    setValue("implementationNote", clienteleResult.implementationNote);
  }, [clienteleResult, setValue]);

  useEffect(() => {
    let mounted = true;
    fetchPaperSizes(mounted);
    return () => {
      mounted = false;
    };
  }, [fetchPaperSizes]);

  return (
    <>
      <Box display="flex" flexDirection="column">
        <GenericTextField
          name="printingHouseName"
          label={t("printing-house")}
          control={control}
          onBlur={setPrintingHouseDetails}
          disabled={!fieldsReadOnly}
        />
      </Box>

      <Box height={"16px"} />

      <Grid container spacing={0} alignItems="center">
        <Grid item xs>
          <Item elevation={0} style={{ paddingLeft: "0px" }}>
            <GenericTextField
              name="printCirculation"
              label={t("distribution")}
              control={control}
              onBlur={setPrintingHouseDetails}
              disabled={fieldsReadOnly}
            />
          </Item>
        </Grid>
        <span style={{ fontSize: "16px" }}>+</span>
        <Grid item xs>
          <Item elevation={0}>
            <GenericTextField
              name="customerCirculation"
              label={t("homework")}
              control={control}
              onBlur={setPrintingHouseDetails}
              disabled={fieldsReadOnly}
            />
          </Item>
        </Grid>
        <span style={{ fontSize: "16px" }}>=</span>

        <Grid item xs>
          <Item elevation={0} style={{ paddingRight: "0px" }}>
            <GenericTextField
              name="totalCirculation"
              label={t("total-circulation")}
              control={control}
              onBlur={setPrintingHouseDetails}
              disabled={fieldsReadOnly}
            />
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={0} alignItems="center">
        <Grid item xs={4}>
          <Item elevation={0} style={{ paddingLeft: "0px" }}>
            <GenericTextField
              name="numberOfPoster"
              label={t("number-of-poster")}
              control={control}
              onBlur={setPrintingHouseDetails}
              disabled={fieldsReadOnly}
            />
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item elevation={0}>
            {paperSizes && (
              <GenericSelect
                name={"paperSize"}
                label={t("poster-dimensions")}
                control={control}
                options={paperSizes}
                disabled={fieldsReadOnly}
                onChange={setPrintingHouseDetails}
              />
            )}
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={0} alignItems="center">
        <Grid item xs={12}>
          <Item elevation={0} style={{ paddingLeft: "0px" }}>
            <GenericTextField
              name="implementationNote"
              multiline={true}
              label={t("implementation-note")}
              control={control}
              onBlur={setPrintingHouseDetails}
              disabled={fieldsReadOnly}
            />
          </Item>
        </Grid>
      </Grid>
    </>
  );
};

export default PrintingInformation;
