import { useTranslation } from "react-i18next";

import { useContext, useEffect } from "react";

import Customer from "../../../domain/ad-planning/views/campaign/customer/Customer";
import { AdPlanningContext } from "../../../domain/layout/AdPlanLayout";

const CustomerPage = () => {
  const { t } = useTranslation();

  const { selectedMarketingPartner } = useContext(AdPlanningContext);

  useEffect(() => {
    document.title = `${t("advertising-planning")} - Team Beverage`;
  }, [t]);
  if (selectedMarketingPartner) {
    return (
      <Customer
        marketingPartnerId={selectedMarketingPartner?.marketingPartnerId}
      />
    );
  } else {
    return <></>;
  }
};

export default CustomerPage;
