import { DropDownItem } from "../../../api/basedataApiClient";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { AutocompleteRenderOptionState } from "@material-ui/lab";
interface WeeksOfYearDropdownProps {
  name: string;
  control: any;
  options: DropDownItem[];
  label?: string;
  localStorage?: boolean;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    { selected }: AutocompleteRenderOptionState,
    onChange?: (data: any) => void
  ) => React.ReactNode;
  placeholder?: (value: any) => any;
}

const useStyles = makeStyles((theme) => ({
  input: {
    "& ::placeholder": {
      color: "black !important",
      opacity: "1 !important",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "7.5px!important",
      paddingBottom: "7px!important",
    },
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const WeeksOfYearDropdown = ({
  name,
  control,
  options,
  localStorage = false,
  label,
  renderOption = (props, option, { selected }, onChange) => (
    <li {...props} key={option.id}>
      <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
      {option.name}
    </li>
  ),
  placeholder = (value: any) => {
    return value ? value.map((elem: any) => elem?.name || elem).join(", ") : "";
  },
}: WeeksOfYearDropdownProps) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [actualValues, setActualValues] = useLocalStorage<string>(
    `tbg-select-${name}`,
    ""
  );

  function isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const getLocalStorage = () => {
    if (
      actualValues &&
      isJson(actualValues) &&
      typeof JSON.parse(actualValues) === typeof options
    )
      return JSON.parse(actualValues);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <>
          <Autocomplete
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            }
            value={getLocalStorage() || value || undefined}
            multiple
            size="small"
            options={options}
            open={open}
            disableCloseOnSelect
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            getOptionLabel={(option) => {
              return option.id + ": " + option.name;
            }}
            renderOption={(...renderOptionProps) =>
              renderOption(
                renderOptionProps[0],
                renderOptionProps[1],
                renderOptionProps[2],
                onChange
              )
            }
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                type="search"
                error={error ? true : false}
                {...params}
                placeholder={placeholder(value)}
                className={classes.input}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {!options && (
                        <CircularProgress color="inherit" size={20} />
                      )}

                      {params.InputProps.endAdornment}
                    </>
                  ),
                  sx: {
                    fontSize: 14,
                    minWidth: "100%",
                    paddingRight: "36px !important",
                  },
                }}
                label={label}
              />
            )}
            onChange={(e, data) => {
              if (localStorage) {
                setActualValues(JSON.stringify(data));
              }
              onChange(data);
            }}
          />
          {error && (
            <FormControl error>
              <FormHelperText>{error.message}</FormHelperText>
            </FormControl>
          )}
        </>
      )}
    />
  );
};

export default WeeksOfYearDropdown;
