import {
  Box,
  FormControl,
  Typography,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import { PropsWithChildren } from "react";
import { DeepMap, FieldError, get } from "react-hook-form";
import ErrorIcon from "@material-ui/icons/Error";

const useStyles = makeStyles({
  labelStyles: {
    textTransform: "capitalize",
  },
});

interface BaseFormFieldProps<T> {
  errors?: DeepMap<T, FieldError>;
  name: string;
  label?: string;
  width?: string;
}

const BaseFormField: <T>(
  p: PropsWithChildren<BaseFormFieldProps<T>>
) => React.ReactElement = (props) => {
  const classes = useStyles();
  const error = props.errors && props.name && get(props.errors, props.name);

  return (
    <FormControl
      id={props.name}
      style={{ width: props.width }}
      error={error ? true : false}
      variant="outlined"
      className={classes.labelStyles}
    >
      <InputLabel>{props.label}</InputLabel>
      {props.children}

      {error && (
        <Box
          display="flex"
          alignItems="center"
          style={{ marginTop: "5px", color: "red" }}
        >
          <ErrorIcon fontSize="small" />
          <Box style={{ marginLeft: "5px" }}>
            <Typography variant="caption">{error.message}</Typography>
          </Box>
        </Box>
      )}
    </FormControl>
  );
};

export default BaseFormField;
