import {
  Box,
  Table,
  TableBody,
  TableContainer,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import SearchInput from "../../layout/SearchInput";

import { useState, useCallback, useRef, useEffect } from "react";
import useAllArticlesSearch from "../../../hooks/useAllArticlesSearch";
import { useTranslation } from "react-i18next";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AllArticlesRow from "./AllArticlesRow";
import {
  Article,
  MarketingCampaign,
} from "../../../api/detailedPlanningApiClient";

interface AllArticlesTableProps {
  clientele: string;
  importVersion: string;
  lastAddedArticlePosition: number | undefined;
  addArticleToFlyerPage: (params: {
    marketingCampaign?: MarketingCampaign | null;
    article?: Article;
    insertionPosition?: { positionId: number; positionIndex: number };
    unlistedArticleName?: string;
    addAsSubposition?: boolean;
  }) => void;
}

const AllArticlesTable = ({
  clientele,
  importVersion,
  addArticleToFlyerPage,
  lastAddedArticlePosition,
}: AllArticlesTableProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [articlesSortedBy, setArticlesSortedBy] = useState(
    "numberOfSalesMarkets"
  );

  const { loading, articlesByBrandFormatted, hasMore } = useAllArticlesSearch(
    importVersion,
    clientele,
    query,
    pageNumber
  );
  const observer = useRef<any>();

  const [articlesByBrand, setArticlesByBrand] = useState(
    articlesByBrandFormatted
  );

  const lastArticleElementRef = useCallback(
    (node: Element) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleSearch = (searchedVal: string) => {
    setQuery(searchedVal);
    setPageNumber(0);
  };

  const sortArticlesByBrand = (sortBy: string) => {
    if (articlesByBrand) {
      const sortedArticles = articlesByBrand?.map((c) => {
        articlesSortedBy === sortBy
          ? c[1]?.sort((a: any, b: any) => a[sortBy] - b[sortBy])
          : c[1]?.sort((a: any, b: any) => b[sortBy] - a[sortBy]);
        return c;
      });
      setArticlesSortedBy(articlesSortedBy === sortBy ? "" : sortBy);
      setArticlesByBrand(sortedArticles);
    }
  };

  useEffect(() => {
    setArticlesByBrand(articlesByBrandFormatted);
  }, [articlesByBrandFormatted]);

  return (
    <>
      <Box display="flex" flexDirection="column" ml={2} mr={2} mb={2}>
        <SearchInput requestSearch={handleSearch} />
      </Box>

      <Divider />

      <TableContainer>
        {loading ? (
            <CircularProgress style={{
              display: "block",
              width: "40px",
              height: "40px",
              margin: "3rem auto",
            }} />
        ) : (
          <Table stickyHeader size="small" aria-label={t("collapsible-table")}>
            <TableHead>
              <TableRow>
                <TableCell width={"100%"}>{t("article")}</TableCell>
                <TableCell>
                  <Box display={"flex"} alignItems={"center"}>
                    {t("paragraph")}
                    <ArrowDownwardIcon
                      onClick={() => sortArticlesByBrand("numberOfSalesMarkets")}
                      style={{
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                      fontSize="small"
                    />
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Box display={"flex"} alignItems={"center"}>
                    {t("mandatory-range")}
                    <ArrowDownwardIcon
                      onClick={() => sortArticlesByBrand("obligationRange")}
                      style={{
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                      fontSize="small"
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {articlesByBrand &&
                articlesByBrand.map((item: any, index: any) => {
                  if (articlesByBrand.length === index + 1) {
                    return (
                      <AllArticlesRow
                        key={"all-article-" + index}
                        index={index}
                        clientele={clientele}
                        importVersion={importVersion}
                        ref={lastArticleElementRef}
                        articlesByBrandKey={item[0]}
                        articlesByBrandValue={item[1]}
                        lastAddedArticlePosition={lastAddedArticlePosition}
                        addArticleToFlyerPage={addArticleToFlyerPage}
                      />
                    );
                  } else {
                    return (
                      <AllArticlesRow
                        key={"all-article-" + index}
                        index={index}
                        clientele={clientele}
                        importVersion={importVersion}
                        articlesByBrandKey={item[0]}
                        articlesByBrandValue={item[1]}
                        lastAddedArticlePosition={lastAddedArticlePosition}
                        addArticleToFlyerPage={addArticleToFlyerPage}
                      />
                    );
                  }
                })}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <Box height={"16px"} />
    </>
  );
};

export default AllArticlesTable;
