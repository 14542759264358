import i18n from "../i18n";

import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";


import AdPlanningPage from "../pages/ad-planning/AdPlanningPage";
import DetailedPlanningPage from "../pages/detailed-planning/DetailedPlannigPage";


const Routes = [
  {
    path: ("/" + i18n.t("advertisingschedule")).toLowerCase(),
    icon: AssignmentIcon,
    component: AdPlanningPage,
  },
  {
    path: ("/" + i18n.t("detailed-planning")).toLowerCase(),
    icon: AssignmentTurnedInIcon,
    component: DetailedPlanningPage,
  },
  // {
  //   path: "/profile",
  //   icon: PersonIcon,
  //   component: ProfilePage,
  //   hideNavItem: true,
  // },
];

export default Routes;
