import { useTranslation } from "react-i18next";

import { useEffect } from "react";
import AdvertisingCommitment from "../../../domain/ad-planning/views/campaign/advertising-commitment/AdvertisingCommitment";

const AdvertisingCommitmentPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `${t("advertising-planning")} - Team Beverage`;
  }, [t]);

  return <AdvertisingCommitment />;
};

export default AdvertisingCommitmentPage;
