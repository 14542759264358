import { Box, makeStyles } from "@material-ui/core";

interface CustomAlertProps {
  severityType: string;
  message: string;
}

const CustomAlert = ({ severityType, message }: CustomAlertProps) => {
  const classes = useStyles();
  return (
    <Box
      className={
        severityType === "error" ? classes.errorAlert : classes.successAlert
      }
    >
      {message}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  errorAlert: {
    height: "2.8em",
    width: "32em",
    backgroundColor: "#ffebee",
    color: "red",

    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),

    fontSize: theme.spacing(2),

    textAlign: "center",
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    borderBottom: "1px solid red",
  },

  successAlert: {
    height: "2.8em",
    width: "32em",

    backgroundColor: "#e8f5e9",
    color: "green",

    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),

    fontSize: theme.spacing(2),

    textAlign: "center",
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    borderBottom: "1px solid green",
  },
}));

export default CustomAlert;
