import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useCallback } from "react";
import SalesDropdown from "../../../dropdowns/SalesDropdown";
import ConditionAreaDropdown from "../../../dropdowns/ConditionAreaDropdown";
import CreditorDropdown from "../../../dropdowns/CreditorDropdown";
import ObjectGroupDropdown from "../../../dropdowns/ObjectGroupDropdown";
import FederalStateDropdown from "../../../dropdowns/FederalStateDropdown";
import RangeRegionDropdown from "../../../dropdowns/RangeRegionDropdown";
import NielsenregionDropdown from "../../../dropdowns/NielsenregionDropdown";
import ZipCodesDropdown from "../../../dropdowns/ZipCodesDropdown";
import SalesDistrictDropdown from "../../../dropdowns/SalesDistrictDropdown";
import NationalInternationalDropdown from "../../../dropdowns/NationalInternationalDropdown";
import CampaignDetailsSubmitFooter from "../../../components/ad-plans/CampaignDetailsSubmitFooter";

import CustomAlert from "../../../../../components/CustomAlert";
import {
  getCustomerCount,
  DropDownItem,
  CustomerCountParams,
} from "../../../../../api/basedataApiClient";

const defaultValues = {
  customerData: {
    salesLines: [],
    conditionAreas: [],
    creditors: [],
    objectGroups: [],
    federalStates: [],
    rangeRegions: [],
    nielsenRegions: [],
    zipCodes: [],
    salesDistricts: [],
    nationalCode: "",
  },
};

const Customer = ({ marketingPartnerId }: { marketingPartnerId: number }) => {
  const { control, reset, watch, getValues } = useFormContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const [countSeverityDetails, setCountSeverityDetails] = useState<{
    type: string;
    message: string;
  } | null>(null);
  const getIds = useCallback((selected: DropDownItem[]): number[] => {
    let itemsTransformed = selected.map((item) => {
      return item.id;
    });

    return itemsTransformed;
  }, []);

  const getNames = useCallback((selected: any): string[] => {
    let itemsTransformed = selected.map((item: any) => {
      return item?.name || item;
    });

    return itemsTransformed;
  }, []);

  const apiCall = useCallback(async () => {
    if (marketingPartnerId) {
      try {
        const values = getValues();
        const params: CustomerCountParams = {
          marketingPartnerId: marketingPartnerId,
          salesLineId: getIds(values.customerData?.salesLines || []).toString(),
          conditionAreaId: getIds(
            values.customerData?.conditionAreas || []
          ).toString(),
          creditorId: getIds(values.customerData?.creditors || []).toString(),
          objectGroup: getIds(
            values.customerData?.objectGroups || []
          ).toString(),
          federalStateId: getIds(
            values.customerData?.federalStates || []
          ).toString(),
          rangeRegionId: getIds(
            values.customerData?.rangeRegions || []
          ).toString(),
          nielsenRegionId: getIds(
            values.customerData?.nielsenRegions || []
          ).toString(),
          zipCode: getNames(values.customerData?.zipCodes || []).toString(),
          salesDistrictId: getIds(
            values.customerData?.salesDistricts || []
          ).toString(),
        };
        const response = await getCustomerCount(params);

        if (response && response.count > 0) {
          setCountSeverityDetails({
            type: "success",
            message: t("customer-that-meet-these-criteria", {
              count: response.count,
            }),
          });
        } else {
          setCountSeverityDetails({
            type: "error",
            message: t("no-customer-meets-these-criteria"),
          });
        }
      } catch (e) {
        setCountSeverityDetails({
          type: "error",
          message: t("error"),
        });
      }
    }
  }, [getIds, getNames, getValues, t, marketingPartnerId]);

  useEffect(() => {
    const subscription = watch(() => {
      apiCall();
    });
    return () => subscription.unsubscribe();
  }, [watch, apiCall]);

  useEffect(() => {
    if (!countSeverityDetails) {
      apiCall();
    }
  }, [countSeverityDetails, apiCall]);

  return (
    <>
      <Box className={classes.container}>
        <Typography variant="h6" component="span" color="textSecondary">
          {t("sales")}
        </Typography>
        <Box className={classes.boxHeight}></Box>
        <Box className={classes.selectStyles}>
          <SalesDropdown
            control={control}
            registerName={"customerData.salesLines"}
          />
        </Box>
        <Box className={classes.boxPaddingBottom}></Box>
        <Typography variant="h6" component="span" color="textSecondary">
          {t("conditions")}
        </Typography>
        <Box className={classes.boxHeight}></Box>
        <Grid container spacing={1}>
          <Grid item className={classes.selectStyles}>
            <ConditionAreaDropdown
              control={control}
              registerName={"customerData.conditionAreas"}
            />
          </Grid>
          <Grid item className={classes.selectStyles}>
            <CreditorDropdown
              control={control}
              registerName={"customerData.creditors"}
            />
          </Grid>
          <Grid item className={classes.selectStyles}>
            {marketingPartnerId && (
              <ObjectGroupDropdown
                control={control}
                registerName={"customerData.objectGroups"}
                marketingPartnerId={marketingPartnerId}
              />
            )}
          </Grid>
        </Grid>
        <Box className={classes.boxPaddingBottom}></Box>
        <Typography variant="h6" component="span" color="textSecondary">
          {t("region")}
        </Typography>
        <Box className={classes.boxHeight}></Box>
        <Grid container direction={"row"} spacing={2}>
          <Grid item className={classes.selectStyles}>
            <FederalStateDropdown
              control={control}
              registerName={"customerData.federalStates"}
            />
          </Grid>
          <Grid item className={classes.selectStyles}>
            <RangeRegionDropdown
              control={control}
              registerName={"customerData.rangeRegions"}
            />
          </Grid>
          <Grid item className={classes.selectStyles}>
            <NielsenregionDropdown
              control={control}
              registerName={"customerData.nielsenRegions"}
            />
          </Grid>
        </Grid>
        <Box className={classes.boxPaddingBottom}></Box>
        <Typography variant="h6" component="h1" color="textSecondary">
          {t("area")}
        </Typography>
        <Box className={classes.boxHeight}></Box>
        <Grid container spacing={2}>
          <Grid item className={classes.selectStyles}>
            <ZipCodesDropdown
              control={control}
              registerName={"customerData.zipCodes"}
              // rules={{ ...GetRequiredRule(t("postal-code")) }}
            />
          </Grid>
          <Grid item className={classes.selectStyles}>
            <SalesDistrictDropdown
              control={control}
              registerName={"customerData.salesDistricts"}
            />
          </Grid>
          <Grid item className={classes.selectStyles}>
            <NationalInternationalDropdown
              control={control}
              registerName={"customerData.nationalCode"}
            />
          </Grid>
        </Grid>
        {countSeverityDetails && (
          <Box>
            <CustomAlert
              severityType={countSeverityDetails.type}
              message={countSeverityDetails.message}
            />
          </Box>
        )}
      </Box>

      <CampaignDetailsSubmitFooter
        textKey={"reset-to-default-customer"}
        resetHandler={() => reset({ ...getValues(), ...defaultValues })}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexFlow: "column",
    minHeight: "calc(100vh - 380px)",
  },

  boxHeight: {
    height: theme.spacing(2),
  },

  boxPaddingBottom: {
    paddingBottom: theme.spacing(4),
  },

  submitFooter: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },

  selectStyles: {
    width: theme.spacing(32),
  },
}));

export default Customer;
