import { useTranslation } from "react-i18next";
import {
  getArticle,
  toArticleCountParams,
} from "../../../api/basedataApiClient";
import Dropdown from "./Dropdown";
import { useFormContext } from "react-hook-form";

interface ArticleDropdownProps {
  registerName: string;
  control: any;
  rules?: any;
  marketingPartnerId: number;
}

const ArticleDropdown = ({
  registerName,
  control,
  rules,
  marketingPartnerId,
}: ArticleDropdownProps) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const values = getValues();

  const fetchParams2 = toArticleCountParams(
    marketingPartnerId,
    values.articleData
  );

  return (
    <Dropdown
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option) => {
        return option.name;
      }}
      fetchData={getArticle}
      control={control}
      label={t("article")}
      name={registerName}
      shouldServerSideSearch={true}
      rules={rules}
      fetchParams={{ marketingPartnerId: marketingPartnerId }}
      fetchParams2={fetchParams2}
    />
  );
};

export default ArticleDropdown;
