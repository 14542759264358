import { useTranslation } from "react-i18next";
import { getSalesline } from "../../../api/detailedPlanningApiClient";
import { useEffect, useCallback, useState } from "react";
import { DropDownItem } from "../../../api/basedataApiClient";
import GenericMultipleSelect from "./GenericMultipleSelect";

interface SaleslineDropdownProps {
  name: string;
  control: any;
}

const SaleslineDropdown = ({ name, control }: SaleslineDropdownProps) => {
  const { t } = useTranslation();
  const [salesline, setSalesline] = useState<DropDownItem[]>([]);

  const fetchSalesline = useCallback(async () => {
    try {
      const salesline = await getSalesline();
      let saleslineFormatted = salesline.results;
      setSalesline(saleslineFormatted);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    fetchSalesline();
  }, [fetchSalesline]);

  return (
    <GenericMultipleSelect
      name={name}
      label={t("sales-line")}
      control={control}
      options={salesline}
      localStorage={true}
    />
  );
};

export default SaleslineDropdown;
