import i18n from "../i18n";
import CustomerPage from "../pages/ad-planning/campaign/CustomerPage";
import RangePage from "../pages/ad-planning/campaign/RangePage";
import AdvertisingCommitmentPage from "../pages/ad-planning/campaign/AdvertisingCommitmentPage";
import FinancingPage from "../pages/ad-planning/campaign/FinancingPage";
import SchedulePage from "../pages/ad-planning/campaign/SchedulePage";

const MarketingPartnersRoutes = [
  {
    path:
      "/" +
      i18n.t("advertisingschedule").toLowerCase() +
      "/:scheduleId" +
      "/:marketingPartnersId" +
      "/:campaignId/" +
      i18n.t("customer"),
    element: <CustomerPage/>,
  },
  {
    path:
      "/" +
      i18n.t("advertisingschedule").toLowerCase() +
      "/:scheduleId" +
      "/:marketingPartnersId" +
      "/:campaignId/" +
      i18n.t("range"),
    element: <RangePage/>,
  },
  {
    path:
      "/" +
      i18n.t("advertisingschedule").toLowerCase() +
      "/:scheduleId" +
      "/:marketingPartnersId" +
      "/:campaignId/" +
      i18n.t("advertising-commitment"),
    element: <AdvertisingCommitmentPage/>,
  },
  {
    path:
      "/" +
      i18n.t("advertisingschedule").toLowerCase() +
      "/:scheduleId" +
      "/:marketingPartnersId" +
      "/:campaignId/" +
      i18n.t("financing"),
    element: <FinancingPage/>,
  },
  {
    path:
      "/" +
      i18n.t("advertisingschedule").toLowerCase() +
      "/:scheduleId" +
      "/:marketingPartnersId" +
      "/:campaignId/" +
      i18n.t("advertising-planning"),
    element: <SchedulePage/>,
  },
];

export default MarketingPartnersRoutes;
