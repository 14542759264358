import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import PrintingInformation from "./PrintingInformation";
import DistributeInformation from "./DistributeInformation";
import { getClientele } from "../../../api/detailedPlanningApiClient";
import { useState, useCallback, useEffect } from "react";
import { Clientele } from "../../../api/detailedPlanningApiClient";
import {
  getCustomer,
  getDistributionCustomer,
} from "../../../api/detailedPlanningApiClient";
import { DistributionCustomer } from "../../../api/detailedPlanningApiClient";

interface PrintingTabsProps {
  distributeInformationDisabled?: boolean;
  year: string;
  weekOfYear: string;
  clientele?: string;
  customerId?: string;
  importVersion: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`printing-tabpanel-${index}`}
      aria-labelledby={`printing-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2, pb: 2 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `printing-tab-${index}`,
    "aria-controls": `printing-tabpanel-${index}`,
  };
}

const PrintingTabs = ({
  year,
  weekOfYear,
  clientele,
  customerId,
  distributeInformationDisabled,
  importVersion,
}: PrintingTabsProps) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const [clienteleResult, setClienteleResult] = useState<Clientele>();
  const [distributionCustomer, setDistributionCustomer] =
    useState<DistributionCustomer>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchDetails = useCallback(async () => {
    if (importVersion) {
      if (customerId) {
        try {
          const customerResult = await getCustomer({
            version: parseInt(importVersion),
            year: +year,
            weekOfYear: +weekOfYear,
            customerId: +customerId,
            clientele: clientele,
          });
          setClienteleResult(customerResult);
        } catch (e) {
          console.log(e);
        }
        try {
          const distributionCustomer = await getDistributionCustomer({
            version: parseInt(importVersion),
            year: +year,
            weekOfYear: +weekOfYear,
            clientele: clientele,
            customerId: +customerId,
          });

          setDistributionCustomer(distributionCustomer);
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          const clienteleResult = await getClientele({
            version: parseInt(importVersion),
            year: +year,
            weekOfYear: +weekOfYear,
            clientele: clientele,
          });

          setClienteleResult(clienteleResult);
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [clientele, customerId, weekOfYear, year, importVersion]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  return (
    <Box sx={{ width: "100%"}}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="printing tabs">
          <Tab label={t("printing-information")} {...a11yProps(0)} />
          <Tab
            label={t("distribute-information")}
            {...a11yProps(1)}
            disabled={distributeInformationDisabled}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {clienteleResult && (
          <PrintingInformation
            importVersion={importVersion}
            fieldsReadOnly={distributeInformationDisabled}
            clienteleResult={clienteleResult}
            clientele={clientele}
            customerId={customerId}
            fetchDetails={fetchDetails}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {distributionCustomer && customerId && (
          <DistributeInformation
            importVersion={importVersion}
            distributionCustomer={distributionCustomer}
            customerId={customerId}
            fetchDetails={fetchDetails}
          />
        )}
      </TabPanel>
    </Box>
  );
};

export default PrintingTabs;
