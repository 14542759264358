import {
  Box,
  Grid,
  Chip,
  FormGroup,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import GenericSwitch from "../../../../ad-planning/dropdowns/GenericSwitch";
import GenericTextField from "../../../../ad-planning/dropdowns/GenericTextField";
import CampaignDetailsSubmitFooter from "../../../components/ad-plans/CampaignDetailsSubmitFooter";

const defaultValues = {
  advertisingCommitmentData: {
    leaflet: false,
    newspaper: false,
    poster: false,
    internet: false,
    campaign: false,
    coverPage: false,
    hero: false,
    tailormade: false,
    onlineCampaign: false,
    offerDiscount: false,
    customQuality: false,
    customQualityDescription: "",
    initiations: 0,
    duration: "",
    advertisingCommitmentComment: "",
    customQuality2: false,
    customQualityDescription2: "",
    customQuality3: false,
    customQualityDescription3: "",
    customQuality4: false,
    customQualityDescription4: "",
    customQuality5: false,
    customQualityDescription5: "",
    customQuality6: false,
    customQualityDescription6: "",
  },
};

const AdvertisingCommitment = () => {
  const { control, reset, getValues } = useFormContext();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Box className={classes.container}>
        <Typography variant="h6" component="span" color="textSecondary">
          {t("advertising-medium")}
        </Typography>
        <Box className={classes.boxHeight}></Box>

        <FormGroup row>
          <Box className={classes.switchContainerStyles}>
            <GenericSwitch
              name="advertisingCommitmentData.leaflet"
              label={t("handout")}
              control={control}
            />
          </Box>

          <Box className={classes.switchContainerStyles}>
            <GenericSwitch
              name="advertisingCommitmentData.newspaper"
              label={t("newspaper")}
              control={control}
            />
          </Box>

          <Box className={classes.switchContainerStyles}>
            <GenericSwitch
              name="advertisingCommitmentData.poster"
              label={t("poster")}
              control={control}
            />
          </Box>

          <Box className={classes.switchContainerStyles}>
            <GenericSwitch
              name="advertisingCommitmentData.internet"
              label={t("internet")}
              control={control}
            />
          </Box>

          <GenericSwitch
            name="advertisingCommitmentData.campaign"
            label={t("campaign")}
            control={control}
          />
        </FormGroup>
        <Box className={classes.boxPaddingBottom}></Box>

        <Typography variant="h6" component="span" color="textSecondary">
          {t("quality")}
        </Typography>
        <Box className={classes.boxHeight}></Box>

        <FormGroup row>
          <Box
            display="flex"
            alignItems="center"
            className={classes.switchContainerStyles}
          >
            <GenericSwitch
              name="advertisingCommitmentData.coverPage"
              label={t("front-page")}
              control={control}
            />

            <Chip
              size="small"
              label="TS"
              color="primary"
              className={classes.chipsStyles}
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            className={classes.switchContainerStyles}
          >
            <GenericSwitch
              name="advertisingCommitmentData.hero"
              label={t("hero")}
              control={control}
            />

            <Chip
              size="small"
              label="H"
              color="primary"
              className={classes.chipsStyles}
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            className={classes.switchContainerStyles}
          >
            <GenericSwitch
              name="advertisingCommitmentData.tailorMade"
              label={t("tailormade")}
              control={control}
            />

            <Chip
              size="small"
              label="TM"
              color="primary"
              className={classes.chipsStyles}
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            className={classes.switchContainerStyles}
          >
            <GenericSwitch
              name="advertisingCommitmentData.onlineCampaign"
              label={t("onlinecampaign")}
              control={control}
            />

            <Chip
              size="small"
              label="OMA"
              color="primary"
              className={classes.chipsStyles}
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            className={classes.switchContainerStyles}
          >
            <GenericSwitch
              name="advertisingCommitmentData.offerDiscount"
              label={t("offerdiscount")}
              control={control}
            />

            <Chip
              size="small"
              label="AR"
              color="primary"
              className={classes.chipsStyles}
            />
          </Box>
        </FormGroup>
        <Box className={classes.boxPaddingBottom}></Box>

        <Typography variant="h6" component="span" color="textSecondary">
          {t("own-quality")}
        </Typography>
        <Box className={classes.boxHeight}></Box>

        <FormGroup row>
          <Box
            display="flex"
            alignItems="center"
            className={classes.switchContainerStyles}
          >
            <GenericSwitch
              name="advertisingCommitmentData.customQuality"
              control={control}
            />

            <GenericTextField
              disabled={!getValues("advertisingCommitmentData.customQuality")}
              name="advertisingCommitmentData.customQualityDescription"
              label={t("own-name")}
              control={control}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className={classes.switchContainerStyles}
          >
            <GenericSwitch
              name="advertisingCommitmentData.customQuality2"
              control={control}
            />

            <GenericTextField
              disabled={!getValues("advertisingCommitmentData.customQuality2")}
              name="advertisingCommitmentData.customQualityDescription2"
              label={t("own-name2")}
              control={control}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className={classes.switchContainerStyles}
          >
            <GenericSwitch
              name="advertisingCommitmentData.customQuality3"
              control={control}
            />

            <GenericTextField
              disabled={!getValues("advertisingCommitmentData.customQuality3")}
              name="advertisingCommitmentData.customQualityDescription3"
              label={t("own-name3")}
              control={control}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className={classes.switchContainerStyles}
          >
            <GenericSwitch
              name="advertisingCommitmentData.customQuality4"
              control={control}
            />

            <GenericTextField
              disabled={!getValues("advertisingCommitmentData.customQuality4")}
              name="advertisingCommitmentData.customQualityDescription4"
              label={t("own-name4")}
              control={control}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className={classes.switchContainerStyles}
          >
            <GenericSwitch
              name="advertisingCommitmentData.customQuality5"
              control={control}
            />

            <GenericTextField
              disabled={!getValues("advertisingCommitmentData.customQuality5")}
              name="advertisingCommitmentData.customQualityDescription5"
              label={t("own-name5")}
              control={control}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className={classes.switchContainerStyles}
          >
            <GenericSwitch
              name="advertisingCommitmentData.customQuality6"
              control={control}
            />

            <GenericTextField
              disabled={!getValues("advertisingCommitmentData.customQuality6")}
              name="advertisingCommitmentData.customQualityDescription6"
              label={t("own-name6")}
              control={control}
            />
          </Box>
        </FormGroup>

        <Box className={classes.boxPaddingBottom}></Box>

        <Grid container spacing={2}>
          <Grid item className={classes.selectStyles}>
            <Typography variant="h6" component="span" color="textSecondary">
              {t("impetus")}
            </Typography>
            <Box className={classes.boxHeight}></Box>

            <GenericTextField
              name="advertisingCommitmentData.initiations"
              control={control}
              inputType="number"
              // validateInitiations={true}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6" component="span" color="textSecondary">
              {t("running-time")}
            </Typography>
            <Box className={classes.boxHeight}></Box>

            <GenericTextField
              name="advertisingCommitmentData.duration"
              control={control}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.commentStyles}>
        <GenericTextField
          name="advertisingCommitmentData.advertisingCommitmentComment"
          label={t("comment")}
          rowsNumber={5}
          control={control}
          isFullWidth={true}
        />
      </Box>
      <CampaignDetailsSubmitFooter
        textKey={"reset-to-default-advertising-commitment"}
        resetHandler={() => reset({ ...getValues(), ...defaultValues })}
        // submitHandler={handleSubmit(onSubmit)}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexFlow: "column",
    minHeight: "calc(100vh - 545px)",
  },

  boxHeight: {
    height: theme.spacing(2),
  },

  boxPaddingBottom: {
    paddingBottom: theme.spacing(4),
  },

  submitFooter: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },

  selectStyles: {
    width: theme.spacing(27),
  },

  switchContainerStyles: {
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },

  chipsStyles: {
    fontSize: "12px",
    width: theme.spacing(5),
  },

  commentStyles: {
    marginTop: theme.spacing(4),
  },
}));

export default AdvertisingCommitment;
