import { getBrand, toArticleCountParams } from "../../../api/basedataApiClient";
import Dropdown from "./Dropdown";
import { useFormContext } from "react-hook-form";

interface BrandDropdownProps {
  registerName: string;
  control: any;
  rules?: any;
  marketingPartnerId: number;
}

const BrandDropdown = ({
  registerName,
  control,
  rules,
  marketingPartnerId,
}: BrandDropdownProps) => {
  const { getValues } = useFormContext();
  const values = getValues();

  const fetchParams2 = toArticleCountParams(
    marketingPartnerId,
    values.articleData
  );

  return (
    <Dropdown
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option) => {
        return option.name;
      }}
      fetchData={getBrand}
      control={control}
      name={registerName}
      shouldServerSideSearch={true}
      rules={rules}
      fetchParams={{ marketingPartnerId: marketingPartnerId }}
      fetchParams2={fetchParams2}
    />
  );
};

export default BrandDropdown;
