import { Box, Divider, Typography, makeStyles, Theme } from "@material-ui/core";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { AdPlanningContext } from "./AdPlanLayout";

const navlinkStyle = {
  color: "#424242",
  fontSize: "14px",

  padding: "10px 20px 10px 20px",
  textDecoration: "none",
};

interface NavTabsProps {
  scheduleId: number | undefined;
  marketingPartnersId: number | undefined;
  campaignId: number | undefined;
}

const NavTabs = ({
  scheduleId,
  marketingPartnersId,
  campaignId,
}: NavTabsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { selectedCampaign, selectedMarketingPartner } =
    useContext(AdPlanningContext);

  return (
    <>
      <Typography component="span" variant="h5">
        {selectedCampaign?.name}
      </Typography>
      <Typography
        component="span"
        variant="subtitle1"
        color="textSecondary"
        className={classes.subtitleStyles}
      >
         <> </>{selectedMarketingPartner?.name}
      </Typography>

      <Typography
        component="span"
        variant="caption"
        className={`${classes.center} ${classes.or}`}
      >
        {t("info-according-to-annual-meeting")}
      </Typography>

      <Box>
        {[
          "Kunde",
          "Sortiment",
          "Werbeverpflichtung",
          "Finanzierung",
          "Werbeplanung",
        ].map((route, index) => (
          <NavLink
            style={({ isActive }) => ({
              ...navlinkStyle,
              color: isActive ? "#0d47a1" : "inherit",
              borderBottom: isActive ? "2px solid" : "inherit",
            })}
            to={
              `/werbeplan/${scheduleId}/${marketingPartnersId}/${campaignId}/` +
              route.toLowerCase()
            }
            key={index}
          >
            {route}
          </NavLink>
        ))}
      </Box>
      <Divider className={classes.dividerStyle} />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  subtitleStyles: {
    marginBottom: theme.spacing(2),
  },

  center: {
    display: "flex",
    justifyContent: "center",
    width: theme.spacing(58),
    color: "#757575",
  },

  or: {
    position: "relative",
    marginBottom: "10px",
    "&:before, &:after": {
      content: "''",
      display: "inline-block",
      height: "1px",
      background: "#00000044",
      position: "absolute",
      top: "50%",
    },
    "&:before": {
      width: "32%",
      transform: "translate(-105%, -50%)",
    },
    "&:after": {
      width: "32%",
      transform: "translate(105%, -50%)",
    },
  },

  dividerStyle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default NavTabs;
