import {
  Box,
  TextField,
  IconButton,
  makeStyles,
  Divider,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

import { MarketingCampaign } from "../../dto/Adplan";

import RemoveSharpIcon from "@material-ui/icons/RemoveSharp";
import EditIcon from "@material-ui/icons/Edit";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CopyAllIcon from "@mui/icons-material/CopyAll";

interface CampaignTableRowsProps {
  campaign: MarketingCampaign;
  parentPath: string;
  isEditing: boolean;
  canEdit: boolean;
  stopEditing: () => void;
  startEditing: () => void;

  deleteCampaignHandler: () => void;

  nameChangeHandler: (campaignId: number | null, name: string) => void;
  copyCampaignHandler: (campaignId: number | null, name: string) => void;

  isNameAvailable: boolean;
  setIsNameAvailable: (isNameAvailable: boolean) => void;
}

const useRowStyles = makeStyles((theme) => ({
  invisible: {
    visibility: "hidden",
  },

  removeSharpIconStyles: {
    color: "#b71c1c",
  },

  editIconStyles: {
    color: "#0d47a1",
  },

  wrapControles: {
    paddingLeft: theme.spacing(1),
  },

  nameTypographyStyles: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },

  ellipsis: {
    textOverflow: "ellipsis",
    width: "200px",
    maxWidth: "400px",
  },

  textFieldStyles: {
    paddingTop: theme.spacing(1),
  },

  checkCircleIconStyles: {
    color: "green",
  },

  errorIconStyles: {
    color: "red",
  },

  formHelperTextStyle: {
    color: "#e53935",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
  },
}));

const navlinkStyle: React.CSSProperties = {
  color: "black",
  paddingLeft: "30px",
  textDecoration: "none",
  width: "100%",
  userSelect: "text",
};

const CampaignTableRows = ({
  campaign,
  parentPath,
  isEditing,
  canEdit,
  stopEditing,
  startEditing,

  deleteCampaignHandler,
  copyCampaignHandler,
  nameChangeHandler,
  isNameAvailable,
  setIsNameAvailable,
}: CampaignTableRowsProps) => {
  const { t } = useTranslation();
  const classes = useRowStyles();
// eslint-disable-next-line
  const location = useLocation();
  const [isHover, setIsHover] = useState(false);
  const [name, setName] = useState(campaign.name);

  let campaignNameNotEmpty = name.length > 0 ? true : false;

  let campaignNameLengthExceeds = name.length < 128 ? false : true;

  const hide = () => (isHover && !isEditing ? "" : classes.invisible);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        setName(campaign.name);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [campaign.name]);

  const wrapper = () => {
    return (
      <Box style={navlinkStyle}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box flex={1} className={classes.ellipsis}>
            {isEditing ? (
              <>
                <TextField
                  error={
                    (campaignNameNotEmpty ? false : true) ||
                    (campaignNameLengthExceeds ? true : false)
                  }
                  value={name}
                  fullWidth={true}
                  disabled={!isEditing}
                  inputRef={(input) => input && input.focus()}
                  onChange={(e) => {
                    setName(e.target.value);
                    setIsNameAvailable(true);
                  }}
                  className={classes.textFieldStyles}
                />
                <FormHelperText className={classes.formHelperTextStyle}>
                  {campaignNameNotEmpty ? "" : t("it-is-a-required-field")}
                  {isNameAvailable
                    ? ""
                    : t("campaign-name-already-taken-error")}
                  {campaignNameLengthExceeds ? t("max-length-exceeded") : ""}
                </FormHelperText>
              </>
            ) : (
              <Typography
                component="span"
                noWrap
                className={classes.nameTypographyStyles}
              >
                {campaign.name || name}
              </Typography>
            )}
          </Box>
          {isEditing && (
            <IconButton
              aria-label={t("edit")}
              onClick={() => nameChangeHandler(campaign.id, name)}
              hidden={true}
              disabled={
                (campaignNameNotEmpty ? false : true) ||
                (campaignNameLengthExceeds ? true : false)
              }
            >
              {campaignNameNotEmpty && !campaignNameLengthExceeds ? (
                <CheckCircleIcon className={classes.checkCircleIconStyles} />
              ) : (
                <ErrorIcon className={classes.errorIconStyles} />
              )}
            </IconButton>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {campaign.id === null ? (
          <Box style={navlinkStyle}>{wrapper()}</Box>
        ) : (
          <NavLink
            draggable={false}
            style={({ isActive }) => ({
              ...navlinkStyle,
              color: isActive ? "#0d47a1" : "inherit",
              backgroundColor: isActive ? "#e3f2fd" : "inherit",
            })}
            to={parentPath + "/" + t("customer").toLowerCase()}
          >
            {wrapper()}
          </NavLink>
        )}

        {canEdit && (
          <Box
            display="flex"
            alignItems="center"
            className={classes.wrapControles}
          >
            <IconButton
              aria-label={t("edit")}
              onClick={startEditing}
              className={hide()}
            >
              <EditIcon className={classes.editIconStyles} />
            </IconButton>
            <IconButton
              aria-label={t("copy")}
              onClick={() => copyCampaignHandler(campaign.id, name)}
              className={hide()}
            >
              <CopyAllIcon className={classes.editIconStyles} />
            </IconButton>

            <IconButton
              aria-label={t("delete")}
              onClick={deleteCampaignHandler}
              className={hide()}
            >
              <RemoveSharpIcon className={classes.removeSharpIconStyles} />
            </IconButton>
          </Box>
        )}
      </Box>
      {<Divider />}
    </Box>
  );
};

export default CampaignTableRows;
