import { useSnackbar } from "notistack";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import {
  getAnnualMeeting,
  updateAnnualMeeting,
} from "../../../api/adplanApiClient";
import NotFound from "../../../components/NotFound";
import Schedule from "../../../domain/ad-planning/views/campaign/schedule/Schedule";
import { AnnualMeeting } from "../../../dto/Adplan";

const SchedulePage = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams<{
    campaignId: string;
  }>();

  const [annualMeeting, setAnnualMeeting] = useState<
    AnnualMeeting | null | "loading"
  >("loading");
  const campaignId = params.campaignId;

  const fetchAnnualMeeting = useCallback(async () => {
    if (campaignId) {
      try {
        setAnnualMeeting("loading");
        let response = await getAnnualMeeting(+campaignId);

        if (response.advertisingCommitmentData) {
          setAnnualMeeting(response);
        } else {
          response = await updateAnnualMeeting(+campaignId, "POST", {});
          if (response.advertisingCommitmentData) {
            setAnnualMeeting(response);
          }
        }
      } catch (e) {
        enqueueSnackbar(t("error"), {
          variant: "error",
        });
        setAnnualMeeting(null);
      }
    }
  }, [campaignId, enqueueSnackbar, t]);

  useEffect(() => {
    fetchAnnualMeeting();
  }, [fetchAnnualMeeting]);

  useEffect(() => {
    document.title = `${t("advertising-planning")} - Team Beverage`;
  }, [t]);
  if (annualMeeting === "loading") {
    return <></>;
  } else if (annualMeeting && campaignId) {
    return <Schedule campaignId={+campaignId} annualMeeting={annualMeeting} />;
  } else {
    return <NotFound />;
  }
};

export default SchedulePage;
