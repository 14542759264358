import { Box, Typography, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";

import { useState, useEffect, useContext } from "react";

import { useTranslation } from "react-i18next";

import { MarketingPartner } from "../../dto/Adplan";
import { addMarketingPartner } from "../../api/adplanApiClient";

import SearchInput from "./SearchInput";
import MarketingPartnerTable from "./MarketingPartnerTable";
import { AdPlanningContext } from "./AdPlanLayout";
import MarketingPartnerDropdown from "../ad-planning/dropdowns/MarketingPartnerDropdown";
import StatusDropdown from "./StatusDropdown";

interface MarketingPartnersSideNavProps {
  scheduleId: number | undefined;
  selectedPartnerId?: number | undefined;
}

const useStyles = makeStyles((theme) => ({
  typographyTextTransform: {
    textTransform: "capitalize",
  },

  topBoxHeight: {
    height: theme.spacing(4),
  },

  bottomBoxHeight: {
    height: theme.spacing(1),
  },
}));

const MarketingPartnersSideNav = ({
  scheduleId,
  selectedPartnerId,
}: MarketingPartnersSideNavProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const locationLastPath = location.pathname.split("/").pop();
  const isPartnersDropdownShown = locationLastPath === "werbeplanung";

  const { schedule, fetchScheduleDetails } = useContext(AdPlanningContext);

  const [searchString, setSearchString] = useState<string | undefined>(
    undefined
  );
  const [stateFilter, setStateFilter] = useState<string>("");
  const [filteredMarketingPartners, setFilteredMarketingPartners] = useState<
    MarketingPartner[] | null
  >(null);

  useEffect(() => {
    if (schedule) {
      let filtered = searchString
        ? schedule.marketingPartners.filter((row) => {
            return (
              row.name.toLowerCase().includes(searchString.toLowerCase()) ||
              row.marketingCampaigns.find((campaignRow) =>
                campaignRow.name
                  .toLowerCase()
                  .includes(searchString.toLowerCase())
              )
            );
          })
        : schedule.marketingPartners;
      filtered = stateFilter
        ? filtered.filter((row) => {
            return row.state === stateFilter;
          })
        : filtered;
      setFilteredMarketingPartners(filtered);
    }
  }, [schedule, searchString, stateFilter]);

  const handleAddMarketingPartner = async (
    marketingPartnerId: number | null
  ) => {
    if (marketingPartnerId && scheduleId) {
      try {
        await addMarketingPartner(scheduleId, marketingPartnerId, "POST");
        enqueueSnackbar(t("successfully-applied"), { variant: "success" });
        fetchScheduleDetails();
      } catch (e) {
        enqueueSnackbar(t("error-msg-marketing-partner-already-available"), {
          variant: "error",
        });
      }
    }
  };

  return (
    <>
      <Typography
        component="span"
        variant="h5"
        className={classes.typographyTextTransform}
      >
        {schedule && schedule.name}
      </Typography>
      <Box display="flex" flexDirection="column">
        <Box className={classes.topBoxHeight}></Box>
        {!isPartnersDropdownShown && (
          <MarketingPartnerDropdown
            addMarketingPartner={handleAddMarketingPartner}
            label={t("marketing-partner")}
          />
        )}
        <Box className={classes.bottomBoxHeight}></Box>
        <Box display="flex" alignItems="center">
          <Box sx={{ width: "60%" }}>
            <SearchInput requestSearch={setSearchString} />
          </Box>
          <Box sx={{ width: "40%" }}>
            <StatusDropdown
              state={stateFilter}
              width={"100%"}
              changeStateHandler={(state: string) => {
                setStateFilter(state);
              }}
              enableInCopy={true}
            />
          </Box>
        </Box>

        <MarketingPartnerTable
          scheduleId={scheduleId ? +scheduleId : undefined}
          marketingPartnersList={
            filteredMarketingPartners || schedule?.marketingPartners || []
          }
          selectedPartnerId={selectedPartnerId ? +selectedPartnerId : undefined}
          changeMarketingPartnerHandler={(partner) => {
            fetchScheduleDetails(); // TODO - review: prevent reload and patch data in context?
          }}
        />
      </Box>
    </>
  );
};

export default MarketingPartnersSideNav;
