import { Box, Typography, Button, makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ContentCard from "../../../components/ContentCard";
import AdvertisingScheduleTable from "../components/ad-plans/AdvertisingScheduleTable";
import AdvertisingScheduleModal from "../components/ad-plans/AdvertisingScheduleModal";
import { getSchedules } from "../../../api/adplanApiClient";
import { useSnackbar } from "notistack";
import { AdvertisingSchedule } from "../../../dto/Adplan";

const useStyles = makeStyles((theme) => ({
  contentCard: {
    padding: theme.spacing(0),
  },
}));

const AdPlanning = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [schedules, setSchedules] = useState<AdvertisingSchedule[]>([]);

  const fetchSchedules = useCallback(async () => {
    try {
      const response = await getSchedules();
      setSchedules(response);
    } catch (e) {
      enqueueSnackbar(t("error"), {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    fetchSchedules();
  }, [fetchSchedules]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    fetchSchedules();
    setOpen(false);
  };

  return (
    <ContentCard className={classes.contentCard}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Typography>{t("all-advertising-planning")}</Typography>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          {t("new-advertising-plan")}
        </Button>
      </Box>

      <Box>
        <AdvertisingScheduleTable schedules={schedules} />
      </Box>
      <AdvertisingScheduleModal open={open} handleClose={handleClose} />
    </ContentCard>
  );
};

export default AdPlanning;
