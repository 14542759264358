import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: theme.spacing(10),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    height: "100vh",
  },
}));

interface ContentContainerProps {
  children: React.ReactNode;
}

const ContentContainer = ({ children }: ContentContainerProps) => {
  const classes = useStyles();

  return <Box className={classes.content}>{children}</Box>;
};

export default ContentContainer;
