import { useState } from "react";

function useLocalStorage<T>(key: string, initialValue: T) {
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === "undefined") {
            return initialValue;
        }
        try {
            const item = window.localStorage.getItem(key);
            if (typeof key !== "undefined") { 
                return item ? item : initialValue;
            } else {
                return item ? JSON.parse(item) : initialValue;
            }
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });
    const setValue = (value: T | ((val: T) => T)) => {
        try {
            const valueToStore =
            value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            if (typeof window !== "undefined") {
                if (typeof valueToStore === "string") {
                    window.localStorage.setItem(key, valueToStore);
                } else {
                    window.localStorage.setItem(key, JSON.stringify(valueToStore));
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    return [storedValue, setValue] as const;
}

export default useLocalStorage;