import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import GenericTextField from "../../../../ad-planning/dropdowns/GenericTextField";
import CampaignDetailsSubmitFooter from "../../../components/ad-plans/CampaignDetailsSubmitFooter";
import Dropdown from "../../../dropdowns/Dropdown";
import { getAdplannigSalesLine } from "../../../../../api/basedataApiClient";
import { useParams } from "react-router";
import { AnnualMeetingFinancingCommentPosition } from "../../../../../dto/Adplan";
import { IconButton } from "@mui/material";
import RemoveSharpIcon from "@material-ui/icons/RemoveSharp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import AddIcon from "@material-ui/icons/Add";

const defaultValues = {
  annualMeetingFinancingCommentPosition: [
    { id: null, salesLines: [], financingComment: null },
  ],
};

const Financing = () => {
  const { control, reset, getValues, setValue } = useFormContext();

  const { t } = useTranslation();
  const classes = useStyles();
  const watchPositions: AnnualMeetingFinancingCommentPosition[] = useWatch({
    name: "annualMeetingFinancingCommentPosition",
    control: control,
  });
  console.log("watchPositions",watchPositions)
  const params = useParams<{
    campaignId: string;
  }>();
  const campaignId = params.campaignId;
  const values = getValues();

  const [
    deleteAnnualMeetingCommentPositionRow,
    setDeleteAnnualMeetingCommentPositionRow,
  ] = useState<number | null>(null);
  const handleDeleteAdplanningRowCancel = () => {
    setDeleteAnnualMeetingCommentPositionRow(null);
  };

  const handleDeleteAdplanningRow = () => {
    if (deleteAnnualMeetingCommentPositionRow === null) return;
    values.annualMeetingFinancingCommentPosition.splice(
      deleteAnnualMeetingCommentPositionRow,
      1
    );
    if (values.annualMeetingFinancingCommentPosition.length === 0) {
      values.annualMeetingFinancingCommentPosition[0] = {
        id: null,
        salesLines: [],
        financingComment: null,
      };
    }
    reset({ ...values }, { keepDirty: true });
    setDeleteAnnualMeetingCommentPositionRow(null);
  };

  const addEmptyFinancing = () => {
    const values = getValues();
    let lastRow = [...getValues().annualMeetingFinancingCommentPosition];
    lastRow.pop();
    if (!lastRow) return;
    let newValues = [...values.annualMeetingFinancingCommentPosition];
    newValues.push({
      id: null,
      salesLines: [],
      financingComment: null,
    });
    setValue("annualMeetingFinancingCommentPosition", newValues);
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Typography
            variant="subtitle2"
            gutterBottom
            component="div"
            color="textSecondary"
          >
            {t("Vertriebslinie")}
          </Typography>
        </Grid>
        <Grid item xs={2} lg>
          <Typography
            variant="subtitle2"
            gutterBottom
            component="div"
            color="textSecondary"
          >
            {t("financing-remark")}
          </Typography>
        </Grid>
      </Grid>
      {values?.annualMeetingFinancingCommentPosition?.length > 0 && (
        <>
          {values?.annualMeetingFinancingCommentPosition?.map(
            (
              position: AnnualMeetingFinancingCommentPosition,
              index: number
            ) => {
              return (
                <div key={index}>
                  <section className={"section"} key={index}>
                    <Grid container spacing={1}>
                      <Grid item xs={2}>
                        <Dropdown
                          control={control}
                          name={`annualMeetingFinancingCommentPosition.${index}.salesLines`}
                          noOptionText={t("no-option")}
                          fetchData={getAdplannigSalesLine}
                          fetchParams={{
                            campaignId: campaignId?.toString(),
                          }}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <GenericTextField
                          name={`annualMeetingFinancingCommentPosition.${index}.financingComment`}
                          rowsNumber={4}
                          control={control}
                          isFullWidth={true}
                        />
                      </Grid>
                      <Grid style={{ marginTop: "0.6em" }} item xs={2} lg>
                        <IconButton
                          size="small"
                          aria-label={t("delete")}
                          onClick={() => {
                            setDeleteAnnualMeetingCommentPositionRow(index);
                          }}
                          style={{ color: "#b71c1c" }}
                          className={classes.removeSharpIconStyles}
                        >
                          <RemoveSharpIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => {
                            const values = getValues();
                            const row = watchPositions[index];

                            values.annualMeetingFinancingCommentPosition = [
                              ...values.annualMeetingFinancingCommentPosition,
                              row,
                            ];
                            reset({ ...values }, { keepDirty: true });
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </section>
                </div>
              );
            }
          )}
          <Box mt={3}>
            <Button
              size="small"
              color="primary"
              onClick={addEmptyFinancing}
              startIcon={<AddIcon></AddIcon>}
            >
              {t("add-financing-comment")}
            </Button>
          </Box>
        </>
      )}

      <CampaignDetailsSubmitFooter
        textKey={"reset-to-default-financing"}
        resetHandler={() => {
          reset({ ...getValues(), ...defaultValues }, { keepDirty: true });
        }}
      />
      <ConfirmDialog
        title={t("delete-action")}
        open={deleteAnnualMeetingCommentPositionRow !== null}
        message={t("delete-addplanning-row")}
        handleConfirm={handleDeleteAdplanningRow}
        handleReject={handleDeleteAdplanningRowCancel}
        ariaLabel="delete-action-dialog-title"
        ariaDescription="delete-action-dialog-description"
        confirmBtnText={t("delete")}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexFlow: "column",
    minHeight: "calc(100vh - 380px)",
  },
  removeSharpIconStyles: {
    color: "#b71c1c",
    marginLeft: theme.spacing(2),
  },
}));

export default Financing;
