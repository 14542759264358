import { Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core";
import { format } from "date-fns";
import InputPeriodDateContainer from "./InputPeriodDateContainer";

interface InputPeriodDateProps {
  name: string;
  control: any;
    openDate: boolean;
    index: number;
    toggleDate: (index:number)=>void;
}

const useStyles = makeStyles((theme) => ({
  periodDate: {
    position: "relative",
  },
  dateText: {
    cursor: "pointer",
  },
}));

const InputPeriodDate = ({
  name,
  control,
                             openDate, index, toggleDate
}: InputPeriodDateProps) => {

  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        // @ts-ignore
        return (
          <>
            <div className={classes.periodDate}

            >
              <span
                onClick={() => toggleDate(index)}
                className={classes.dateText}
              >
                {`${format(value.startDate, "dd.MM.yyyy")} - ${format(
                  value.endDate,
                  "dd.MM.yyyy"
                )}`}
              </span>

              {openDate && (
                  <InputPeriodDateContainer
                  value={value}
                  onChange={onChange}
                  name={name}
                  toggleDate={toggleDate}
                  index={index}
                  />
              )}
            </div>
          </>
        );
      }}
    />
  );
};

export default InputPeriodDate;
