import { useTranslation } from "react-i18next";
import { getRangeRegion } from "../../../api/basedataApiClient";
import Dropdown from "./Dropdown";

interface RangeRegionDropdownProps {
  registerName: string;
  control: any;
  rules?: any;
}

const RangeRegionDropdown = ({
  registerName,
  control,
  rules,
}: RangeRegionDropdownProps) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option) => {
        return option.name;
      }}
      fetchData={getRangeRegion}
      control={control}
      label={t("region")}
      name={registerName}
      shouldServerSideSearch={false}
      rules={rules}
    />
  );
};

export default RangeRegionDropdown;
