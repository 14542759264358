import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { useTranslation } from "react-i18next";
import { getCreditor } from "../../../api/basedataApiClient";
import Dropdown from "./Dropdown";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface CreditorDropdownProps {
  registerName: string;
  control: any;
  rules?: any;
}

const CreditorDropdown = ({
  registerName,
  control,
  rules,
}: CreditorDropdownProps) => {
  const { t } = useTranslation();
  return (
    <Dropdown
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option) => {
        return option.externalId + ": " + option.name;
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
          {option.externalId + ": " + option.name}
        </li>
      )}
      fetchData={getCreditor}
      control={control}
      label={t("creditor")}
      name={registerName}
      shouldServerSideSearch={true}
      rules={rules}
    />
  );
};

export default CreditorDropdown;
