// eslint-disable-next-line
import i18n, { Callback } from "i18next";
import { initReactI18next } from "react-i18next";
import de from "./locales/de/de.json";

const resources = {
  de: {
    translation: de,
  },
};

// @ts-ignore
i18n.use(initReactI18next).init({
  lng: "de",
  resources,
  returnNull: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    // format: (value, format, lng) => {
    //   legacy usage
    //   if (value instanceof Date) {
    //     return DateTime.fromJSDate(value)
    //       .setLocale(lng)
    //       .toLocaleString(DateTime[format]);
    //   }
    //   return value;
    // },
  },
});

export default i18n;
