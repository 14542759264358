import { useTranslation } from "react-i18next";
import { DropDownItem } from "../../../api/basedataApiClient";
import GenericMultipleSelect from "./GenericMultipleSelect";
interface WeeksOfYearDropdownProps {
  name: string;
  control: any;
  options: DropDownItem[];
}

const WeeksOfYearDropdown = ({
  name,
  control,
  options,
}: WeeksOfYearDropdownProps) => {
  const { t } = useTranslation();
  return (
    <GenericMultipleSelect
      name={name}
      label={t("advertising-week")}
      control={control}
      options={options}
      localStorage={true}
    />
  );
};

export default WeeksOfYearDropdown;
