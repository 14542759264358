import { Box, Grid, makeStyles } from "@material-ui/core";
import ContentCard from "../../components/ContentCard";
import DetailedPlanningSideNav from "./DetailedPlanningSideNav";
import { Outlet } from "react-router-dom";

const DetailedPlanningLayout = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={8} className={classes.gridItemStyles}>
          <Box className={classes.fixedLaylout}>
            <ContentCard>
              <DetailedPlanningSideNav />
            </ContentCard>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  gridItemStyles: {
    paddingRight: theme.spacing(2),
  },

  fixedLaylout: {
    position: "fixed",
    width: "61%",
  },
}));

export default DetailedPlanningLayout;
