import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormGroup,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  Clienteles,
  getClienteles,
  copyDetailedPlanning,
  DetailedPlanningDetail,
} from "../../../api/detailedPlanningApiClient";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import GenericCheckbox from "./GenericCheckbox";
import { useFormContext } from "react-hook-form";
import { FormValues } from "../../layout/DetailedPlanningDetailsLayout";

interface CopyDetailedPlanningFormProps {
  sourceTemplateName: string;
  sourceTemplatePages: string[];
  setOpenImportModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "white",
  borderRadius: "5px",
  boxShadow: 24,
  p: 0,
};

export interface LocationProp {
  year: string;
  weekOfYear: string;
  importVersion: string;
  clientele: string;
}

const CopyDetailedPlanningForm = ({
  sourceTemplateName,
  sourceTemplatePages,
  setOpenImportModal,
}: CopyDetailedPlanningFormProps) => {
  const [clientelesToImport, setClientelesToImport] = useState<Clienteles[]>();
  const [openClientele, setOpenClientele] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [copyResult, setCopyResult] = useState<DetailedPlanningDetail>();
  const [openInUseModal, setOpenInUseModal] = useState(false);

  const { t } = useTranslation();

  let location = useLocation();

  const { weekOfYear, year, clientele, importVersion } =
    location.state as LocationProp;

  const { control, getValues, watch, reset } = useFormContext<FormValues>();
  const watchedPages = watch(`pages`) || [];

  // const pagesAllreadyInUse = watchedPages.map((p, i) => {
  //   if (
  //     p.positions?.flatMap((a) => a?.articleAssignments).filter(Boolean)
  //       .length > 0
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // });

  const submit = async () => {
    const values = getValues();

    const selectedPages = Object.keys(values.pagesToCopy).filter(
      (k) => values.pagesToCopy[k as any]
    );
    // const selectedPagesInUse = selectedPages.filter(
    //   (p) => pagesAllreadyInUse[getPageIndex(p)]
    // );

    if (
      clientelesToImport &&
      openClientele !== undefined &&
      selectedPages.length
    ) {
      const result = await copyDetailedPlanning({
        version: parseInt(importVersion),
        year: parseInt(year),
        weekOfYear: parseInt(weekOfYear),
        fromClientele: clientelesToImport[openClientele].clientele,
        toClientele: clientele,
        pages: selectedPages,
      });
      // FIX THIS
      // if (selectedPagesInUse.length) {
      setCopyResult(result);
      setOpenInUseModal(true);
      // } else {
      //   copyPages(result);
      // }
    }
  };

  const getPageIndex = (page: string) => {
    let pageIndex!: number;
    sourceTemplatePages.forEach((x, templateIndex) => {
      if (x === page) {
        pageIndex = templateIndex;
      }
    });
    return pageIndex;
  };

  const copyPages = (result: DetailedPlanningDetail) => {
    let updatedPages = watchedPages.map((p, i) => {
      const newPage = result.pages.filter((rp) => getPageIndex(rp.name) === i);
      if (newPage.length) {
        const newPagePositions = watchedPages[i].positions.map((wp, wi) => {
          const newPosition = newPage[0].positions?.filter(
            (np) => np.articleAssignments[0].position === wi
          );
          if (newPosition?.length) {
            return newPosition[0];
          } else {
            return wp;
          }
        });
        return { positions: newPagePositions };
      } else {
        return p;
      }
    });

    reset({ pages: updatedPages, pagesToCopy: getValues().pagesToCopy });
    setOpenImportModal(false);
  };

  const getClientelesCallback = useCallback(async () => {
    setLoading(true);
    const clienteles = await getClienteles({
      year: parseInt(year),
      weekOfYear: [parseInt(weekOfYear)],
      sourceTemplateName: sourceTemplateName,
    });
    setClientelesToImport([...clienteles.clienteles]);
    setLoading(false);
  }, [sourceTemplateName, year, weekOfYear]);

  useEffect(() => {
    getClientelesCallback();
  }, [getClientelesCallback]);

  return (
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        style={{ padding: "1em" }}
      >
        {t("import-from-template")}
      </Typography>
      <Box
        style={{
          height: "70vh",
          overflowY: "scroll",
          border: "1px solid lightgrey",
        }}
      >
        <TableBody>
          {clientelesToImport &&
            clientelesToImport.map((clientele, cIndex) => (
              <>
                <TableRow
                  key={"copy" + clientele + "-" + cIndex}
                  onClick={() => {
                    openClientele === cIndex
                      ? setOpenClientele(undefined)
                      : setOpenClientele(cIndex);
                  }}
                  style={{ cursor: "pointer", borderBottom: "1px solid" }}
                >
                  <TableCell style={{ border: 0 }}>
                    <IconButton aria-label="expand row" size="small">
                      {openClientele === cIndex ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell style={{ border: 0 }} component="th" scope="row">
                    {clientele.clientele}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse
                      in={openClientele === cIndex}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ margin: 1 }}>
                        <Table size="small" aria-label="purchases">
                          <TableBody>
                            <FormGroup style={{ margin: "0.3em" }}>
                              {openClientele === cIndex &&
                                sourceTemplatePages.map((page) => (
                                  <GenericCheckbox
                                    control={control}
                                    name={"pagesToCopy." + page}
                                    label={page}
                                  />
                                ))}
                            </FormGroup>
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
        </TableBody>
        {loading ? (
          <CircularProgress
            color="inherit"
            size={20}
            style={{ margin: "auto", display: "block", marginTop: "35vh" }}
          />
        ) : null}
      </Box>

      <Modal open={openInUseModal} onClose={() => setOpenInUseModal(false)}>
        <Box
          sx={style}
          style={{ display: "flex", flexDirection: "column", width: "30vw" }}
        >
          <Typography
            id="modal-modal-title"
            variant="subtitle2"
            component="span"
            style={{ padding: "1em" }}
          >
            {t("copy-detailed-planning-dialog")}
          </Typography>
          <Box style={{ display: "flex" }}>
            <Button
              onClick={() => setOpenInUseModal(false)}
              variant="contained"
              color="primary"
              disabled={openClientele === undefined ? true : false}
              style={{
                margin: "1rem",
              }}
            >
              {t("abort")}
            </Button>
            <Button
              onClick={() => copyResult && copyPages(copyResult)}
              variant="contained"
              color="primary"
              disabled={openClientele === undefined ? true : false}
              style={{
                margin: "1rem 1rem 1rem auto",
              }}
            >
              {t("import")}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Button
        onClick={() => submit()}
        variant="contained"
        color="primary"
        disabled={openClientele === undefined ? true : false}
        style={{
          margin: "1rem 1rem 1rem auto",
          display: "block",
        }}
      >
        {t("import")}
      </Button>
    </Box>
  );
};

export default CopyDetailedPlanningForm;
