import { useTranslation } from "react-i18next";
import { getObjectGroup } from "../../../api/basedataApiClient";
import Dropdown from "./Dropdown";

interface ObjectGroupDropdownProps {
  registerName: string;
  control: any;
  rules?: any;
  marketingPartnerId: number;
}

const ObjectGroupDropdown = ({
  registerName,
  control,
  rules,
  marketingPartnerId,
}: ObjectGroupDropdownProps) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option) => {
        return option.name;
      }}
      fetchData={getObjectGroup}
      control={control}
      label={t("object-group")}
      name={registerName}
      shouldServerSideSearch={false}
      rules={rules}
      fetchParams={{ marketingPartnerId: marketingPartnerId }}
    />
  );
};

export default ObjectGroupDropdown;
