import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: "100%",
    objectFit: "contain",
  },
}));

const Logo = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box
      bgcolor="white"
      height={64}
      paddingLeft={2}
      paddingRight={12}
      paddingTop={1}
      paddingBottom={1}
    >
      <img
        src="/team-bevarage-logo.png"
        alt={t("logo")}
        className={classes.logo}
      />
    </Box>
  );
};

export default Logo;
