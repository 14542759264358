import { useTranslation } from "react-i18next";
import { getConditionArea } from "../../../api/basedataApiClient";
import Dropdown from "./Dropdown";

interface ConditionAreaDropdownProps {
  registerName: string;
  control: any;
  rules?: any;
}

const ConditionAreaDropdown = ({
  registerName,
  control,
  rules,
}: ConditionAreaDropdownProps) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option) => {
        return option.name;
      }}
      fetchData={getConditionArea}
      control={control}
      label={t("condition-area")}
      name={registerName}
      shouldServerSideSearch={false}
      rules={rules}
    />
  );
};

export default ConditionAreaDropdown;
