import { Chip, Select, MenuItem, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import BaseFormField from "../../components/BaseFormField";

import { updateSchedulePartnerState } from "../../api/adplanApiClient";

import { SCHEDULE_STATUS } from "../../dto/Adplan";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  selectStyles: {
    width: theme.spacing(10),
    height: theme.spacing(4),
    textAlign: "left",
  },

  chipStyles: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

interface StatusDropdownProps {
  schedulePartnerId?: number;
  state: string;
  isDisabled?: boolean;
  changeStateHandler: (state: string) => void;
  width?: string;
  enableInCopy?: boolean;
}

const STATUS = [
  {
    name: SCHEDULE_STATUS.IN_COPY,
    backgroundColor: "#fafafa",
  },
  {
    name: SCHEDULE_STATUS.FINISHED,
    backgroundColor: "green",
  },
  {
    name: SCHEDULE_STATUS.IN_PROGRESS,
    backgroundColor: "gold",
  },
  {
    name: SCHEDULE_STATUS.ERROR,
    backgroundColor: "red",
  },
];

const StatusDropdown = ({
  schedulePartnerId,
  state,
  isDisabled = false,
  changeStateHandler,
  width,
  enableInCopy = false,
}: StatusDropdownProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const statusOptions = enableInCopy
    ? STATUS
    : STATUS.filter((status) => status.name !== "IN_COPY");

  const changeState = async (index: number) => {
    try {
      if (schedulePartnerId) {
        const response = await updateSchedulePartnerState(
          schedulePartnerId,
          "PUT",
          {
            state: statusOptions[index].name,
          }
        );
        changeStateHandler(response?.state);
        enqueueSnackbar(t("successfully-applied"), { variant: "success" });
      } else {
        changeStateHandler(index === -1 ? "" : statusOptions[index].name);
      }
    } catch (e) {
      enqueueSnackbar(t("error"), {
        variant: "error",
      });
    }
  };
  if (isDisabled)
    return (
      <Chip
        size="small"
        label={``}
        style={{
          backgroundColor: statusOptions.find((s) => s.name === state)
            ?.backgroundColor,
        }}
        className={classes.chipStyles}
      />
    );

  return (
    <>
      <BaseFormField width={width} name={t("status")} label={t("status")}>
        <Select
          value={state}
          label={t("status")}
          className={schedulePartnerId ? classes.selectStyles : ""}
        >
          {!schedulePartnerId && (
            <MenuItem
              key={"all-status"}
              value={""}
              selected={true}
              onClick={() => changeState(-1)}
            >
              <Chip
                size="small"
                label={``}
                style={{
                  backgroundColor: "unset",
                  marginRight: "5px",
                }}
                className={classes.chipStyles}
              />
              {t("all-status-option")}
            </MenuItem>
          )}
          {statusOptions.map((status, index) => (
            <MenuItem
              key={index}
              value={status.name}
              selected={index === 1}
              onClick={() => changeState(index)}
            >
              <Chip
                size="small"
                label={``}
                style={{
                  backgroundColor: status.backgroundColor,
                  marginRight: "5px",
                }}
                className={classes.chipStyles}
              />
              {t(
                status.name.toLowerCase().replace(/_/g, "-") +
                  "-status-option-vmp"
              )}
            </MenuItem>
          ))}
        </Select>
      </BaseFormField>
    </>
  );
};

export default StatusDropdown;
