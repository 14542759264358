import {
  Box,
  ListItem,
  ListItemText,
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useTranslation } from "react-i18next";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import {
  Article,
  MarketingCampaign,
  getAdvertisingCommitmentComment,
} from "../../../api/detailedPlanningApiClient";
import ArticleRow from "./ArticleRow";
import { useState } from "react";
import { RenderCampaign } from "../../layout/Insertions";

interface InsertionRowProps {
  initiallyOpen?: boolean;
  clientele: string;
  index: number;
  renderCampaign: RenderCampaign;
  importVersion: string;
  lastAddedArticlePosition: number | undefined;
  addArticleToFlyerPage: (params: {
    marketingCampaign?: MarketingCampaign | null;
    article?: Article;
    insertionPosition?: { positionId: number; positionIndex: number };
    unlistedArticleName?: string;
    addAsSubposition?: boolean;
  }) => void;
}

function InsertionRow({
  initiallyOpen,
  clientele,
  renderCampaign,
  index,
  importVersion,
  lastAddedArticlePosition,
  addArticleToFlyerPage,
}: InsertionRowProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(initiallyOpen ?? false);

  let timeout: ReturnType<typeof setTimeout>;

  const [tooltipContent, setTooltipContent] = useState<string>("");
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [addBtnOnHover, setAddBtnOnHover] = useState(false);

  const getTooltipAdvertisingCommitmentComment = async () => {
    const result = await getAdvertisingCommitmentComment(
      renderCampaign.marketingCampaign.marketingCampaignId
    );

    if (result.value) {
      setTooltipContent(result.value);
      setTooltipIsOpen(true);
    }
  };

  return (
    <>
      <TableRow
        key={"campaign-" + index}
        onMouseEnter={() => setAddBtnOnHover(true)}
        onMouseLeave={() => setAddBtnOnHover(false)}
      >
        <TableCell
          style={{
            padding: 0,
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
          }}
          colSpan={3}
        >
          <ListItem
            button
            onClick={() => setOpen(!open)}
            style={{ margin: 0, padding: 0 }}
          >
            <ListItemText style={{ margin: 0 }}>
              <Box
                display="flex"
                alignItems="center"
                style={{
                  fontSize: "14px",
                  transition: "all 1s ease",
                  opacity: "1",
                  maxHeight: "200px",
                  padding: "4px 0",
                  overflow: "hidden",
                }}
              >
                <IconButton
                  aria-label={t("expand-row")}
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? (
                    <KeyboardArrowUpIcon
                      fontSize="large"
                      color={open ? "primary" : "inherit"}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      fontSize="large"
                      color={open ? "primary" : "inherit"}
                    />
                  )}
                </IconButton>
                {`${renderCampaign.marketingCampaign?.name}`}
                {addBtnOnHover && (
                  <Tooltip
                    arrow
                    onMouseEnter={() => {
                      timeout = setTimeout(
                        () => getTooltipAdvertisingCommitmentComment(),
                        300
                      );
                    }}
                    onMouseLeave={() => {
                      clearTimeout(timeout);
                      setTooltipIsOpen(false);
                    }}
                    open={tooltipIsOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <>
                        {tooltipContent
                          .split("\n")
                          .map((line: string, index: number) => (
                            <div key={index}>{line}</div>
                          ))}
                      </>
                    }
                  >
                    <InfoOutlinedIcon
                      color="primary"
                      style={{ fontSize: "15px", margin: "0 5px 0 auto" }}
                    />
                  </Tooltip>
                )}
                {renderCampaign.articlesAssigned && (
                  <CheckIcon
                    color="success"
                    style={{ margin: "0 5px 0 auto" }}
                  />
                )}
              </Box>
            </ListItemText>
          </ListItem>
        </TableCell>
      </TableRow>
      {open &&
        renderCampaign.renderArticles?.map((article, index) => {
          return (
            <ArticleRow
              lastAddedArticlePosition={lastAddedArticlePosition}
              key={"articlerow-" + index}
              marketingCampaign={renderCampaign.marketingCampaign}
              article={article}
              position={{
                positionId: renderCampaign.positionId,
                positionIndex: renderCampaign.positionIndex,
              }}
              clientele={clientele}
              importVersion={importVersion}
              isMandatory
              addArticleToFlyerPage={addArticleToFlyerPage}
              showAddBtn={true}
            />
          );
        })}
    </>
  );
}

export default InsertionRow;
