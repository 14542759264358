import { useTranslation } from "react-i18next";
import { getNielsenregion } from "../../../api/basedataApiClient";
import Dropdown from "./Dropdown";

interface NielsenregionDropdownProps {
  registerName: string;
  control: any;
  rules?: any;
}

const NielsenregionDropdown = ({
  registerName,
  control,
  rules,
}: NielsenregionDropdownProps) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option) => {
        return option.name;
      }}
      fetchData={getNielsenregion}
      control={control}
      label={t("nielsenarea")}
      name={registerName}
      shouldServerSideSearch={false}
      rules={rules}
    />
  );
};

export default NielsenregionDropdown;
