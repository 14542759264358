import {Routes, Route, Navigate, createBrowserRouter, RouterProvider} from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "./domain/layout/Layout";
import AdPlanLayout from "./domain/layout/AdPlanLayout";
import DetailedPlanningLayout from "./domain/layout/DetailedPlanningLayout";

import routes from "./router/routes";
import marketingPartnersRoutes from "./router/marketing-partners-routes";
import detailedPlannigRoutes from "./router/detailed-planning-routes";
import NotFound from "./components/NotFound";
import detailedPlanningDetailsRoutes from "./router/detailed-planning-details-routes";
import DetailedPlanningDetailsLayout from "./domain/layout/DetailedPlanningDetailsLayout";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { useParams } from "react-router";

const App = () => {
  const { t } = useTranslation();

  const routeComponents = routes.map(
    ({ path, component: Component }, index) => <Component key={path + index} />
  );

  const detailedPlanningDetailsComponents = detailedPlanningDetailsRoutes.map(
    ({ path, component: Component }, index) => (
      <Routes key={"routes" + path + index}>
        <Route
          path={path}
          element={<Component key={path + index} />}
          key={"route" + path + index}
        />
      </Routes>
    )
  );

  const { marketingPartnersId } = useParams();

    const router = createBrowserRouter([
        {
            path:"/",
            element: <Navigate
                to={
                    "/" + (t("advertisingschedule" as any) as string).toLowerCase()
                }
            />
        },
        {
            element: <Layout>{routeComponents}</Layout>,
            path: "/" + (t("advertisingschedule" as any) as string).toLowerCase()
        },
        {
            element: <Layout>{routeComponents}</Layout>,
            path: "/profile"
        },
        {
            element: <Layout>
                <AdPlanLayout
                    selectedPartnerId={
                        marketingPartnersId
                            ? parseInt(marketingPartnersId)
                            : undefined
                    }
                ></AdPlanLayout>
            </Layout>,
            path: `/${(
                t("advertisingschedule" as any) as string
            ).toLowerCase()}/:scheduleId`,
            children: marketingPartnersRoutes,
        },
        {
            element: <Layout>
                <DetailedPlanningLayout></DetailedPlanningLayout>
            </Layout>,
            path: `/${(t("detailed-planning" as any) as string).toLowerCase()}`,
            children: detailedPlannigRoutes,
        },
        {
            element: <Layout>
                <DndProvider backend={HTML5Backend}>
                    <DetailedPlanningDetailsLayout>
                        {detailedPlanningDetailsComponents}
                    </DetailedPlanningDetailsLayout>
                </DndProvider>
            </Layout>,
            path: "/" +
                (t("detailed-planning" as any) as string).toLowerCase() +
                "/details",
        },
        {
            element: <Layout>
                <NotFound />
            </Layout>,
            path: "*",
        },

    ]);

    return (
    <>
        <RouterProvider router={router} />
    </>
  );
};

export default App;
