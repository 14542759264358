import { Box, Button, Divider, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  dividerStyles: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  footerStyles: {
    marginBottom: theme.spacing(2),
  },
}));

const CampaignDetailsSubmitFooter = ({
  textKey,
  resetHandler,
  submitHandler,
}: {
  textKey?: string;
  resetHandler?: () => void;
  submitHandler?: () => void;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Divider className={classes.dividerStyles} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.footerStyles}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            if (resetHandler) {
              resetHandler();
            }
          }}
        >
          {t(textKey ? textKey : "reset-to-default")}
        </Button>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => (submitHandler ? submitHandler() : {})}
        >
          {t("save-annualmeeting")}
        </Button>
      </Box>
    </>
  );
};

export default CampaignDetailsSubmitFooter;
