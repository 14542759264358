import { Switch, FormControlLabel } from "@material-ui/core";
import { Controller } from "react-hook-form";

const GenericSwitch = ({
  name,
  label,
  control,
  disabled,
}: {
  name: string;
  label?: string;
  control: any;
  disabled?: boolean;
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, onBlur, value, name, ref } }) => {
      return (
        <FormControlLabel
          control={
            <Switch
              name={name}
              color="primary"
              checked={value || false}
              onChange={onChange}
              disabled={disabled}
            />
          }
          label={label}
        />
      );
    }}
  />
);

export default GenericSwitch;
