import i18n from "../i18n";

import DetailedPlanningDetailsPage from "../pages/detailed-planning/DetailedPlanningDetailsPage";

const DetailedPlanningDetailsRoutes = [
  {
    path: "/" + i18n.t("detailed-planning").toLowerCase() + "/details",
    component: DetailedPlanningDetailsPage,
  },
];

export default DetailedPlanningDetailsRoutes;
