import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";

import Range from "../../../domain/ad-planning/views/campaign/range/Range";
import { AdPlanningContext } from "../../../domain/layout/AdPlanLayout";

const RangePage = () => {
  const { t } = useTranslation();
  const { selectedMarketingPartner } = useContext(AdPlanningContext);

  useEffect(() => {
    document.title = `${t("advertising-planning")} - Team Beverage`;
  }, [t]);

  if (selectedMarketingPartner) {
    return (
      <Range marketingPartnerId={selectedMarketingPartner.marketingPartnerId} />
    );
  } else {
    return <></>;
  }
};

export default RangePage;
