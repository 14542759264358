import {
  Box,
  Collapse,
  TableCell,
  TableRow,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import StatusDropdown from "./StatusDropdown";

import { useNavigate, useLocation } from "react-router";

import CampaignTable from "./CampaignTable";

import { MarketingCampaign, MarketingPartner } from "../../dto/Adplan";
import RemoveSharpIcon from "@material-ui/icons/RemoveSharp";
import ConfirmDialog from "../../components/ConfirmDialog";
import { removeMarketingPartner } from "../../api/adplanApiClient";
import { useSnackbar } from "notistack";
import { AdPlanningContext } from "./AdPlanLayout";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },

  collapsibleTableCellStyles: {
    paddingBottom: 0,
    paddingTop: 0,
  },

  collapseOpenStyles: {
    color: "#0d47a1",
  },

  dropdownStyles: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
  },

  invisible: {
    visibility: "hidden",
  },

  removeSharpIconStyles: {
    color: "#b71c1c",
  },
}));

interface TableRowProps {
  partner: MarketingPartner;
  scheduleId: number | undefined;
  initiallyOpen?: boolean;
  changeMarketingPartnerHandler: (partner: MarketingPartner) => void;
}

function MarketingPartnerTableRows({
  partner,
  scheduleId,
  initiallyOpen,
  changeMarketingPartnerHandler,
}: TableRowProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(initiallyOpen ?? false);
  const classes = useRowStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const locationLastPath = location.pathname.split("/").pop();
  const { fetchScheduleDetails, schedule } = useContext(AdPlanningContext);
  const isPartnersDropdownShown = locationLastPath === "werbeplanung";
  const [deleteMarketingPartnerOpen, setDeleteMarketingPartnerOpen] = useState<
    number | null
  >(null);

  const [campaignList, setCampaignList] = useState<MarketingCampaign[]>(
    partner.marketingCampaigns
  );
  const [isHover, setIsHover] = useState(false);

  const handleDeletePartnerCancel = () => {
    setDeleteMarketingPartnerOpen(null);
  };

  const handleDeletePartner = async () => {
    if (deleteMarketingPartnerOpen && schedule?.year) {
      try {
        await removeMarketingPartner(
          deleteMarketingPartnerOpen,
          schedule?.year,
          "DELETE"
        );
        setDeleteMarketingPartnerOpen(null);
        enqueueSnackbar(t("successfully-applied"), { variant: "success" });
        navigate(
          "/" + t("advertisingschedule").toLowerCase() + `/${scheduleId}`
        );
        fetchScheduleDetails();
      } catch (e) {
        enqueueSnackbar(t("error"), { variant: "error" });
      }
    }
  };

  useEffect(() => {
    setCampaignList(partner.marketingCampaigns);
  }, [partner.marketingCampaigns]);

  return (
    <>
      <TableRow
        className={classes.root}
        onMouseOver={() => setIsHover(true)}
        onMouseOut={() => setIsHover(false)}
      >
        <TableCell component="th" scope="row" width="62%">
          <IconButton
            aria-label={t("expand-row")}
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon
                fontSize="large"
                color={open ? "primary" : "inherit"}
              />
            ) : (
              <KeyboardArrowDownIcon
                fontSize="large"
                color={open ? "primary" : "inherit"}
              />
            )}
          </IconButton>
          <span className={open ? classes.collapseOpenStyles : ""}>
            {partner.name}
          </span>
        </TableCell>
        <TableCell align="right" width="25%">
          <Box className={classes.dropdownStyles}>
            <IconButton
              aria-label={t("delete")}
              onClick={() =>
                setDeleteMarketingPartnerOpen(partner.marketingPartnerId)
              }
              className={isHover ? "" : classes.invisible}
            >
              <RemoveSharpIcon className={classes.removeSharpIconStyles} />
            </IconButton>
            <StatusDropdown
              state={partner.state}
              schedulePartnerId={partner.id}
              isDisabled={isPartnersDropdownShown}
              changeStateHandler={(state) => {
                const newPartner = {
                  ...partner,
                  state,
                };
                changeMarketingPartnerHandler(newPartner);
              }}
            />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.collapsibleTableCellStyles} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {campaignList && (
              <Box display="flex" flexDirection="column">
                <CampaignTable
                  campaignList={campaignList}
                  setCampaignList={setCampaignList}
                  scheduleId={scheduleId ? +scheduleId : undefined}
                  marketingPartnerId={partner.id}
                />
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
      <ConfirmDialog
        title={t("delete-partner")}
        open={deleteMarketingPartnerOpen !== null}
        message={t("delete-partner-detail")}
        handleConfirm={handleDeletePartner}
        handleReject={handleDeletePartnerCancel}
        ariaLabel="delete-partner-dialog-title"
        ariaDescription="delete-partner-dialog-description"
        confirmBtnText={t("delete")}
      />
    </>
  );
}

export default MarketingPartnerTableRows;
