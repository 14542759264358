import {
  Box,
  Grid,
  Button,
  IconButton,
  Typography,
  makeStyles,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray, useWatch } from "react-hook-form";

import AddIcon from "@material-ui/icons/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AdPlanningFooter from "../../../components/ad-plans/AdPlanningFooter";

import RemoveSharpIcon from "@material-ui/icons/RemoveSharp";
import { useEffect, useState } from "react";
import { AdPlanning, AnnualMeeting } from "../../../../../dto/Adplan";
import Dropdown from "../../../dropdowns/Dropdown";
import {
  createAdplanning,
  getAdplannigArticle,
  getAdplannigConditionArea,
  getAdplannigSalesLine,
  getAdplanningBrand,
  getAdplanningPackageSize,
} from "../../../../../api/basedataApiClient";
import { useSnackbar } from "notistack";
import {
  getAdPlanning,
  setExportAdplanAct,
  setExportAdplanAll,
} from "../../../../../api/adplanApiClient";
import PeriodDropdown from "../../../dropdowns/PeriodDropdown";

import ConfirmDialog from "../../../../../components/ConfirmDialog";
import ReactRouterPrompt from "react-router-prompt";

const arrayEqualsUnsorted = (array1: number[], array2: number[]) =>
  array1.length === array2.length &&
  array1.every((item: number) => array2.includes(item));
interface ScheduleProps {
  campaignId: number;
  annualMeeting: AnnualMeeting | null;
}

const Schedule = ({ campaignId, annualMeeting }: ScheduleProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingExport, setLoadingExport] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [deleteAdplanningRow, setDeleteAdplanningRow] = useState<number | null>(
    null
  );
  const [qualitiesFromAnnualMeeting, setQualitiesFromAnnualMeeting] = useState({
    tailorMade: false,
    onlineCampaign: false,
    offerDiscount: false,
    coverPage: false,
    customQuality: false,
    hero: false,
    customQuality2: false,
    customQuality3: false,
    customQuality4: false,
    customQuality5: false,
    customQuality6: false,
  });
  const [countPerSalesLine, setCounterPerSalesLine] = useState<boolean>(false);

  const handleDeleteAdplanningRowCancel = () => {
    setDeleteAdplanningRow(null);
  };

  const handleDeleteAdplanningRow = () => {
    if (deleteAdplanningRow === null) return;
    remove(deleteAdplanningRow);
    setDeleteAdplanningRow(null);
  };

  const { control, handleSubmit, getValues, reset, formState, setValue } =
    useForm<AdPlanning>({
      defaultValues: {
        adPlanningPositions: [],
        advertisingCommitmentComment: "",
      },
      mode: "onBlur",
    });
  const setExportAdplanningAct = async () => {
    try {
      setLoadingExport(true);
      const result = await setExportAdplanAct(campaignId);
      setLoadingExport(false);
      enqueueSnackbar(t("export-successfully-started"), {
        variant: "success",
      });
      return result;
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  const setExportAdplanningAll = async () => {
    try {
      setLoadingExport(true);
      const result = await setExportAdplanAll(campaignId);
      setLoadingExport(false);
      enqueueSnackbar(t("export-successfully-started"), {
        variant: "success",
      });
      return result;
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const { fields, remove } = useFieldArray({
    name: "adPlanningPositions",
    control,
  });

  useEffect(() => {
    (async () => {
      try {
        if (annualMeeting) {
          setQualitiesFromAnnualMeeting({
            tailorMade: annualMeeting?.advertisingCommitmentData.tailorMade,
            onlineCampaign:
              annualMeeting?.advertisingCommitmentData.onlineCampaign,
            offerDiscount:
              annualMeeting?.advertisingCommitmentData.offerDiscount,
            coverPage: annualMeeting?.advertisingCommitmentData.coverPage,
            customQuality:
              annualMeeting?.advertisingCommitmentData.customQuality,
            hero: annualMeeting?.advertisingCommitmentData.hero,
            customQuality2:
              annualMeeting?.advertisingCommitmentData.customQuality2,
            customQuality3:
              annualMeeting?.advertisingCommitmentData.customQuality3,
            customQuality4:
              annualMeeting?.advertisingCommitmentData.customQuality4,
            customQuality5:
              annualMeeting?.advertisingCommitmentData.customQuality5,
            customQuality6:
              annualMeeting?.advertisingCommitmentData.customQuality6,
          });
          const result = await getAdPlanning(campaignId);
          reset(result);
          setIsLoaded(true);
        }
      } catch (e) {
        const error = e as Error;
        enqueueSnackbar(error.message, { variant: "error" });
      }
    })();
  }, [campaignId, reset, enqueueSnackbar, annualMeeting]);
  const watchPositions = useWatch({
    name: "adPlanningPositions",
    control: control,
  });
  let countByVl = watchPositions.map((pos) => {
    let salesLines = pos.salesLines;
    if (salesLines) {
      let equalSalesLines = watchPositions.filter((pos) =>
        arrayEqualsUnsorted(
          salesLines.map((sl) => sl.id),
          pos.salesLines.map((sl) => sl.id)
        )
      );
      return equalSalesLines.reduce(
        (prev, current) => prev + current.periods.length,
        0
      );
    }
    return 0;
  });

  const onSubmit = async (adPlanning: AdPlanning) => {
    try {
      const response = await createAdplanning(campaignId, adPlanning);
      if (response) reset(response);
      enqueueSnackbar(t("successfully-applied"), { variant: "success" });
    } catch (e) {
      const error = e as Error;
      console.log(e);

      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  // use this for ReactRouterPrompt ?
  // const navigateTo = useCallback(
  //   (path: string) => {
  //     navigate(path);
  //   },
  //   [navigate]
  // );

  return (
    <>
      <ReactRouterPrompt when={formState.isDirty}>
        {({ isActive, onConfirm, onCancel }) => (
          <ConfirmDialog
            title={t("unsaved-changes")}
            message={t("discard-addplanning-changes")}
            open={isActive}
            handleReject={onCancel}
            confirmBtnText={t("leave")}
            handleConfirm={onConfirm}
          />
        )}
      </ReactRouterPrompt>
      <Box className={classes.container}>
        <Typography variant="h6" component="span" color="textSecondary">
          {t("range-period-and-sales-line")}
        </Typography>
        <Box className={classes.boxHeight}></Box>
        <Grid container spacing={1}>
          <Grid item xs={2} lg>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              color="textSecondary"
            >
              {t("Vertriebslinie")}
            </Typography>
          </Grid>
          <Grid item xs={2} lg>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              color="textSecondary"
            >
              {t("brand")}
            </Typography>
          </Grid>
          <Grid item xs={2} lg>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              color="textSecondary"
            >
              {t("container")}
            </Typography>
          </Grid>
          <Grid item xs={2} lg={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              color="textSecondary"
            >
              {t("article")}
            </Typography>
          </Grid>
          <Grid item xs={2} lg>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              color="textSecondary"
            >
              {t("period")}
            </Typography>
          </Grid>

          <Grid item xs={2} lg>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              color="textSecondary"
            >
              {t("condition-area")}
            </Typography>
          </Grid>
          <Grid item xs={2} lg>
            <Box marginLeft={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={countPerSalesLine}
                      onChange={(event) =>
                        setCounterPerSalesLine(event.target.checked)
                      }
                      size="small"
                    />
                  }
                  label="VL"
                />
              </FormGroup>
            </Box>
          </Grid>
        </Grid>

        {isLoaded && fields && (
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((field, index) => {
              return (
                <div key={field.id}>
                  <section className={"section"} key={field.id}>
                    <Grid container spacing={1}>
                      <Grid item xs={2} lg>
                        <Dropdown
                          control={control}
                          name={`adPlanningPositions.${index}.salesLines`}
                          fetchData={getAdplannigSalesLine}
                          fetchParams={{
                            campaignId: campaignId.toString(),
                          }}
                        />
                      </Grid>
                      <Grid item xs={2} lg>
                        <Dropdown
                          control={control}
                          name={`adPlanningPositions.${index}.brands`}
                          shouldServerSideSearch
                          fetchData={getAdplanningBrand}
                          fetchParams={{
                            campaignId: campaignId.toString(),
                            articleIds: watchPositions[index]?.articles
                              ?.map((a) => a.id)
                              .flat(),
                            brandIds: watchPositions[index]?.brands
                              ?.map((b) => b.id)
                              .flat(),
                          }}
                        />
                      </Grid>
                      <Grid item xs={2} lg>
                        <Dropdown
                          control={control}
                          name={`adPlanningPositions.${index}.packageSizes`}
                          shouldServerSideSearch
                          fetchData={getAdplanningPackageSize}
                          fetchParams={{
                            campaignId: campaignId.toString(),
                            articleIds: watchPositions[index]?.articles
                              ?.map((a) => a.id)
                              .flat(),
                            brandIds: watchPositions[index]?.brands
                              ?.map((b) => b.id)
                              .flat(),
                          }}
                        />
                      </Grid>
                      <Grid item xs={2} lg={4}>
                        <Dropdown
                          control={control}
                          shouldServerSideSearch
                          name={`adPlanningPositions.${index}.articles`}
                          fetchData={getAdplannigArticle}
                          fetchParams={{
                            campaignId: campaignId.toString(),
                            articleIds: watchPositions[index]?.articles
                              ?.map((a) => a.id)
                              .flat(),
                            brandIds: watchPositions[index]?.brands
                              ?.map((b) => b.id)
                              .flat(),
                            packageSizeIds: watchPositions[index]?.packageSizes
                              ?.map((p: any) => p.id.toString())
                              .flat(),
                          }}
                        />
                      </Grid>

                      <Grid item xs={2} lg>
                        <PeriodDropdown
                          control={control}
                          name={`adPlanningPositions.${index}.periods`}
                          campaignId={campaignId.toString()}
                          qualitiesFromAnnualMeeting={
                            qualitiesFromAnnualMeeting
                          }
                        />
                      </Grid>

                      <Grid item xs={2} lg>
                        <Dropdown
                          control={control}
                          name={`adPlanningPositions.${index}.conditionAreas`}
                          fetchData={getAdplannigConditionArea}
                          fetchParams={{
                            campaignId: campaignId.toString(),
                          }}
                        />
                      </Grid>

                      <Grid item xs={2} lg>
                        <Box
                          height="100%"
                          display={"flex"}
                          flexDirection="row"
                          alignItems="center"
                        >
                          {!countPerSalesLine ? (
                            <Typography
                              style={{
                                color:
                                  watchPositions[index]?.periods?.length !==
                                  (annualMeeting?.advertisingCommitmentData
                                    ?.initiations || 0)
                                    ? "red"
                                    : "green",
                              }}
                            >
                              {`${
                                watchPositions[index]?.periods?.length || "0"
                              } von ${
                                annualMeeting?.advertisingCommitmentData
                                  ?.initiations || "0"
                              }`}
                            </Typography>
                          ) : (
                            <Typography
                              style={{
                                color:
                                  countByVl[index] !==
                                  (annualMeeting?.advertisingCommitmentData
                                    ?.initiations || 0)
                                    ? "red"
                                    : "green",
                              }}
                            >
                              {`${countByVl[index] || "0"} von ${
                                annualMeeting?.advertisingCommitmentData
                                  ?.initiations || "0"
                              }`}
                            </Typography>
                          )}

                          {fields.length > 1 && (
                            <>
                              <IconButton
                                size="small"
                                aria-label={t("delete")}
                                onClick={() => {
                                  setDeleteAdplanningRow(index);
                                }}
                                className={classes.removeSharpIconStyles}
                              >
                                <RemoveSharpIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => {
                                  const values = getValues();
                                  const row = watchPositions[index];

                                  values.adPlanningPositions = [
                                    ...values.adPlanningPositions,
                                    row,
                                  ];

                                  reset({ ...values }, { keepDirty: true });
                                }}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </section>
                  <Box className={classes.boxHeight}></Box>
                </div>
              );
            })}           <Box mt={3}>
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  const values = getValues();
                  let lastRow = [...getValues().adPlanningPositions];
                  lastRow.pop();
                  if (!lastRow) return;
                  let newAdPlanningPositions = [
                      ...values.adPlanningPositions,
                    ];
                  newAdPlanningPositions.push({
                    brands: [],
                    packageSizes: [],
                    articles: [],
                    periods: [],
                    salesLines: [],
                    conditionAreas: [],
                  })
                  setValue("adPlanningPositions", newAdPlanningPositions);
                }}
                startIcon={<AddIcon></AddIcon>}
              >
                {t("add-article")}
              </Button>
            </Box>
          </form>
        )}

        <Box mb={17}></Box>
      </Box>
      <Box className={classes.captionContainer}>
        <Typography
          component="span"
          variant="caption"
          className={`${classes.center} ${classes.or}`}
        >
          {t("comment-on-advertising-obligation")}
        </Typography>
        <Typography
          component="span"
          variant="body2"
          style={{ marginLeft: "40px" }}
        >
          {getValues("advertisingCommitmentComment")}
        </Typography>
      </Box>

      <AdPlanningFooter
        setExportAdplanningAct={setExportAdplanningAct}
        setExportAdplanningAll={setExportAdplanningAll}
        submitHandler={handleSubmit(onSubmit)}
      />

      <ConfirmDialog
        title={t("delete-action")}
        open={deleteAdplanningRow !== null}
        message={t("delete-addplanning-row")}
        handleConfirm={handleDeleteAdplanningRow}
        handleReject={handleDeleteAdplanningRowCancel}
        ariaLabel="delete-action-dialog-title"
        ariaDescription="delete-action-dialog-description"
        confirmBtnText={t("delete")}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexFlow: "column",
    minHeight: "calc(100vh - 435px)",
  },

  removeSharpIconStyles: {
    color: "#b71c1c",
    marginLeft: theme.spacing(2),
  },

  boxHeight: {
    height: theme.spacing(2),
  },

  captionContainer: {
    marginBottom: theme.spacing(5),
  },

  center: {
    width: theme.spacing(44),
    display: "flex",
    color: "#757575",
  },

  or: {
    position: "relative",
    marginLeft: "40px",
    "&:before, &:after": {
      content: "''",
      display: "inline-block",
      height: "1px",
      background: "#00000044",
      position: "absolute",
      top: "50%",
    },

    "&:before": {
      width: "20px",
      transform: "translate(-140%, -50%)",
    },
    "&:after": {
      width: "60px",
      transform: "translate(340%, -50%)",
    },
  },
}));

export default Schedule;
