import {  makeStyles } from "@material-ui/core";
import { DateRange} from "react-date-range";
import {useEffect, useRef} from "react";

interface InputPeriodDateContainerProps {
  onChange: (...event: any[]) => void;
  value: any;
  name: string;
  toggleDate: (index:number)=>void;
  index:number;
}

const useStyles = makeStyles((theme) => ({
  date: {
    position: "absolute",
    top: "50px",
    left: 0,
    zIndex: 1,
    border: "1px solid #eff2f7",
  },
}));

const InputPeriodDateContainer = ({
                           onChange,
                           value,name,toggleDate, index
}: InputPeriodDateContainerProps) => {
  const classes = useStyles();
  const dateRef = useRef<any>(null);

  useEffect(() => {
    window.onclick = (event: MouseEvent) => {
      event.preventDefault()
      const target = event.target as HTMLDivElement
          | HTMLInputElement
          | HTMLParagraphElement
          | HTMLButtonElement
          | HTMLHeadingElement;

      if (target?.contains(dateRef.current) && target !== dateRef.current) {
        toggleDate(index);
      } else {
      }
    }
  }, [index,toggleDate]);
  // @ts-ignore
        return (
          <>
            <div ref={dateRef}>
                <DateRange
                  className={classes.date}
                  editableDateInputs={true}
                  ranges={[
                    {
                      startDate: new Date(value.startDate),
                      endDate: new Date(value.endDate),
                      key: name,
                    },
                  ]}
                  onChange={(data) => {
                    onChange(data.periodDate);
                  }}
                  color={"#3f51b5"}
                  rangeColors={["#3f51b5", "#3ecf8e", "#fed14c"]}
                />
            </div>
          </>
        );
};

export default InputPeriodDateContainer;
