import { Select, MenuItem, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { getScheduleYears } from "../../../../api/adplanApiClient";

import BaseFormField from "../../../../components/BaseFormField";

const useStyles = makeStyles({
  selectStyles: {
    textAlign: "left",
  },
});

const ScheduleYearsDropdown = ({ register, errors }: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [scheduleYears, setScheduleYears] = useState<string[]>();

  const fetchScheduleYears = useCallback(async () => {
    try {
      const years = await getScheduleYears();
      setScheduleYears(years);
    } catch (e) {
      enqueueSnackbar(t("error"), {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    fetchScheduleYears();
  }, [fetchScheduleYears]);

  return (
    <>
      <BaseFormField name={"year"} errors={errors} label={t("year")}>
        <Select
          defaultValue=""
          label={t("year")}
          {...register("year", {
            required: `${t("the-year-is-a-mandatory-field")}`,
          })}
          className={classes.selectStyles}
        >
          <MenuItem value="" disabled>
            {t("year")}
          </MenuItem>
          {scheduleYears &&
            scheduleYears.map((year, index) => (
              <MenuItem key={index} value={year}>
                {year}
              </MenuItem>
            ))}
        </Select>
      </BaseFormField>
    </>
  );
};

export default ScheduleYearsDropdown;
