import { Box } from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
//@ts-ignore
import {
  upsertPrintTemplateOrState,
  UpsertPrintTemplateOrStatePayload,
  DetailedPlanningDetail,
  DetailedPlanningPosition,
} from "../../../api/detailedPlanningApiClient";
import ConfirmDialog from "../../../components/ConfirmDialog";
import FlyerDropdown from "../dropdowns/FlyerDropdown";
import StatusDropdown from "../dropdowns/StatusDropdown";

interface FormValues {
  templateAndState: UpsertPrintTemplateOrStatePayload;
}

interface PrintTemplateAndStatusProps {
  watchedPages: {
    positions: DetailedPlanningPosition[];
  }[];
  value: UpsertPrintTemplateOrStatePayload;
  onChange: (value: { detailedPlanningDetail: DetailedPlanningDetail }) => void;
  allreadyInUse?: boolean | null;
  setFieldsChanged: Dispatch<SetStateAction<boolean>>;
}

export interface LocationProp {
  year: string;
  weekOfYear: string;
  importVersion: string;
  clientele: string;
}

const PrintTemplateAndStatus = ({
  watchedPages,
  onChange,
  value,
  allreadyInUse,
  setFieldsChanged,
}: PrintTemplateAndStatusProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [showResetDialog, setShowResetDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const { clientele, importVersion, weekOfYear, year } =
    (location.state as LocationProp)
      ? (location.state as LocationProp)
      : JSON.parse(localStorage.getItem("detailedPlaning") as string);
  const { control, watch, reset, getValues } = useForm<FormValues>({
    defaultValues: {
      templateAndState: value,
    },
  });
  const handleConfirm = async () => {
    setLoading(true);
    const values = getValues();
    await upsertPrintTemplateOrStateCallback(values, true);
    setLoading(false);
    setShowResetDialog(false);
  };

  const handleAlternativeConfirm = async () => {
    setLoading(true);
    const values = getValues();
    await upsertPrintTemplateOrStateCallback(values, false);
    setFieldsChanged(true);
    setLoading(false);
    setShowResetDialog(false);
  };

  const handleReject = () => {
    reset({ templateAndState: value });
    setShowResetDialog(false);
  };

  const upsertPrintTemplateOrStateCallback = useCallback(
    async (newValue: FormValues, force: boolean = false) => {
      try {
        let result = await upsertPrintTemplateOrState(
          {
            year: parseInt(year),
            weekOfYear: parseInt(weekOfYear),
            version: parseInt(importVersion),
            clientele: clientele,
            force: true,
          },
          {
            printTemplateId: newValue.templateAndState!.printTemplateId,
            state: newValue.templateAndState!.state,
          }
        );
        if (!force) {
          result = {
            ...result,
            pages: result.pages.map((r, i) => {
              return {
                ...r,
                positions: watchedPages[i]?.positions?.filter(
                  (p) => p.articleAssignments
                ),
              };
            }),
          };
        }
        onChange({ detailedPlanningDetail: result });
      } catch (e) {
        const error = e as Error;
        enqueueSnackbar(error.message, { variant: "error" });
        reset({ templateAndState: value });
      }
    },
    [
      clientele,
      importVersion,
      weekOfYear,
      year,
      enqueueSnackbar,
      onChange,
      reset,
      value,
      watchedPages,
    ]
  );

  useEffect(() => {
    reset({ templateAndState: value });
    setShowResetDialog(false);
  }, [value, reset]);

  useEffect(() => {
    const subscription = watch(async (newValue, { name, type }) => {
      if (
        (name === "templateAndState.printTemplateId" ||
          name === "templateAndState.state") &&
        type === "change"
      ) {
        if (name === "templateAndState.printTemplateId" && allreadyInUse) {
          setShowResetDialog(true);
        } else {
          upsertPrintTemplateOrStateCallback(newValue as any);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, allreadyInUse, upsertPrintTemplateOrStateCallback]);

  return (
    <Box display="flex" alignItems="center">
      <Box sx={{ width: "200px", mr: 2 }}>
        <FlyerDropdown
          name="templateAndState.printTemplateId"
          control={control}
        />
      </Box>
      <Box sx={{ width: "180px" }}>
        <StatusDropdown
          name="templateAndState.state"
          control={control}
          disableUnknown={false}
          showEmptyOption={false}
        />
      </Box>
      <ConfirmDialog
        title={t("print-template-warning-title")}
        open={showResetDialog}
        message={t("print-template-warning-message")}
        handleConfirm={handleConfirm}
        handleReject={handleReject}
        handleAlternativeConfirm={handleAlternativeConfirm}
        ariaLabel="print-template-warning-title"
        ariaDescription="print-template-warning-message"
        confirmBtnText={t("delete")}
        alternativeConfirmBtnText={t("import")}
        showAlternativeConfirmButton={true}
        loading={loading}
      />
    </Box>
  );
};

export default PrintTemplateAndStatus;
