import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import AdvertisingScheduleForm from "./AdvertisingScheduleForm";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: "center",
    },

    h1Styles: {
      fontWeight: "normal",
    },
  })
);

interface NewAdvertisingScheduleModalProps {
  open: boolean;
  handleClose: () => void;
}

const AdvertisingScheduleModal = ({
  open,
  handleClose,
}: NewAdvertisingScheduleModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby={t("new-advertising-plan-title")}
      aria-describedby={t("new-advertising-plan-form")}
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className={classes.paper}>
          <h1 id={t("new-advertising-plan-title")} className={classes.h1Styles}>
            {t("new-advertising-plan")}
          </h1>
          <AdvertisingScheduleForm handleClose={handleClose} />
        </Box>
      </Fade>
    </Modal>
  );
};

export default AdvertisingScheduleModal;
