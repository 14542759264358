import {
  CircularProgress,
  FormControl,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { Control, Controller } from "react-hook-form";

import { useState, useEffect } from "react";

import {
  DropDownResult,
  GetDropDownItemsParams,
  DropDownItem,
  FindRangeSelectableParams,
} from "../../../api/basedataApiClient";
import { AutocompleteRenderOptionState } from "@material-ui/lab";
import { useSnackbar } from "notistack";

export interface DropdownProps {
  fetchData: (
    params: GetDropDownItemsParams,
    params2: FindRangeSelectableParams | undefined
  ) => Promise<DropDownResult>;
  fetchParams?: GetDropDownItemsParams;
  fetchParams2?: FindRangeSelectableParams;
  label?: string;
  control: Control<any>;
  rules?: any;
  shouldServerSideSearch?: boolean;
  name: any;
  searchField?: string;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  getOptionLabel?: (option: any) => string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    { selected }: AutocompleteRenderOptionState,
    onChange?: (data: any) => void
  ) => React.ReactNode;
  noOptionText?: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  listItemStyles: {
    padding: 0,
  },

  selectContainer: {
    backgroundColor: "#eeeeee",
    paddingRight: theme.spacing(1),
  },

  input: {
    "& ::placeholder": {
      // textOverflow: "ellipsis !important",
      color: "black !important",
      opacity: "1 !important",
    },
  },
}));

const Dropdown = ({
  noOptionText,
  fetchData,
  fetchParams,
  fetchParams2,
  label,
  control,
  rules,
  shouldServerSideSearch,
  name,
  searchField = "search",
  isOptionEqualToValue = (option, value) => option.id === value.id,
  getOptionLabel = (option) => option.name,
  renderOption = (props, option, { selected }, onChange) => (
    <li {...props} key={option.id}>
      <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
      {option.name}
    </li>
  ),
}: DropdownProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<DropDownItem[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [textToShowClear, setTextToShowClear] = useState("");

  let loading = open && options.length === 0;

  const [showCircularProgress, setShowCircularProgress] = useState(false);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        setShowCircularProgress(true);
        const response = await fetchData(
          { ...fetchParams },
          { ...fetchParams2 }
        );
        const responseResult = response.results;
        if (active) {
          setOptions(responseResult);
        }
      } catch (e) {
        const error = e as Error;
        enqueueSnackbar(error.message, { variant: "error" });
        setOptions([]);
      } finally {
        setShowCircularProgress(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, fetchParams, fetchParams2, fetchData, enqueueSnackbar]);

  useEffect(() => {
    if (!shouldServerSideSearch) {
      return;
    }
    if (searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        (async () => {
          setShowCircularProgress(true);
          const response = await fetchData(
            {
              ...fetchParams,
              [searchField]: searchTerm,
            },
            {
              ...fetchParams2,
              [searchField]: searchTerm,
            }
          );
          const responseResult = response.results;

          setShowCircularProgress(false);

          setOptions(responseResult);
        })();
      }, 500);

      return () => {
        clearTimeout(delayDebounceFn);
      };
    }
  }, [
    fetchData,
    searchTerm,
    fetchParams,
    fetchParams2,
    searchField,
    shouldServerSideSearch,
  ]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <>
          <Autocomplete
            noOptionsText={noOptionText}
            isOptionEqualToValue={isOptionEqualToValue}
            value={value ? value : []}
            multiple
            options={options}
            loading={showCircularProgress}
            open={open}
            disableCloseOnSelect
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            getOptionLabel={getOptionLabel}
            renderOption={(...renderOptionProps) =>
              renderOption(
                renderOptionProps[0],
                renderOptionProps[1],
                renderOptionProps[2],
                onChange
              )
            }
            onInputChange={
              shouldServerSideSearch
                ? (event, input) => setSearchTerm(input)
                : undefined
            }
            disableClearable={
              showCircularProgress || textToShowClear !== "" ? true : false
            }
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                type="search"
                error={error ? true : false}
                {...params}
                placeholder={
                  value
                    ? value.map((elem: any) => elem?.name || elem).join(", ")
                    : ""
                }
                className={classes.input}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {showCircularProgress && (
                        <CircularProgress color="inherit" size={20} />
                      )}

                      {params.InputProps.endAdornment}
                    </>
                  ),
                  sx: {
                    fontSize: 14,
                    minWidth: "100%",
                    paddingRight: "36px !important",
                  },
                }}
                onChange={(e) => setTextToShowClear(e.target.value)}
                onBlur={() => setTextToShowClear("")}
                label={label}
              />
            )}
            onChange={(e, data) => {
              onChange(data);
              setSearchTerm("");
            }}
          />
          {error && (
            <FormControl error>
              <FormHelperText>{error.message}</FormHelperText>
            </FormControl>
          )}
        </>
      )}
    />
  );
};

export default Dropdown;
