import { authRequest } from "../authConfig";
import { AuthenticationScheme } from "@azure/msal-common";
import {
  InteractionRequiredAuthError,
  SilentRequest,
} from "@azure/msal-browser";
import { msalInstance } from "..";

/**
 * Build auth request.
 */
const getAuthRequest = async () => {
  const account = await msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }
  return {
    ...authRequest,
    account,
  };
};

const acquireAccessToken = async (forceRefresh: boolean = false) => {
  const request = getAuthRequest();
  try {
    return await msalInstance.acquireTokenSilent({
      scopes: (await request).scopes,
      account: (await request).account,
      authenticationScheme: AuthenticationScheme.BEARER,
      forceRefresh: forceRefresh,
    } as SilentRequest);
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect(await request);
    }
    throw e;
  }
};

const populateOptions = (
  method: string,
  payload: any,
  token: string | undefined,
  formData: any
): RequestInit => {
  let headers = new Headers();
  if (!formData) {
    headers.append("Content-Type", "application/json");
  }
  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }
  return !payload && !formData
    ? ({
        method,
        headers,
      } as RequestInit)
    : ({
        body: formData || JSON.stringify(payload),
        method,
        headers,
      } as RequestInit);
};

export async function callApi<T>(
  path: string,
  method: string,
  payload: any = null,
  appendBearerToken: boolean = false,
  formData: FormData | null = null,
  preventDownload: boolean = false
): Promise<T> {
  try {
    let authResult = await acquireAccessToken();
    let options = populateOptions(
      method,
      payload,
      authResult?.accessToken,
      formData
    );
    const url = `${path}`;
    let response = await fetch(url, options);
    if (response.status === 401) {
      authResult = await acquireAccessToken(true);
      options = populateOptions(
        method,
        payload,
        authResult?.accessToken,
        formData
      );
      response = await fetch(url, options);
    }
    if (!response.ok) {
      let errorJson = await response.json();
      throw Error(errorJson.message ? errorJson.message : response.statusText);
    } else {
      if (response.headers.get("content-disposition")) {
        if (!preventDownload) {
          let filename = "";
          let disposition = response.headers.get("Content-Disposition");
          if (disposition) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        return null as any;
      } else {
        const string = await response.text();
        const json = string === "" ? {} : JSON.parse(string);
        return json;
      }
    }
  } catch (e) {
    const error = e as Error;
    throw error;
  }
}
