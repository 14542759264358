import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdPlanning from "../../domain/ad-planning/views/AdPlanning";

const AdPlanningPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `${t("advertising-planning")} - ${t("customer")}`;
  });
  return <AdPlanning />;
};

export default AdPlanningPage;
