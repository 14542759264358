import { OutlinedInput, InputAdornment } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import SearchIcon from "@material-ui/icons/Search";
import BaseFormField from "../../components/BaseFormField";

interface SearchInputProps {
  requestSearch: (value: string) => void;
  value?: string;
}

const SearchInput = ({ requestSearch, value }: SearchInputProps) => {
  const { t } = useTranslation();
  return (
    <BaseFormField name="adPlanName">
      <OutlinedInput
        type="search"
        value={value}
        placeholder={t("search")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          requestSearch(e.target.value)
        }
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </BaseFormField>
  );
};

export default SearchInput;
