import { useMsal } from "@azure/msal-react";
import { Button, ButtonProps } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const SignOutButton = (props: ButtonProps) => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const { t } = useTranslation();
  if (account) {
    return (
      <Button
        {...props}
        type="submit"
        variant="text"
        color="secondary"
        onClick={() => instance.logoutRedirect()}
      >
        {t("sign-out")}
      </Button>
    );
  } else {
    return <></>;
  }
};
export default SignOutButton;
