import {
  CircularProgress,
  FormControl,
  FormHelperText,
  makeStyles
} from "@material-ui/core";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Box, Checkbox, Chip, ListItem, Popper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { getAdplannigPeriods } from "../../../api/basedataApiClient";
import { PeriodDropdownItem } from "../../../dto/Adplan";

interface PeriodDropdownProps {
  control: any;
  name: string;
  campaignId: string;
  label?: string;
  qualitiesFromAnnualMeeting: {
    tailorMade: boolean;
    onlineCampaign: boolean;
    offerDiscount: boolean;
    coverPage: boolean;
    customQuality: boolean;
    hero: boolean;
    customQuality2: boolean;
    customQuality3: boolean;
    customQuality4: boolean;
    customQuality5: boolean;
    customQuality6: boolean;
  };
}

const chipStylesSelected = {
  color: "white",
  backgroundColor: "#3f51b5",
  fontSize: "11px",
};

const chipStylesNotSelected = {
  color: "white",
  backgroundColor: "#9fa8da",
  fontSize: "11px",
};

const useStyles = makeStyles((theme) => ({
  listItemStyles: {
    padding: 0,
  },

  selectContainer: {
    backgroundColor: "#eeeeee",
    paddingRight: theme.spacing(1),
  },

  input: {
    "& ::placeholder": {
      textOverflow: "ellipsis !important",
      color: "black !important",
      opacity: "1 !important",
    },
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomPopper = (props: any) => {
  return <Popper
    {...props}
    style={{ width: "auto" }}
    placement="bottom-start"
  /> }
;

const PeriodDropdown = ({
  name,
  control,
  campaignId,
  label,
  qualitiesFromAnnualMeeting,
}: PeriodDropdownProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<PeriodDropdownItem[]>([]);

  const [textToShowClear, setTextToShowClear] = useState("");

  const [showCircularProgress, setShowCircularProgress] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setShowCircularProgress(true);
        const response = await getAdplannigPeriods({
          campaignId: campaignId,
        });
        const responseResult = response.results;
        setOptions(responseResult);
      } catch (e) {
        const error = e as Error;
        enqueueSnackbar(error.message, { variant: "error" });
        setOptions([]);
      } finally {
        setShowCircularProgress(false);
      }
    })();
  }, [campaignId, enqueueSnackbar]);

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={value ? value : []}
              multiple
              options={options.map((o) => ({
                ...o,
                // TODO Backend: qualities come as default true from backend, making the preselected when activating the given week
                // disabling manually here for now, maybe move this to backend?
                coverPage: false,
                hero: false,
                tailorMade: false,
                onlineCampaign: false,
                offerDiscount: false,
                customQuality: false,
                customQuality2: false,
                customQuality3: false,
                customQuality4: false,
                customQuality5: false,
                customQuality6: false,
              }))}
              loading={showCircularProgress}
              open={open}
              disableCloseOnSelect
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => {
                let values = [...value];
                let index = values.findIndex((o: any) => o.id === option.id);
                return (
                  <Box key={option.id}>
                    <ListItem {...props} key={option.id}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                      {option.name}
                      {selected && (
                        <>
                          {(qualitiesFromAnnualMeeting.coverPage ||
                            values[index].coverPage) && (
                            <Box ml={2}>
                              <Chip
                                label="TS"
                                size="small"
                                style={
                                  index !== -1 && values[index].coverPage
                                    ? chipStylesSelected
                                    : chipStylesNotSelected
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  values[index] = {
                                    ...values[index],
                                    coverPage: !values[index].coverPage,
                                  };
                                  onChange(values);
                                }}
                              />
                            </Box>
                          )}
                          {(qualitiesFromAnnualMeeting.tailorMade ||
                            values[index].tailorMade) && (
                            <Box ml={1}>
                              <Chip
                                label="TM"
                                size="small"
                                style={
                                  index !== -1 && values[index].tailorMade
                                    ? chipStylesSelected
                                    : chipStylesNotSelected
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  values[index] = {
                                    ...values[index],
                                    tailorMade: !values[index].tailorMade,
                                  };
                                  onChange(values);
                                }}
                              />
                            </Box>
                          )}
                          {(qualitiesFromAnnualMeeting.onlineCampaign ||
                              values[index].onlineCampaign) && (
                              <Box ml={1}>
                                <Chip
                                    label="OMA"
                                    size="small"
                                    style={
                                      index !== -1 && values[index].onlineCampaign
                                          ? chipStylesSelected
                                          : chipStylesNotSelected
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      values[index] = {
                                        ...values[index],
                                        onlineCampaign: !values[index].onlineCampaign,
                                      };
                                      onChange(values);
                                    }}
                                />
                              </Box>
                          )}
                          {(qualitiesFromAnnualMeeting.offerDiscount ||
                              values[index].offerDiscount) && (
                              <Box ml={1}>
                                <Chip
                                    label="AR"
                                    size="small"
                                    style={
                                      index !== -1 && values[index].offerDiscount
                                          ? chipStylesSelected
                                          : chipStylesNotSelected
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      values[index] = {
                                        ...values[index],
                                        offerDiscount: !values[index].offerDiscount,
                                      };
                                      onChange(values);
                                    }}
                                />
                              </Box>
                          )}
                          {(qualitiesFromAnnualMeeting.hero ||
                            values[index].hero) && (
                            <Box ml={1}>
                              <Chip
                                label="H"
                                size="small"
                                style={
                                  index !== -1 && values[index].hero
                                    ? chipStylesSelected
                                    : chipStylesNotSelected
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  values[index] = {
                                    ...values[index],
                                    hero: !values[index].hero,
                                  };
                                  onChange(values);
                                }}
                              />
                            </Box>
                          )}
                          {(qualitiesFromAnnualMeeting.customQuality ||
                            values[index].customQuality) && (
                            <Box ml={1}>
                              <Chip
                                label={values[index].customQualityDescription}
                                size="small"
                                style={
                                  index !== -1 && values[index].customQuality
                                    ? chipStylesSelected
                                    : chipStylesNotSelected
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  values[index] = {
                                    ...values[index],
                                    customQuality: !values[index].customQuality,
                                  };
                                  onChange(values);
                                }}
                              />
                            </Box>
                          )}
                          {(qualitiesFromAnnualMeeting.customQuality2 ||
                              values[index].customQuality2) && (
                              <Box ml={1}>
                                <Chip
                                    label={values[index].customQualityDescription2}
                                    size="small"
                                    style={
                                      index !== -1 && values[index].customQuality2
                                          ? chipStylesSelected
                                          : chipStylesNotSelected
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      values[index] = {
                                        ...values[index],
                                        customQuality2: !values[index].customQuality2,
                                      };
                                      onChange(values);
                                    }}
                                />
                              </Box>
                          )}
                          {(qualitiesFromAnnualMeeting.customQuality3 ||
                              values[index].customQuality3) && (
                              <Box ml={1}>
                                <Chip
                                    label={values[index].customQualityDescription3}
                                    size="small"
                                    style={
                                      index !== -1 && values[index].customQuality3
                                          ? chipStylesSelected
                                          : chipStylesNotSelected
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      values[index] = {
                                        ...values[index],
                                        customQuality3: !values[index].customQuality3,
                                      };
                                      onChange(values);
                                    }}

                                />
                              </Box>
                          )}
                          {(qualitiesFromAnnualMeeting.customQuality4 ||
                              values[index].customQuality4) && (
                              <Box ml={1}>
                                <Chip
                                    label={values[index].customQualityDescription4}
                                    size="small"
                                    style={
                                      index !== -1 && values[index].customQuality4
                                          ? chipStylesSelected
                                          : chipStylesNotSelected
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      values[index] = {
                                        ...values[index],
                                        customQuality4: !values[index].customQuality4,
                                      };
                                      onChange(values);
                                    }}
                                />
                              </Box>
                          )}
                          {(qualitiesFromAnnualMeeting.customQuality5 ||
                              values[index].customQuality5) && (
                              <Box ml={1}>
                                <Chip
                                    label={values[index].customQualityDescription5}
                                    size="small"
                                    style={
                                      index !== -1 && values[index].customQuality5
                                          ? chipStylesSelected
                                          : chipStylesNotSelected
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      values[index] = {
                                        ...values[index],
                                        customQuality5: !values[index].customQuality5,
                                      };
                                      onChange(values);
                                    }}
                                />
                              </Box>
                          )}
                          {(qualitiesFromAnnualMeeting.customQuality6 ||
                              values[index].customQuality6) && (
                              <Box ml={1}>
                                <Chip
                                    label={values[index].customQualityDescription6}
                                    size="small"
                                    style={
                                      index !== -1 && values[index].customQuality6
                                          ? chipStylesSelected
                                          : chipStylesNotSelected
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      values[index] = {
                                        ...values[index],
                                        customQuality6: !values[index].customQuality6,
                                      };
                                      onChange(values);
                                    }}
                                />
                              </Box>
                          )}
                        </>
                      )}
                    </ListItem>
                  </Box>
                );
              }}
              disableClearable={
                showCircularProgress || textToShowClear !== "" ? true : false
              }
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  type="search"
                  error={error ? true : false}
                  {...params}
                  placeholder={
                    value ? value.map((elem: any) => elem.id).join(", ") : ""
                  }
                  className={classes.input}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {showCircularProgress && (
                          <CircularProgress color="inherit" size={20} />
                        )}

                        {params.InputProps.endAdornment}
                      </>
                    ),
                    sx: {
                      fontSize: 14,
                      minWidth: "100%",
                      paddingRight: "36px !important",
                    },
                  }}
                  onChange={(e) => setTextToShowClear(e.target.value)}
                  onBlur={() => setTextToShowClear("")}
                  label={label}
                />
              )}
              onChange={(e, data) => {
                onChange(data);
              }}
              PopperComponent={CustomPopper}
            />
            {error && (
              <FormControl error>
                <FormHelperText>{error.message}</FormHelperText>
              </FormControl>
            )}
          </>
        )}
      />
    </>
  );
};
export default PeriodDropdown;

