import { Box, Divider, makeStyles } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Customers } from "../../../api/detailedPlanningApiClient";

interface CampaignTableRowsProps {
  customer: Customers;
  year: number;
  weekOfYear: number;
  clientele: string;
  importVersion: number;
}

const navlinkStyle = {
  color: "black",

  textDecoration: "none",
  width: "100%",
};

const CustomerCollapsibleTableRow = ({
  customer,
  year,
  weekOfYear,
  clientele,
  importVersion,
}: CampaignTableRowsProps) => {
  const { t } = useTranslation();
  const classes = useRowStyles();

  return (
    <>
      <Divider />
      <Box className={classes.boxStyles}>
        <NavLink
          style={({ isActive }) => ({
            ...navlinkStyle,
            color: isActive ? "#0d47a1" : "inherit",
          })}
          // isActive={() =>
          //   history.location.pathname.indexOf(parentPath) > -1
          // }

          to={
            "/" +
            t("detailed-planning").toLowerCase() +
            "/clientele/" +
            customer.id
          }
          state={{
            year: year,
            weekOfYear: weekOfYear,
            customerName: customer.name,
            clientele: clientele,
            importVersion: importVersion,
          }}
        >
          {customer.name}
        </NavLink>
      </Box>
    </>
  );
};

const useRowStyles = makeStyles((theme) => ({
  boxStyles: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
}));

export default CustomerCollapsibleTableRow;
