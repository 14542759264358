import { Box, Button, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";

import { useNavigate } from "react-router";

import AddIcon from "@material-ui/icons/Add";

import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import CampaignTableRow from "./CampaignTableRow";
import { MarketingCampaign } from "../../dto/Adplan";

import ConfirmDialog from "../../components/ConfirmDialog";

import {
  createCampaign,
  updateCampaign,
  copyCampaign,
} from "../../api/adplanApiClient";

import { AdPlanningContext } from "./AdPlanLayout";

const useRowStyles = makeStyles((theme) => ({
  addBtnBoxStyles: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    width: "100%",
  },
}));

interface CampaignTableProps {
  campaignList: MarketingCampaign[];
  setCampaignList: React.Dispatch<React.SetStateAction<MarketingCampaign[]>>;
  scheduleId: number | undefined;
  marketingPartnerId: number;
}

const CampaignTable = ({
  campaignList,
  setCampaignList,
  scheduleId,
  marketingPartnerId,
}: CampaignTableProps) => {
  const { t } = useTranslation();
  const classes = useRowStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchScheduleDetails } = useContext(AdPlanningContext);

  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isNameAvailable, setIsNameAvailable] = useState(true);

  const [deleteCampaignOpen, setDeleteCampaignOpen] = useState<number | null>(
    null
  );

  const [copyCampaignOpen, setCopyCampaignOpen] = useState<number | null>(null);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        stopEditing();

        let updatedCampaignList = campaignList.filter(
          (item) => item.id !== null
        );

        setCampaignList(updatedCampaignList);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [campaignList, setCampaignList]);

  const stopEditing = () => {
    setEditIndex(null);
  };

  const startEditing = (index: number) => () => {
    setEditIndex(index);
  };

  const addCampaignHandler = () => {
    setCampaignList([...campaignList, { id: null, name: "" }]);
    setEditIndex(campaignList.length);
    setIsNameAvailable(true);
  };

  const nameChangeHandler = async (campaignId: number | null, name: string) => {
    try {
      if (campaignId === null) {
        await createCampaign(marketingPartnerId, "POST", {
          name: name,
        });
        stopEditing();
        fetchScheduleDetails();
      } else {
        await updateCampaign(campaignId, "PUT", { name: name });
        stopEditing();
        fetchScheduleDetails();
      }
      enqueueSnackbar(t("successfully-applied"), { variant: "success" });
    } catch (e) {
      stopEditing();
      setIsNameAvailable(false);
      enqueueSnackbar(t("error"), { variant: "error" });
    }
  };

  const deleteCampaignHandler = (campaignId: number | null) => () => {
    if (campaignId === null) {
      return;
    }

    setDeleteCampaignOpen(campaignId);
  };

  const handleDeleteCampaignCancel = () => {
    setDeleteCampaignOpen(null);
  };

  const copyCampaignHandler = (campaignId: number | null) => {
    if (campaignId === null) {
      return;
    }

    setCopyCampaignOpen(campaignId);
  };

  const handleCopyCampaignCancel = () => {
    setCopyCampaignOpen(null);
  };

  const handleCopyCampaign = async () => {
    if (copyCampaignOpen) {
      try {
        await copyCampaign(copyCampaignOpen, "PUT");
        setCopyCampaignOpen(null);
        enqueueSnackbar(t("successfully-applied"), { variant: "success" });
        stopEditing();
        fetchScheduleDetails();
      } catch (e) {
        setIsNameAvailable(false);
        enqueueSnackbar(t("error"), { variant: "error" });
      }
    }
  };

  const handleDeleteCampaign = async () => {
    if (deleteCampaignOpen) {
      try {
        await updateCampaign(deleteCampaignOpen, "DELETE");
        setDeleteCampaignOpen(null);
        enqueueSnackbar(t("successfully-applied"), { variant: "success" });
        navigate(
          "/" + t("advertisingschedule").toLowerCase() + `/${scheduleId}`
        );
        fetchScheduleDetails();
      } catch (e) {
        enqueueSnackbar(t("error"), { variant: "error" });
      }
    }
  };

  return (
    <>
      <div>
        {campaignList &&
          campaignList.map((item, index) => {
            const parentPath =
              "/" +
              t("advertisingschedule").toLowerCase() +
              `/${scheduleId}` +
              `/${marketingPartnerId}` +
              `/${item.id}`;
            return (
              <CampaignTableRow
                campaign={item}
                parentPath={parentPath}
                key={item.id}
                isEditing={editIndex === index}
                canEdit={editIndex === null}
                stopEditing={stopEditing}
                startEditing={startEditing(index)}
                deleteCampaignHandler={deleteCampaignHandler(item.id)}
                copyCampaignHandler={copyCampaignHandler}
                nameChangeHandler={nameChangeHandler}
                isNameAvailable={isNameAvailable}
                setIsNameAvailable={setIsNameAvailable}
              />
            );
          })}
      </div>
      <Box className={classes.addBtnBoxStyles}>
        <Button
          color="primary"
          onClick={() => addCampaignHandler()}
          disabled={editIndex === null ? false : true}
          startIcon={<AddIcon></AddIcon>}
        >
          {t("add-action")}
        </Button>
      </Box>

      <ConfirmDialog
        title={t("delete-action")}
        open={deleteCampaignOpen !== null}
        message={t("delete-action-detail")}
        handleConfirm={handleDeleteCampaign}
        handleReject={handleDeleteCampaignCancel}
        ariaLabel="delete-action-dialog-title"
        ariaDescription="delete-action-dialog-description"
        confirmBtnText={t("delete")}
      />

      <ConfirmDialog
        title={t("copy-action")}
        open={copyCampaignOpen !== null}
        message={t("copy-action-detail")}
        handleConfirm={handleCopyCampaign}
        handleReject={handleCopyCampaignCancel}
        ariaLabel="copy-action-dialog-title"
        ariaDescription="copy-action-dialog-description"
        confirmBtnText={t("copy-action")}
      />
    </>
  );
};

export default CampaignTable;
