export interface CreateSchedule {
  name: string;
  year: number;
  referenceYear?: number;
}

export interface AdvertisingSchedule {
  id: number;
  name: string;
  year: number;
  status: SCHEDULE_STATUS;
  numberError: number;
  numberFinished: number;
  numberInProgress: number;
  numberInCopy: number;
}

export enum SCHEDULE_STATUS {
  FINISHED = "FINISHED",
  IN_PROGRESS = "IN_PROGRESS",
  IN_COPY = "IN_COPY",
  ERROR = "ERROR",
}

export interface Schedule {
  id: number;
  marketingPartners: MarketingPartner[];
  name: string;
  year: number;
}

export interface MarketingPartner {
  id: number;
  marketingPartnerId: number;
  name: string;
  state: string;
  marketingCampaigns: MarketingCampaign[];
}

export interface MarketingCampaign {
  id: number | null;
  name: string;
}

export interface AddMarketingPartner {
  id: number;
  adScheduleId: number;
  marketingPartnerId: number;
  state: string;
}

// export interface MarketingPartner {
//   results: MarketingPartnerResult[];
//   length: number;
// }

// export interface MarketingPartnerResult {
//   id: number;
//   name: string;
// }

export interface CreateCampaign {
  name: string;
}

export interface CopyCampaign {
  id: number;
  name: string;
}

export interface UpdateSchedulePartnerStateResponse {
  id: number;
  adScheduleId: number;
  marketingPartnerId: number;
  state: string;
}

export interface UpdateCampaignResponse {
  id: number;
  name: string;
}

export interface DropDownItem {
  id: number;
  name: string;
}

export interface AnnualMeeting {
  customerData: CustomerData;
  articleData: ArticleData;
  advertisingCommitmentData: AdvertisingCommitmentData;
  annualMeetingFinancingCommentPosition: AnnualMeetingFinancingCommentPosition[];
}
export interface AnnualMeetingFinancingCommentPosition {
  salesLines: DropDownItem[];
  financingComment: string | null;
}

export interface CustomerData {
  salesLines: DropDownItem[];
  conditionAreas: DropDownItem[];
  creditors: DropDownItem[];
  objectGroups: DropDownItem[];
  nielsenRegions: DropDownItem[];
  federalStates: DropDownItem[];
  rangeRegions: DropDownItem[];
  zipCodes: DropDownItem[];
  salesDistricts: DropDownItem[];
  nationalCode: string | null;
}

export interface ArticleData {
  brands: DropDownItem[];
  productHierarchyLevel3Ids: DropDownItem[];
  productKinds: DropDownItem[];
  articles: DropDownItem[];
  articleRange: boolean;
  containerReturnPolicies: DropDownItem[];
  packageSizes: DropDownItem[];
}

export interface AdvertisingCommitmentData {
  leaflet: boolean;
  newspaper: boolean;
  poster: boolean;
  internet: boolean;
  campaign: boolean;
  coverPage: boolean;
  hero: boolean;
  tailorMade: boolean;
  onlineCampaign: boolean;
  offerDiscount: boolean;
  customQuality: boolean;
  customQualityDescription: string | null;
  initiations: number | null;
  duration: string | null;
  advertisingCommitmentComment: string | null;
  customQuality2: boolean;
  customQualityDescription2: string | null;
  customQuality3: boolean;
  customQualityDescription3: string | null;
  customQuality4: boolean;
  customQualityDescription4: string | null;
  customQuality5: boolean;
  customQualityDescription5: string | null;
  customQuality6: boolean;
  customQualityDescription6: string | null;
}

export interface FinancingData {
  financingComment: string | null;
}

export interface AnnualMeetingFormDto {
  customerData: {
    salesLineIds: number[];
    conditionAreaIds: number[];
    creditorIds: number[];
    objectGroups: number[];
    nielsenRegionIds: number[];
    federalStateIds: number[];
    rangeRegionIds: number[];
    zipCodes: string[];
    salesDistrictIds: number[];
    nationalCode: string;
  };
  articleData: {
    brandIds: number[];
    productHierarchyLevel3Ids: number[];
    productKindIds: number[];
    articleIds: number[];
    articleRange: boolean;
    containerReturnPolicies: number[];
    packageSizes: number[];
  };
  advertisingCommitmentData: {
    leaflet: boolean;
    newspaper: boolean;
    poster: boolean;
    internet: boolean;
    campaign: boolean;
    coverPage: boolean;
    hero: boolean;
    tailorMade: boolean;
    onlineCampaign: boolean;
    offerDiscount: boolean;
    customQuality: boolean;
    customQualityDescription: string | null;
    initiations: number | null;
    duration: string | null;
    advertisingCommitmentComment: string | null;
    customQuality2: boolean;
    customQualityDescription2: string | null;
    customQuality3: boolean;
    customQualityDescription3: string | null;
    customQuality4: boolean;
    customQualityDescription4: string | null;
    customQuality5: boolean;
    customQualityDescription5: string | null;
    customQuality6: boolean;
    customQualityDescription6: string | null;
  };
  annualMeetingFinancingCommentPosition: [];
}

export function mapAnnualMeeting(
  annualMeeting: AnnualMeeting
): AnnualMeetingFormDto {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <AnnualMeetingFormDto>{
    customerData: {
      salesLineIds: annualMeeting.customerData.salesLines?.map((x) => x.id),
      conditionAreaIds: annualMeeting.customerData.conditionAreas?.map(
        (x) => x.id
      ),
      creditorIds: annualMeeting.customerData.creditors?.map((x) => x.id),
      federalStateIds: annualMeeting.customerData.federalStates?.map(
        (x) => x.id
      ),
      nielsenRegionIds: annualMeeting.customerData.nielsenRegions?.map(
        (x) => x.id
      ),
      objectGroups: annualMeeting.customerData.objectGroups?.map((x) => x.id),
      rangeRegionIds: annualMeeting.customerData.rangeRegions?.map((x) => x.id),
      nationalCode: annualMeeting.customerData.nationalCode || "",
      salesDistrictIds: annualMeeting.customerData.salesDistricts?.map(
        (x) => x.id
      ),
      zipCodes: annualMeeting.customerData?.zipCodes?.map((x) => x.name),
    },
    articleData: {
      articleIds: annualMeeting.articleData.articles?.map((x) => x.id),
      articleRange: annualMeeting.articleData.articleRange,
      brandIds: annualMeeting.articleData.brands?.map((x) => x.id),
      containerReturnPolicies:
        annualMeeting.articleData.containerReturnPolicies?.map((x) => x.id),
      packageSizes: annualMeeting.articleData.packageSizes?.map((x) => x.id),
      productHierarchyLevel3Ids:
        annualMeeting.articleData.productHierarchyLevel3Ids?.map((x) => x.id),
      productKindIds: annualMeeting.articleData.productKinds?.map((x) => x.id),
    },
    advertisingCommitmentData: {
      advertisingCommitmentComment:
        annualMeeting.advertisingCommitmentData.advertisingCommitmentComment,
      campaign: annualMeeting.advertisingCommitmentData.campaign,
      coverPage: annualMeeting.advertisingCommitmentData.coverPage,
      customQuality: annualMeeting.advertisingCommitmentData.customQuality,
      customQuality2: annualMeeting.advertisingCommitmentData.customQuality2,
      customQuality3: annualMeeting.advertisingCommitmentData.customQuality3,
      customQuality4: annualMeeting.advertisingCommitmentData.customQuality4,
      customQuality5: annualMeeting.advertisingCommitmentData.customQuality5,
      customQuality6: annualMeeting.advertisingCommitmentData.customQuality6,
      leaflet: annualMeeting.advertisingCommitmentData.leaflet,
      customQualityDescription:
        annualMeeting.advertisingCommitmentData.customQualityDescription,
      customQualityDescription2:
        annualMeeting.advertisingCommitmentData.customQualityDescription2,
      customQualityDescription3:
        annualMeeting.advertisingCommitmentData.customQualityDescription3,
      customQualityDescription4:
        annualMeeting.advertisingCommitmentData.customQualityDescription4,
      customQualityDescription5:
        annualMeeting.advertisingCommitmentData.customQualityDescription5,
      customQualityDescription6:
        annualMeeting.advertisingCommitmentData.customQualityDescription6,
      duration: annualMeeting.advertisingCommitmentData.duration,
      hero: annualMeeting.advertisingCommitmentData.hero,
      initiations: annualMeeting.advertisingCommitmentData.initiations,
      internet: annualMeeting.advertisingCommitmentData.internet,
      newspaper: annualMeeting.advertisingCommitmentData.newspaper,
      poster: annualMeeting.advertisingCommitmentData.poster,
      tailorMade: annualMeeting.advertisingCommitmentData.tailorMade,
      onlineCampaign: annualMeeting.advertisingCommitmentData.onlineCampaign,
      offerDiscount: annualMeeting.advertisingCommitmentData.offerDiscount,
    },
    annualMeetingFinancingCommentPosition:
      annualMeeting.annualMeetingFinancingCommentPosition?.map((position) => ({
        salesLines: position.salesLines?.map((sl) => sl.id),
        financingComment: position.financingComment,
      })),
  };
}

export interface AdPlanning {
  adPlanningPositions: AdPlanningPosition[];
  advertisingCommitmentComment: string | null;
}

export interface AdPlanningPosition {
  salesLines: DropDownItem[];
  articles: DropDownItem[];
  periods: PeriodDropdownItem[];
  brands: DropDownItem[];
  conditionAreas: DropDownItem[];
  packageSizes: DropDownItem[];
}
export interface AdPlanningPositionFormDto {
  salesLineIds: number[];
  articleIds: number[];
  periods: PeriodDropdownItem[];
  brandIds: number[];
  conditionAreaIds: number[];
  packageSizes: DropDownItem[];
}

export interface AdPlanningFormDto {
  positions: AdPlanningPositionFormDto[];
  // advertisingCommitmentComment: string | null;
}

export function mapAdPlanning(adPlanning: AdPlanning): AdPlanningFormDto {
  return {
    positions: adPlanning.adPlanningPositions?.map((position) => ({
      salesLineIds: position.salesLines.map((sl) => sl.id),
      articleIds: position.articles.map((a) => a.id),
      periods: position.periods,
      brandIds: position.brands.map((b) => b.id),
      conditionAreaIds: position.conditionAreas.map((c) => c.id),
      packageSizes: position.packageSizes,
    })),
  };
}

export interface AdPlanning {
  brands: DropDownItem[];
  articles: DropDownItem[];
  periods: PeriodDropdownItem[];
  salesLines: DropDownItem[];
  conditionAreas: DropDownItem[];
  packageSizes: DropDownItem[];
}
export interface PeriodDropdownItem {
  weekOfYear: number;
  coverPage: boolean | null;
  hero: boolean | null;
  tailorMade: boolean | null;
  onlineCampaign: boolean | null;
  offerDiscount: boolean | null;
  customQuality: boolean | null;
  customQualityDescription: string | null;
  customQuality2: boolean | null;
  customQualityDescription2: string | null;
  customQuality3: boolean | null;
  customQualityDescription3: string | null;
  customQuality4: boolean | null;
  customQualityDescription4: string | null;
  customQuality5: boolean | null;
  customQualityDescription5: string | null;
  customQuality6: boolean | null;
  customQualityDescription6: string | null;
  name: string;
  id: number;
}
