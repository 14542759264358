import { Typography, TextField, makeStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ErrorIcon from "@material-ui/icons/Error";

const GenericTextField = ({
  name,
  rules,
  label,
  control,
  rowsNumber,
  isFullWidth,
  inputType,
  validateInitiations,
  disabled = false,
}: {
  name: string;
  rules?: any;
  label?: string;
  control: any;
  rowsNumber?: number;
  isFullWidth?: boolean;
  inputType?: string;
  validateInitiations?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      rules={
        validateInitiations
          ? {
              validate: (value) => value > 0 && value <= 30,
            }
          : undefined
      }
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => {
        return (
          <>
            <TextField
              disabled={disabled}
              variant="outlined"
              type={inputType}
              label={label}
              InputLabelProps={{
                shrink: true,
              }}
              name={name}
              value={value || ""}
              onChange={onChange}
              error={error ? true : false}
              minRows={rowsNumber ?? undefined}
              multiline={rowsNumber ? true : false}
              className={isFullWidth ? classes.fullWidth : undefined}
            />
            {error && (
              <>
                <Typography
                  component={"span"}
                  variant={"body2"}
                  className={classes.wrapIcon}
                >
                  <ErrorIcon fontSize="small" />
                  <Typography
                    component={"span"}
                    variant={"body2"}
                    className={classes.iconTextStyles}
                  >
                    {t("enter-number")}
                  </Typography>
                </Typography>
              </>
            )}
          </>
        );
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },

  wrapIcon: {
    color: "red",
    marginTop: theme.spacing(1),
    verticalAlign: "middle",
    display: "inline-flex",
  },

  iconTextStyles: {
    marginLeft: theme.spacing(1),
    marginTop: "2px",
    fontSize: "13px",
  },
}));

export default GenericTextField;
