import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import { nationalInternational } from "../../../api/constants";
import { TextField } from "@material-ui/core";

interface NationalInternationalDropdownProps {
  registerName: string;
  control: any;
  rules?: any;
}

const NationalInternationalDropdown = ({
  registerName,
  control,
}: NationalInternationalDropdownProps) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={registerName}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <TextField
            id="select"
            value={value || ""}
            onChange={onChange}
            select
            label={t("national-interntional")}
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value="">
              <em>{t("empty-option")}</em>
            </MenuItem>
            {nationalInternational &&
              nationalInternational.map((item) => (
                <MenuItem key={item.key} value={item.value}>
                  {item.key}
                </MenuItem>
              ))}
          </TextField>
        );
      }}
    />
  );
};

export default NationalInternationalDropdown;
