import * as React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Insertions from "./Insertions";
import AllArticlesTable from "../detailed-planning/components/AllArticlesTable";
import {
  Article,
  ArticleAssignment,
  MarketingCampaign,
} from "../../api/detailedPlanningApiClient";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import { useNavigate } from "react-router-dom";

const useRowStyles = makeStyles((theme) => ({
  ArrowCardStyles: {
    display: "flex",
  },
  ArrowBackStyles: {
    margin: "3px 5px 0 0",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
      borderRadius: "50%",
    },
  },
}));

interface DetailedPlanningDetailsSideNavProps {
  clientele: string;
  importVersion: string;
  weekOfYear: string;
  year: string;
  assigned: ArticleAssignment[];
  lastAddedArticlePosition: number | undefined;
  addArticleToFlyerPage: (params: {
    marketingCampaign?: MarketingCampaign | null;
    article?: Article;
    insertionPosition?: { positionId: number; positionIndex: number };
    unlistedArticleName?: string;
    addAsSubposition?: boolean;
  }) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DetailedPlanningDetailsSideNav = ({
  clientele,
  importVersion,
  weekOfYear,
  year,
  assigned,
  lastAddedArticlePosition,
  addArticleToFlyerPage,
}: DetailedPlanningDetailsSideNavProps) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const classes = useRowStyles();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Card variant="outlined">
        <CardContent className={classes.ArrowCardStyles}>
          <ArrowBackSharpIcon
            fontSize="medium"
            onClick={() => navigate(-1)}
            className={classes.ArrowBackStyles}
          />
          <Typography component="span" variant="h5">
            {clientele}
          </Typography>
        </CardContent>
        <Box sx={{ width: "100%", height: "100%" }}>
          <CardContent>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={t("insertion-from-adplanning")}
                  {...a11yProps(0)}
                  sx={{ width: "50%" }}
                />
                <Tab
                  label={t("all-articles")}
                  {...a11yProps(1)}
                  sx={{ width: "50%" }}
                />
              </Tabs>
            </Box>
          </CardContent>
          <TabPanel value={value} index={0}>
            <Insertions
              lastAddedArticlePosition={lastAddedArticlePosition}
              clientele={clientele}
              importVersion={importVersion}
              weekOfYear={weekOfYear}
              year={year}
              assigned={assigned}
              addArticleToFlyerPage={addArticleToFlyerPage}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AllArticlesTable
              lastAddedArticlePosition={lastAddedArticlePosition}
              importVersion={importVersion}
              clientele={clientele}
              addArticleToFlyerPage={addArticleToFlyerPage}
            />
          </TabPanel>
        </Box>
      </Card>
    </>
  );
};

export default DetailedPlanningDetailsSideNav;
