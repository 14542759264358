import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useCallback } from "react";
import BrandDropdown from "../../../dropdowns/BrandDropdown";
import ProductHierarchyDropdown from "../../../dropdowns/ProductHierarchyDropdown";
import ProductKindDropdown from "../../../dropdowns/ProductKindDropdown";
import ArticleDropdown from "../../../dropdowns/ArticleDropdown";
import ContainerReturnPolicyDropdown from "../../../dropdowns/ContainerReturnPolicyDropdown";
import PackageSizeDropdown from "../../../dropdowns/PackageSizeDropdown";
import CampaignDetailsSubmitFooter from "../../../components/ad-plans/CampaignDetailsSubmitFooter";
import CustomAlert from "../../../../../components/CustomAlert";
import {
  DropDownItem,
  postArticleCount,
} from "../../../../../api/basedataApiClient";
import GenericSwitch from "../../../../ad-planning/dropdowns/GenericSwitch";

const defaultValues = {
  articleData: {
    brands: [],
    productHierarchyLevel3Ids: [],
    productKinds: [],
    articles: [],
    articleRange: false,
    containerReturnPolicies: [],
    packageSizes: [],
  },
};

const Range = ({ marketingPartnerId }: { marketingPartnerId: number }) => {
  const { control, reset, watch, getValues } = useFormContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const [countSeverityDetails, setCountSeverityDetails] = useState<{
    type: string;
    message: string;
  } | null>(null);

  const getIds = useCallback((selected: DropDownItem[]): number[] => {
    let itemsTransformed = selected.map((item) => {
      return item.id;
    });

    return itemsTransformed;
  }, []);

  const apiCall = useCallback(async () => {
    try {
      const values = getValues();
      let brandId = getIds(values.articleData.brands).map((x) => x.toString());
      let productHierarchyId = getIds(
        values.articleData.productHierarchyLevel3Ids
      ).map((x) => x.toString());
      let productKindId = getIds(values.articleData.productKinds).map((x) =>
        x.toString()
      );
      let articleId = getIds(values.articleData.articles).map((x) =>
        x.toString()
      );
      let containerReturnPolicy = getIds(
        values.articleData.containerReturnPolicies
      ).map((x) => x.toString());
      let packageSize = getIds(values.articleData.packageSizes).map((x) =>
        x.toString()
      );
      if (marketingPartnerId) {
        const response = await postArticleCount({
          marketingPartnerId: [marketingPartnerId],
          brandId,
          productHierarchyId,
          productKindId,
          articleId,
          containerReturnPolicy,
          packageSize,
        });

        if (response && response.count > 0) {
          setCountSeverityDetails({
            type: "success",
            message: t("article-that-meet-these-criteria", {
              count: response.count,
            }),
          });
        } else {
          setCountSeverityDetails({
            type: "error",
            message: t("no-article-meets-these-criteria"),
          });
        }
      }
    } catch (e) {
      setCountSeverityDetails({
        type: "error",
        message: t("error"),
      });
    }
  }, [getValues, getIds, t, marketingPartnerId]);

  useEffect(() => {
    const subscription = watch(() => {
      apiCall();
    });
    return () => subscription.unsubscribe();
  }, [watch, apiCall]);
  useEffect(() => {
    if (!countSeverityDetails) {
      apiCall();
    }
  }, [apiCall, countSeverityDetails]);
  return (
    <>
      <Box className={classes.container}>
        <Typography variant="h6" component="span" color="textSecondary">
          {t("brand")}
        </Typography>
        <Box className={classes.boxHeight}></Box>

        <Box className={classes.selectStyles}>
          <BrandDropdown
            control={control}
            registerName={"articleData.brands"}
            marketingPartnerId={marketingPartnerId}
          />
        </Box>
        <Box className={classes.boxPaddingBottom}></Box>

        <Typography variant="h6" component="span" color="textSecondary">
          {t("product")}
        </Typography>
        <Box className={classes.boxHeight}></Box>

        <Grid container spacing={2}>
          <Grid item className={classes.selectStyles}>
            <ProductHierarchyDropdown
              control={control}
              registerName={"articleData.productHierarchyLevel3Ids"}
              marketingPartnerId={marketingPartnerId}
            />
          </Grid>
          <Grid item className={classes.selectStyles}>
            <ProductKindDropdown
              control={control}
              registerName={"articleData.productKinds"}
              marketingPartnerId={marketingPartnerId}
            />
          </Grid>
          <Grid item className={classes.selectStyles}>
            <ArticleDropdown
              control={control}
              registerName={"articleData.articles"}
              marketingPartnerId={marketingPartnerId}
            />
          </Grid>
        </Grid>
        <Box className={classes.boxHeight}></Box>

        <Box>
          <GenericSwitch
            name="articleData.articleRange"
            control={control}
            label={t("articlerange")}
          />
        </Box>

        <Box className={classes.boxPaddingBottom}></Box>

        <Typography variant="h6" component="span" color="textSecondary">
          {t("properties")}
        </Typography>
        <Box className={classes.boxHeight}></Box>

        <Grid container spacing={2}>
          <Grid item className={classes.selectStyles}>
            <ContainerReturnPolicyDropdown
              control={control}
              registerName={"articleData.containerReturnPolicies"}
              marketingPartnerId={marketingPartnerId}
            />
          </Grid>
          <Grid item className={classes.selectStyles}>
            <PackageSizeDropdown
              control={control}
              registerName={"articleData.packageSizes"}
              marketingPartnerId={marketingPartnerId}
            />
          </Grid>
        </Grid>
        {countSeverityDetails && (
          <Box>
            <CustomAlert
              severityType={countSeverityDetails.type}
              message={countSeverityDetails.message}
            />
          </Box>
        )}
      </Box>
      <CampaignDetailsSubmitFooter
        textKey={"reset-to-default-range"}
        resetHandler={() => reset({ ...getValues(), ...defaultValues })}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexFlow: "column",
    minHeight: "calc(100vh - 380px)",
  },

  boxHeight: {
    height: theme.spacing(2),
  },

  boxPaddingBottom: {
    paddingBottom: theme.spacing(4),
  },

  submitFooter: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },

  selectStyles: {
    width: theme.spacing(32),
  },
}));

export default Range;
