import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { TextField, MenuItem, Chip } from "@mui/material";
import useLocalStorage from "../../../hooks/useLocalStorage";

interface StatusDropdownProps {
  name: string;
  control: any;
  localStorage?: boolean;
  disableUnknown?: boolean;
  showEmptyOption?: boolean;
}

export enum STATUS_TYPE {
  UNKNOWN = "UNKNOWN",
  IN_GRAPHIC = "IN_GRAPHIC",
  FINISHED = "FINISHED",
  FINISHED_MEDIA = "FINISHED_MEDIA",
  IN_PROGRESS = "IN_PROGRESS",
  IN_RELEASE = "IN_RELEASE",
  CRITICAL = "CRITICAL",
  READY = "READY",
}

const STATUS = [
  {
    name: STATUS_TYPE.UNKNOWN,
    backgroundColor: "grey",
  },
  {
    name: STATUS_TYPE.IN_PROGRESS,
    backgroundColor: "gold",
  },
  {
    name: STATUS_TYPE.READY,
    backgroundColor: "orange",
  },
  {
    name: STATUS_TYPE.IN_RELEASE,
    backgroundColor: "lightgreen",
  },
  {
    name: STATUS_TYPE.FINISHED,
    backgroundColor: "green",
  },
  {
    name: STATUS_TYPE.FINISHED_MEDIA,
    backgroundColor: "lightblue",
  },
  {
    name: STATUS_TYPE.IN_GRAPHIC,
    backgroundColor: "blue",
  },
  {
    name: STATUS_TYPE.CRITICAL,
    backgroundColor: "red",
  },
];

const StatusDropdown = ({
  name,
  control,
  localStorage = false,
  disableUnknown = false,
  showEmptyOption = true,
}: StatusDropdownProps) => {
  const { t } = useTranslation();
  const [actualValue, setActualValue] = useLocalStorage<string>(
    `tbg-select-${name}`,
    ""
  );
  const statusOptions = disableUnknown
    ? STATUS.filter((status) => status.name !== "UNKNOWN")
    : STATUS;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <TextField
            select
            id="select"
            value={actualValue || field.value || ""}
            onChange={(e) => {
              field.onChange(e.target.value);
              if (localStorage) {
                setActualValue(e.target.value);
              }
            }}
            label={t("status")}
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
            fullWidth
          >
            {showEmptyOption && (
              <MenuItem value="">
                <em>{t("empty-option")}</em>
              </MenuItem>
            )}
            {statusOptions.map((option, index) => {
              return (
                <MenuItem key={index} value={option.name}>
                  <Chip
                    size="small"
                    label={``}
                    style={{
                      backgroundColor: option.backgroundColor,
                      width: "16px",
                      height: "16px",
                      marginRight: "5px",
                    }}
                  />
                  {t(
                    option.name.toLowerCase().replace(/_/g, "-") +
                      "-status-option"
                  )}
                </MenuItem>
              );
            })}
          </TextField>
        );
      }}
    />
  );
};

export default StatusDropdown;
