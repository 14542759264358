import * as React from "react";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface InputDayProps {
  name: string;
  label?: string;
  control: any;
  setPrintingHouseDetails: () => void;
}

export enum WEEKDAYS_TYPE {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

const WEEKDAYS = [
  {
    name: WEEKDAYS_TYPE.MONDAY,
  },
  {
    name: WEEKDAYS_TYPE.TUESDAY,
  },
  {
    name: WEEKDAYS_TYPE.WEDNESDAY,
  },
  {
    name: WEEKDAYS_TYPE.THURSDAY,
  },
  {
    name: WEEKDAYS_TYPE.FRIDAY,
  },
  {
    name: WEEKDAYS_TYPE.SATURDAY,
  },
  {
    name: WEEKDAYS_TYPE.SUNDAY,
  },
];

const InputDay = ({
  name,
  label,
  control,
  setPrintingHouseDetails,
}: InputDayProps) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <TextField
            select
            id="select-inputday"
            value={value || ""}
            onChange={onChange}
            onBlur={() => setPrintingHouseDetails()}
            label={label}
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
            fullWidth
          >
            {WEEKDAYS.map((option, index) => {
              return (
                <MenuItem key={index} value={option.name}>
                  {t(
                    option.name.toLowerCase().replace(/_/g, "-") +
                      "-weekday-option"
                  )}
                </MenuItem>
              );
            })}
          </TextField>
        );
      }}
    />
  );
};

export default InputDay;
